import dayjs from 'dayjs';
import { Theme } from '@mui/material';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { Annotations, Layout, ColorScale, Shape, Data } from 'plotly.js';
import chunk from 'lodash/chunk';
import { DailyMetricItem, HourlyMetric } from '../../../services/api';
import { HoursSelection } from '../../../state/types';
import {
  BandParamsType,
  dataColours,
  varNameBandParams,
  motionUtilisationBands,
} from '../../../utils/dataBandParams';
import { VarName } from '../../../utils/varNames';
import {
  CalendarSelectionType,
  getAvgDataMetricValue,
  getMaxDataMetricValue,
  getMinDataMetricValue,
  PlotType,
  ValueType,
} from '../../CalendarView/helpers';
import { TimeRange } from '../plotCommon';
import { themeProps } from '../../../styles/theme';

dayjs.extend(localizedFormat);

const getDays = (totalDays: number, startDate: number): Date[] => {
  const allDays: Date[] = [];
  for (let i = 0; i < totalDays; i++) {
    const newDay = new Date(dayjs(startDate).add(i, 'day').unix() * 1000);
    if (newDay) allDays.push(newDay);
  }
  return allDays;
};

export const getWeekXValues = (selectedHours: HoursSelection): number[] => {
  let xValues;
  const { selectHours, startHour, endHour } = selectedHours;
  const allHours = Array.from(Array(24).keys());
  if (selectHours) xValues = allHours.slice(startHour, endHour);
  else {
    xValues = allHours;
  }
  return xValues;
};

export const getWeekYValues = (timeRange: TimeRange): Date[] => {
  const { start, end } = timeRange;
  let totalDays;
  if (dayjs(end).diff(start, 'day') > 6) {
    totalDays = 7;
  } else totalDays = dayjs(end).diff(start, 'day') + 1;
  const allDays = getDays(totalDays, start);
  return allDays;
};

export const getCalendarXvalues = (selectedTimeRange: TimeRange): Date[] => {
  const { start, end } = selectedTimeRange;
  const totalWeeks = dayjs(end).endOf('week').diff(dayjs(start).startOf('week'), 'week');
  const startTime = dayjs(start).day(0).unix() * 1000;
  const allWeeks: Date[] = [];
  for (let i = 0; i <= totalWeeks; i++) {
    const weekDayjs = dayjs(startTime).add(i, 'week');
    const newWeekDate = new Date(dayjs(weekDayjs).unix() * 1000);
    if (newWeekDate) allWeeks.push(newWeekDate);
  }
  return allWeeks;
};

export const getCalendarYvalues = (timeRange: TimeRange): Date[] => {
  const { start } = timeRange;
  const startTime = dayjs(start).day(0).unix() * 1000;
  const totalDays = 7;
  const allDays = getDays(totalDays, startTime);
  return allDays;
};

const isSelectedTime = (time: number, hoursRange: [number, number]): boolean => {
  // Checks whether a given time matches our selection criteria
  const m = dayjs(time);
  const hour = m.hour();
  if (hour < hoursRange[0] || hour >= hoursRange[1]) {
    return false;
  }
  return true;
};

const getHourValue = (
  dataHistory: DailyMetricItem[],
  startHour: number,
  valueType: ValueType | undefined,
  varName: VarName
): number | null => {
  let hourValue = null;
  const hourValues: number[] = [];
  const selectedDataDate = dataHistory.filter(
    (data) => dayjs(data.date).get('day') === dayjs(startHour).get('day')
  );
  const selectedHour = dayjs(startHour).format('H');
  if (selectedDataDate.length > 0) {
    for (let i = 0; i < selectedDataDate.length; i++) {
      const selectedHourData = selectedDataDate[i]?.hours?.find(
        (hour: HourlyMetric) => hour?.h.toString() === selectedHour
      );
      if (selectedHourData) {
        const { utl, tot, min, max, avg } = selectedHourData;
        const hasTotValue =
          dataHistory.filter((data) => Object.prototype.hasOwnProperty.call(data, 'tot')).length >
          0;
        if (varName === VarName.MotionEvent) {
          if (utl !== undefined) hourValues.push(utl);
        } else if (hasTotValue) {
          if (tot !== undefined) hourValues.push(tot);
        } else {
          if (valueType === ValueType.avg && avg !== undefined) {
            hourValues.push(avg);
          }
          if (valueType === ValueType.min && min !== undefined) {
            hourValues.push(min);
          }
          if (valueType === ValueType.max && max !== undefined) {
            hourValues.push(max);
          }
        }
      }
    }
  }
  if (hourValues.length > 0) {
    const avgValue = hourValues.reduce((a, b) => a + b, 0) / hourValues.length;
    if (varName === VarName.MotionEvent) {
      // for occupancy define 0 as unoccupied and 1 as occupied
      if (valueType === ValueType.occ) {
        if (avgValue && avgValue > 0) hourValue = 100;
        else if (avgValue === 0) hourValue = 0;
      } else hourValue = avgValue * 100;
    } else hourValue = avgValue;
  }
  return hourValue;
};

const getDayValue = (
  dataHistory: DailyMetricItem[],
  startTimeStamp: number,
  valueType: ValueType | undefined,
  varName: VarName
): number | null => {
  let dayValue = null;
  const dayValues: number[] = [];
  const selectedDate = dayjs(startTimeStamp).format('YYYY-MM-DD');
  const dayDataMetric = dataHistory.filter((data) => data.date === selectedDate);
  const hasTotValue =
    dataHistory.filter((data) => Object.prototype.hasOwnProperty.call(data, 'tot')).length > 0;
  if (dayDataMetric.length > 0) {
    for (let i = 0; i < dayDataMetric.length; i++) {
      const { min, avg, max, utl, tot } = dayDataMetric[i];
      if (varName === VarName.MotionEvent) {
        if (utl !== undefined) dayValues.push(utl);
      } else if (hasTotValue) {
        if (tot !== undefined) dayValues.push(tot);
      } else {
        if (valueType === ValueType.avg && avg !== undefined) {
          dayValues.push(avg);
        }
        if (valueType === ValueType.min && min !== undefined) {
          dayValues.push(min);
        }
        if (valueType === ValueType.max && max !== undefined) {
          dayValues.push(max);
        }
      }
    }
  }
  if (dayValues.length > 0) {
    const avgValue = dayValues.reduce((a, b) => a + b, 0) / dayValues.length;
    if (varName === VarName.MotionEvent) {
      // for occupancy define 0 as unoccupied and 1 as occupied
      if (valueType === ValueType.occ) {
        if (avgValue && avgValue > 0) dayValue = 100;
        else if (avgValue === 0) dayValue = 0;
      } else dayValue = avgValue * 100;
    } else dayValue = avgValue;
  }
  return dayValue;
};

const getWeekZvalues = (
  varName: VarName,
  boxArray: number[],
  dataHistory: DailyMetricItem[],
  selectedHours: HoursSelection,
  valueType: ValueType | undefined
): (number | null)[] => {
  const zValues: (number | null)[] = [];
  for (let i = 0; i < boxArray.length; i++) {
    const { selectHours, startHour, endHour } = selectedHours;
    const isSelectedHour = isSelectedTime(boxArray[i], [startHour, endHour]);
    const addToArray = () => {
      const hourValue = getHourValue(dataHistory, boxArray[i], valueType, varName);
      zValues.push(hourValue);
    };
    if (selectHours) {
      if (isSelectedHour) addToArray();
      else zValues.push(null);
    } else addToArray();
  }
  return zValues;
};

const getCalendarZvalues = (
  varName: VarName,
  boxArray: number[],
  dataHistory: DailyMetricItem[],
  valueType: ValueType | undefined
): (number | null)[] => {
  const zValues = [];
  for (let i = 0; i < boxArray.length; i++) {
    let dayValue = null;
    if (dataHistory.length > 0 && dataHistory[0]?.id !== undefined)
      dayValue = getDayValue(dataHistory, boxArray[i], valueType, varName);
    zValues.push(dayValue);
  }
  return zValues;
};

const getWeekBoxArray = (timeRange: TimeRange, selectedHours: HoursSelection): number[] => {
  const { selectHours, startHour, endHour } = selectedHours;
  const { start, end } = timeRange;
  let totalBox;
  if (dayjs(end).diff(dayjs(start), 'days') > 6) totalBox = 24 * 7;
  else totalBox = Math.round((end - start) / (3600 * 1000));
  const boxArray: number[] = [];
  for (let i = 0; i < totalBox; i++) {
    const hourTimeStamp = start + i * 3600 * 1000;
    const isSelectedHour = isSelectedTime(hourTimeStamp, [startHour, endHour]);
    if (selectHours) {
      if (isSelectedHour) boxArray.push(hourTimeStamp);
    } else boxArray.push(hourTimeStamp);
  }
  return boxArray;
};

const getCalendarBoxArray = (timeRange: TimeRange): number[] => {
  const { start, end } = timeRange;
  const totalWeeks = Math.ceil(
    dayjs(end).endOf('week').diff(dayjs(start).startOf('week'), 'days') / 7
  );
  const totalBox = totalWeeks * 7;
  const boxArray: number[] = [];
  for (let i = 0; i < totalBox; i++) {
    const dayTimeStamp = dayjs(start).startOf('week').add(i, 'day').unix() * 1000;
    boxArray.push(dayTimeStamp);
  }
  return boxArray;
};

export const getWeekZValue = (
  varName: VarName,
  dataHistory: DailyMetricItem[],
  timeRange: TimeRange,
  selectedHours: HoursSelection,
  valueType: ValueType | undefined
): (number | null)[] => {
  const boxArray = getWeekBoxArray(timeRange, selectedHours);
  const zValues = getWeekZvalues(varName, boxArray, dataHistory, selectedHours, valueType);
  return zValues;
};

export const getCalendarZvalue = (
  varName: VarName,
  dataHistory: DailyMetricItem[],
  timeRange: TimeRange,
  valueType: ValueType | undefined
): (number | null)[] => {
  const boxArray = getCalendarBoxArray(timeRange);
  const zValues: (number | null)[] = getCalendarZvalues(varName, boxArray, dataHistory, valueType);
  return zValues;
};

export const nullReplacementData = (
  zmin: number | undefined,
  data: (number | null)[] | undefined
): number[] => {
  let nullReplacement;
  const zValueData: number[] = [];
  if (zmin !== undefined) nullReplacement = zmin - 0.1;
  if (data && nullReplacement) {
    for (let i = 0; i < data.length; i++) {
      if (data[i] === null) {
        zValueData.push(nullReplacement);
      } else zValueData.push(data[i] as number);
    }
  }
  return zValueData;
};

const getHighlightedShades = (highlightedTimes: TimeRange[]): Shape[] => {
  const newShapes: Shape[] = [];
  if (highlightedTimes) {
    highlightedTimes.forEach(({ start, end }) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      newShapes.push({
        type: 'rect',
        xref: 'paper',
        yref: 'y',
        x0: 1,
        y0: dayjs(start).subtract(12, 'hours').toDate(),
        x1: 0,
        y1: dayjs(end).subtract(12, 'hours').toDate(),
        fillcolor: '#000',
        opacity: 0.3,
        line: { width: 0 },
      });
    });
  }
  return newShapes;
};

const getBaseLayout = (
  xValues: (Date | number)[],
  yValues: Date[],
  highlightedTimes: TimeRange[],
  theme: Theme
): Partial<Layout> => {
  const xMetric = 3; // 3 values for min/max/avg
  const yMetric = 1; // 1 values for avg
  const nX = xValues.length + xMetric;
  const nY = yValues.length + yMetric;
  const xBoxSize = 1 / (nX + 0.5); // 0.5 spacing between subplots
  const yBoxSize = 1 / (nY + 0.5); // 0.5 spacing between subplots
  const shapes = getHighlightedShades(highlightedTimes);
  return {
    font: { family: themeProps.fontFamily.body },
    shapes,
    annotations: [],
    xaxis: {
      ticks: '',
      // For hourly view have a tick for every hour
      nticks: xValues && typeof xValues[0] === 'number' ? 27 : undefined,
      side: 'top',
      domain: [0, xValues.length * xBoxSize],
      tickformatstops: [
        {
          dtickrange: [null, 'M1'],
          value: '%e %b',
        },
        {
          dtickrange: ['M1', 'M12'],
          value: '%e %b %y',
        },
        {
          dtickrange: ['M12', null],
          value: '%Y Y.',
        },
      ],
      tickfont: {
        color: theme.palette.text.primary,
      },
    },
    xaxis2: {
      ticks: '',
      side: 'top',
      domain: [1 - xMetric * xBoxSize, 1],
      tickfont: {
        color: theme.palette.text.primary,
      },
    },
    margin: {
      l: 40,
      r: 5,
      b: 5,
      t: 20,
      pad: 0,
    },
    height: 200,
    yaxis: {
      nticks: 8,
      tickformat: '%a',
      ticks: '',
      ticksuffix: ' ',
      autosize: false,
      autorange: 'reversed',
      domain: [1 - yValues.length * yBoxSize, 1],
      tickfont: {
        color: theme.palette.text.primary,
      },
    },
    yaxis2: {
      ticks: '',
      autosize: false,
      autorange: 'reversed',
      domain: [0, yMetric * yBoxSize],
      tickfont: {
        color: theme.palette.text.primary,
      },
    },
    plot_bgcolor: theme.palette.primary.main,
    paper_bgcolor: theme.palette.primary.main,
  } as Partial<Layout>;
};

export const getWeekPlotLayout = (
  xValues: (Date | number)[],
  yValues: Date[],
  zValues: number[][],
  minValue: number,
  valueType: ValueType | undefined,
  highlightedTimes: TimeRange[],
  theme: Theme
): Partial<Layout> => {
  const layout = getBaseLayout(xValues, yValues, highlightedTimes, theme);
  if (zValues) {
    for (let i = 0; i < yValues.length; i++) {
      for (let j = 0; j < xValues.length; j++) {
        const currentValue = zValues?.[i]?.[j];
        let textLabel = '';
        if (currentValue === minValue - 0.1) textLabel = 'n/a';
        else if (valueType === ValueType.occ) {
          if (currentValue === 0) textLabel = 'Avai';
          else if (currentValue === 100) textLabel = 'Occ';
        } else textLabel = zValues?.[i]?.[j]?.toFixed(1) ?? '';
        const result = {
          xref: 'x',
          yref: 'y',
          x: xValues[j],
          y: yValues[i],
          text: textLabel,
          font: {
            size: 10,
            color: 'white',
          },
          showarrow: false,
        } as unknown as Partial<Annotations>;
        layout?.annotations?.push(result);
      }
    }
  }
  return layout;
};

export const getCalendarPlotLayout = (
  xValues: (Date | number)[],
  yValues: Date[],
  zValues: number[][],
  minValue: number,
  isLongRange: boolean,
  highlightedTimes: TimeRange[],
  valueType: ValueType | undefined,
  theme: Theme
): Partial<Layout> => {
  const layout = getBaseLayout(xValues, yValues, highlightedTimes, theme);
  if (zValues) {
    for (let i = 0; i < yValues.length; i++) {
      for (let j = 0; j < xValues.length; j++) {
        let textLabel = '';
        const currentValue = zValues?.[i]?.[j];
        if (!isLongRange) {
          if (currentValue === minValue - 0.1) textLabel = 'n/a';
          else if (valueType === ValueType.occ) {
            if (currentValue === 0) textLabel = 'Avai';
            else if (currentValue === 100) textLabel = 'Occ';
          } else textLabel = zValues?.[i]?.[j]?.toFixed(1) ?? '';
        }
        const result = {
          xref: 'x',
          yref: 'y',
          x: xValues[j],
          y: yValues[i],
          text: textLabel,
          font: {
            size: 10,
            color: 'white',
          },
          showarrow: false,
        } as unknown as Partial<Annotations>;
        layout?.annotations?.push(result);
      }
    }
  }
  return layout;
};

export const getSubPlotResult = (
  xValues: Date[] | string[] | number[],
  yValues: Date[] | string[],
  zValues: number[][],
  xRef: string,
  yRef: string,
  minValue: number,
  isLongRange: boolean,
  valueType: ValueType | undefined
): Partial<Annotations>[] => {
  const annotations = [];
  if (zValues) {
    for (let i = 0; i < yValues.length; i++) {
      for (let j = 0; j < xValues.length; j++) {
        let textLabel = '';
        const currentValue = zValues?.[i]?.[j];
        if (!isLongRange) {
          if (currentValue === minValue - 0.1) textLabel = 'n/a';
          else if (valueType === ValueType.occ) {
            if (currentValue === 0) textLabel = 'Avai';
            else if (currentValue > 0) textLabel = 'Occ';
          } else textLabel = zValues?.[i]?.[j]?.toFixed(1) ?? '';
        }

        const result = {
          xref: xRef,
          yref: yRef,
          x: xValues[j],
          y: yValues[i],
          text: textLabel,
          font: {
            size: 10,
            color: 'white',
          },
          showarrow: false,
        } as unknown as Partial<Annotations>;
        annotations.push(result);
      }
    }
  }
  return annotations;
};

export const getZmaxValue = (
  data: (number | null)[] | undefined,
  varName: VarName
): number | undefined => {
  let maxValue;
  let bands = varNameBandParams[varName];
  if (varName === VarName.MotionEvent) bands = motionUtilisationBands;
  if (varName === VarName.OnlineStatus) maxValue = 1;
  else if (bands) {
    for (let i = 0; i < bands.length; i++) {
      const { upperBound } = bands[i];
      if (maxValue && upperBound > maxValue && upperBound !== Infinity) maxValue = upperBound;
      else if (maxValue === undefined && upperBound !== Infinity) maxValue = upperBound;
    }
  } else if (data) {
    for (let i = 0; i < data.length; i++) {
      const dataValue = data[i];
      if (dataValue !== null) {
        if (maxValue === undefined) maxValue = dataValue;
        else if (dataValue > maxValue) {
          maxValue = dataValue;
        }
      }
    }
  }
  return maxValue;
};

export const getColorScaleValue = (
  varName: VarName,
  minValue: number | undefined,
  maxValue: number | undefined,
  showGradient: boolean | undefined
): (string | number)[][] => {
  let bands;
  if (varName === VarName.MotionEvent) bands = motionUtilisationBands;
  else bands = varNameBandParams[varName];
  const colorScale: (string | number)[][] = [];
  if (minValue !== undefined && maxValue !== undefined) {
    const bandWithLowerBound = bands ? [...bands] : [];
    const lowestValue = minValue - 0.1;
    const lowerBound: BandParamsType = {
      upperBound: lowestValue,
      color: dataColours.grey,
      text: 'N/A',
      label: 'N/A',
    };
    bandWithLowerBound.unshift(lowerBound);
    if (!bands) {
      bandWithLowerBound.push({
        upperBound: minValue,
        color: '#9db7cc', // setting default min value colorscale to custom light blue
        text: 'N/A',
        label: 'N/A',
      });
      bandWithLowerBound.push({
        upperBound: maxValue,
        color: dataColours.blue,
        text: 'N/A',
        label: 'N/A',
      });
      bandWithLowerBound.push({
        upperBound: Infinity,
        color: dataColours.green,
        text: 'N/A',
        label: 'N/A',
      });
    }

    for (let i = 0; i < bandWithLowerBound.length; i++) {
      const { upperBound } = bandWithLowerBound[i];
      let gradientBound = upperBound;

      if (i === 0) gradientBound = upperBound;
      else if (i !== 0 && i < bandWithLowerBound.length)
        gradientBound =
          (bandWithLowerBound[i - 1].upperBound + bandWithLowerBound[i].upperBound) / 2;
      let colorBound = upperBound;
      if (showGradient) colorBound = gradientBound;
      if (minValue !== undefined && maxValue !== undefined) {
        const roundUpScale = (colorBound - lowestValue) / (maxValue - lowestValue);
        if (!showGradient) {
          if (upperBound !== Infinity && roundUpScale !== 1) {
            colorScale.push([roundUpScale, bandWithLowerBound[i].color]);
            colorScale.push([roundUpScale, bandWithLowerBound[i + 1].color]);
          } else if (roundUpScale === 1) {
            colorScale.push([0.999999999, bandWithLowerBound[i].color]);
            colorScale.push([1, bandWithLowerBound[i + 1].color]);
          } else if (varName === VarName.OnlineStatus) {
            colorScale.push([1, bandWithLowerBound[i].color]);
          }
        } else if (showGradient) {
          if (i === 0) {
            colorScale.push([roundUpScale, bandWithLowerBound[i].color]);
            colorScale.push([roundUpScale, bandWithLowerBound[i + 1].color]);
          } else if (upperBound !== Infinity) {
            colorScale.push([roundUpScale, bandWithLowerBound[i].color]);
          } else if (upperBound === Infinity) colorScale.push([1, bandWithLowerBound[i].color]);
        }
      }
    }
  }
  return colorScale;
};

const getSubPlotMinValue = (data: number[], zmin: number): number => {
  const nanValue = zmin - 0.1;
  let minValue = nanValue;
  for (let i = 0; i < data.length; i++) {
    if (minValue === nanValue && data[i] !== nanValue) minValue = data[i];
    else if (minValue && data[i] < minValue && data[i] !== nanValue) minValue = data[i];
  }
  return Number(minValue?.toFixed(1));
};

const getSubPlotAvgValue = (data: number[], zmin: number): number => {
  const nanValue = zmin - 0.1;
  let total = 0;
  let avgValue = nanValue;
  const validData = data.filter((value) => value !== nanValue && value !== null) as number[];
  for (let i = 0; i < validData.length; i++) {
    total += validData[i];
  }
  if (total) avgValue = Number((total / validData.length).toFixed(1));
  else if (total === 0) avgValue = total;
  return avgValue;
};

const getSubPlotMaxValue = (data: number[]): number => {
  let maxValue;
  for (let i = 0; i < data.length; i++) {
    if (!maxValue) maxValue = data[i];
    else if (data[i] > maxValue) maxValue = data[i];
  }
  return Number(maxValue?.toFixed(1));
};

export const getHighlightedItems = (yValues: Date[], hoursSelection: HoursSelection) => {
  const highlightedTimes: TimeRange[] = [];
  const { selectHours, includeWeekends } = hoursSelection;
  if (selectHours && !includeWeekends) {
    const weekendValues = yValues.filter(
      (day) => dayjs(day).get('day') === 0 || dayjs(day).get('day') === 6
    );

    if (weekendValues.length > 0) {
      for (let i = 0; i < weekendValues.length; i++) {
        highlightedTimes.push({
          start: dayjs(weekendValues[i]).startOf('day').unix() * 1000,
          end: dayjs(weekendValues[i]).endOf('day').unix() * 1000,
        });
      }
    }
  }
  return highlightedTimes;
};

// create an array for plot from Tot value that holds min, max and avg per day
const getTotMinMaxAvgPerDay = (mainPlotZValues: number[][], nanValue: number) => {
  const minMaxAvgPerDay: number[] = [];
  for (let i = 0; i < mainPlotZValues.length; i++) {
    // remove nan value
    const filteredZValues = mainPlotZValues[i].filter((value) => value !== nanValue);
    const minValue = Math.min.apply(null, filteredZValues);
    minMaxAvgPerDay.push(minValue !== Infinity && !Number.isNaN(minValue) ? minValue : nanValue);

    const avgValue = filteredZValues.reduce((p, c) => p + c, 0) / filteredZValues.length;
    minMaxAvgPerDay.push(avgValue !== Infinity && !Number.isNaN(avgValue) ? avgValue : nanValue);

    const maxValue = Math.max.apply(null, filteredZValues);
    minMaxAvgPerDay.push(maxValue !== Infinity && !Number.isNaN(maxValue) ? maxValue : nanValue);
  }
  return minMaxAvgPerDay;
};

export const getMinMaxAvgPerDay = (
  days: Date[],
  activeMarker: VarName,
  historyData: DailyMetricItem[],
  nanValue: number,
  mainPlotZValues: number[][] | undefined
): number[][] => {
  let minMaxAvgData: number[] = [];
  const hasTotValue =
    historyData.filter((data) => Object.prototype.hasOwnProperty.call(data, 'tot')).length > 0;
  // if has tot value then get min max avg from tot
  if (hasTotValue && mainPlotZValues) {
    minMaxAvgData = getTotMinMaxAvgPerDay(mainPlotZValues, nanValue);
  } else {
    const zValueData = [];
    for (let i = 0; i < days.length; i++) {
      const dayNum = dayjs(days[i]).get('day');
      const dataMetric = historyData.filter((data) => dayjs(data.date).get('day') === dayNum);
      const minValue = getMinDataMetricValue(dataMetric, activeMarker);
      const avgValue = getAvgDataMetricValue(dataMetric, activeMarker);
      const maxValue = getMaxDataMetricValue(dataMetric, activeMarker);

      if (minValue !== undefined && minValue !== null) zValueData.push(minValue);
      else zValueData.push(nanValue);
      if (avgValue !== undefined && avgValue !== null) zValueData.push(avgValue);
      else zValueData.push(nanValue);
      if (maxValue !== undefined && maxValue !== null) zValueData.push(maxValue);
      else zValueData.push(nanValue);
    }
    if (zValueData.length > 0) minMaxAvgData = zValueData;
  }
  return chunk(minMaxAvgData, 3);
};

const getWeekendFilteredData = (
  zValues: number[][] | undefined,
  yValues: Date[],
  includeWeekends: boolean
): number[][] | undefined => {
  let plotData: number[][] | undefined;
  if (!includeWeekends && zValues) {
    const allZValues: number[][] | undefined | (number[] | null)[] = [...zValues];
    const sunParam = yValues.find((data) => dayjs(data).get('day') === 0);
    const satParam = yValues.find((data) => dayjs(data).get('day') === 6);
    if (sunParam && allZValues !== undefined) {
      const sunIndex = yValues.indexOf(sunParam);
      allZValues.splice(sunIndex, 1, null);
    }
    if (satParam) {
      const satIndex = yValues.indexOf(satParam);
      allZValues?.splice(satIndex, 1, null);
    }
    const nullReplacedValues = allZValues?.filter((data) => data !== null) as number[][];
    if (nullReplacedValues) plotData = nullReplacedValues;
  } else plotData = zValues;
  return plotData;
};

const getSubPlotAlteredData = (
  zValues: number[][] | undefined,
  yValues: Date[],
  includeWeekends: boolean
): number[][] => {
  const zValueData: number[][] = [];
  const plotData = getWeekendFilteredData(zValues, yValues, includeWeekends);
  if (plotData) {
    for (let i = 0; i < plotData[0].length; i++) {
      const alteredData = plotData.map((data) => data[i]);
      if (alteredData) zValueData.push(alteredData);
    }
  }
  return zValueData;
};

export const getAvgPerColumn = (
  plotValues: number[][] | undefined,
  minValjue: number,
  days: Date[],
  includeWeekends: boolean
): number[][] => {
  const alteredData = getSubPlotAlteredData(plotValues, days, includeWeekends);
  const avgPerColumnValues: number[] = [];
  if (plotValues && alteredData.length === plotValues[0].length) {
    for (let i = 0; i < alteredData.length; i++) {
      const avgValue = getSubPlotAvgValue(alteredData[i], minValjue);
      if (avgValue !== undefined) avgPerColumnValues.push(avgValue);
      else avgPerColumnValues.push(minValjue - 0.1);
    }
  }
  return [avgPerColumnValues];
};

export const getMinMaxAvgPerColumn = (
  plotValues: number[][] | undefined,
  minValue: number,
  date: Date[],
  includeWeekends: boolean
): number[][] => {
  let minMaxAvgPerColValues: number[] = [];
  const alteredData = getSubPlotAlteredData(plotValues, date, includeWeekends);
  if (plotValues && alteredData.length === plotValues[0].length) {
    for (let i = 0; i < alteredData.length; i++) {
      const min = getSubPlotMinValue(alteredData[0], minValue);
      const avg = getSubPlotAvgValue(alteredData[1], minValue);
      const max = getSubPlotMaxValue(alteredData[2]);
      minMaxAvgPerColValues = [min, avg, max];
    }
  }
  return [minMaxAvgPerColValues];
};

export const getMainPlotData = (
  colorscaleValue: (string | number)[][],
  xValues: (Date | number)[],
  yValues: Date[],
  plotValues: number[][] | undefined,
  title: string,
  minValue: number,
  maxValue: number | undefined
): Data | undefined => {
  let plotlyData: Data | undefined;
  if (colorscaleValue) {
    plotlyData = {
      x: xValues,
      y: yValues,
      z: plotValues,
      hovertemplate: `${title}: %{x}<br>Day: %{y}<br>Value: %{z}<extra></extra>`,
      xgap: 3,
      ygap: 3,
      type: 'heatmap',
      colorscale: colorscaleValue as ColorScale,
      showscale: false,
      zmin: minValue !== undefined ? minValue : 0,
      zmax: maxValue !== undefined ? maxValue : 1,
    };
  }
  return plotlyData;
};

export const getMinMaxAvgPerDayData = (
  colorscaleValue: (string | number)[][],
  plotValues: number[][],
  days: Date[],
  zmin: number,
  zmax: number | undefined
): Data | undefined => {
  let plotlyData: Data | undefined;
  if (colorscaleValue) {
    plotlyData = {
      x: [PlotType.min, PlotType.avg, PlotType.max],
      xaxis: 'x2',
      y: days,
      z: plotValues,
      xgap: 3,
      ygap: 3,
      type: 'heatmap',
      colorscale: colorscaleValue as ColorScale,
      showscale: false,
      zmin: zmin !== undefined ? zmin : 0,
      zmax: zmax !== undefined ? zmax : 1,
    };
  }
  return plotlyData;
};

export const getAvgPerColumnData = (
  colorscaleValue: (string | number)[][],
  plotValues: number[][],
  xValues: (Date | number)[],
  minValue: number,
  maxValue: number | undefined
): Data | undefined => {
  let plotlyData: Data | undefined;
  if (colorscaleValue && plotValues) {
    plotlyData = {
      x: xValues,
      y: [PlotType.avg],
      yaxis: 'y2',
      z: plotValues,
      xgap: 3,
      ygap: 3,
      type: 'heatmap',
      colorscale: colorscaleValue as ColorScale,
      showscale: false,
      zmin: minValue !== undefined ? minValue : 0,
      zmax: maxValue !== undefined ? maxValue : 1,
    };
  }
  return plotlyData;
};

export const getMinMaxAvgPerColumnData = (
  colorscaleValue: (string | number)[][],
  plotValues: number[][],
  minValue: number,
  maxValue: number | undefined
): Data | undefined => {
  let plotlyData: Data | undefined;
  if (colorscaleValue && plotValues) {
    plotlyData = {
      x: [PlotType.min, PlotType.avg, PlotType.max],
      y: [PlotType.avg],
      xaxis: 'x2',
      yaxis: 'y2',
      z: plotValues,
      xgap: 3,
      ygap: 3,
      type: 'heatmap',
      colorscale: colorscaleValue as ColorScale,
      showscale: false,
      zmin: minValue !== undefined ? minValue : 0,
      zmax: maxValue !== undefined ? maxValue : 1,
    };
  }
  return plotlyData;
};

export const getCsvData = (
  minValue: number,
  mainPlotXValues: (Date | number)[],
  mainPlotYValues: Date[],
  mainPlotZValues: number[][] | undefined,
  minMaxAvgPerDay: number[][],
  avgPerHourColumn: number[][],
  minMaxAvgPerColumn: number[][],
  selectionType: CalendarSelectionType
) => {
  let exportPlotData =
    selectionType === CalendarSelectionType.week ? 'Day/Hour,' : 'Day/Start of Week,';
  if (mainPlotZValues) {
    // generate header line
    for (let i = 0; i < mainPlotXValues.length; i++) {
      if (selectionType === CalendarSelectionType.week) {
        exportPlotData += `${JSON.stringify(mainPlotXValues[i])},`;
      } else exportPlotData += `${dayjs(mainPlotXValues[i]).format('DD/MM/YYYY')},`;
    }
    exportPlotData += ', Min, Max, Avg\r\n\n';
    // add plot values to table starting with day
    for (let i = 0; i < mainPlotYValues.length; i++) {
      exportPlotData += `${dayjs(mainPlotYValues[i]).format('dddd')},`;
      const mainPlotValues = mainPlotZValues[i];
      const minMaxAvgPlotValues = minMaxAvgPerDay[i];
      for (let j = 0; j < mainPlotValues.length; j++) {
        let plotValue = mainPlotValues[j].toFixed(1);
        if (Number(mainPlotValues[j].toFixed(1)) === minValue - 0.1) plotValue = 'n/a';
        exportPlotData += `${plotValue},`;
      }
      exportPlotData += ',';
      for (let j = 0; j < minMaxAvgPlotValues.length; j++) {
        exportPlotData += `${minMaxAvgPlotValues[j]}${
          j === minMaxAvgPlotValues.length - 1 ? '' : ','
        }`;
      }
      exportPlotData += '\r\n';
    }

    // add average values from the subplot to table
    exportPlotData += '\nAverage,';
    const avgPerHourColumnPlotValues = avgPerHourColumn[0];
    for (let i = 0; i < avgPerHourColumnPlotValues.length; i++) {
      exportPlotData += `${avgPerHourColumnPlotValues[i]},`;
    }
    exportPlotData += ',';
    const minMaxAvgPerColumnPlotValues = minMaxAvgPerColumn[0];
    for (let i = 0; i < minMaxAvgPerColumnPlotValues.length; i++) {
      exportPlotData += `${minMaxAvgPerColumnPlotValues[i]}${
        i === minMaxAvgPerColumnPlotValues.length - 1 ? '' : ','
      }`;
    }
  }
  return exportPlotData;
};
