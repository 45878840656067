import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import CalendarViewDataContainer from '../Widgets/CalendarView/CalendarViewDataContainer';
import {
  getActiveMarker,
  getCurrentLocation,
  getSelectedHours,
  getSelectedStartDate,
  getSelectedEndDate,
  getCurrentLocationChildSensors,
} from '../state/selectors';
import {
  SensorValueItem,
  CalendarSelectionType,
  getAvgValue,
  getHourSet,
  HoveredPlot,
  processRawData,
} from '../Widgets/CalendarView/helpers';
import MapViewWrapper from '../Widgets/HelperComponents/MapViewWrapper';
import { DailyMetricItem, SensorLatest } from '../services/api';
import CalendarTimeSelection from '../Widgets/CalendarView/CalendarTimeSelection';
import DataStore from '../services/dataStore';

dayjs.extend(weekOfYear);
dayjs.extend(localizedFormat);

function CalendarView(): JSX.Element {
  const activeMarker = useSelector(getActiveMarker);
  const currentLocation = useSelector(getCurrentLocation);
  const selectedHours = useSelector(getSelectedHours);
  const startDate = useSelector(getSelectedStartDate);
  const endDate = useSelector(getSelectedEndDate);
  const sensorsInLoc = useSelector(getCurrentLocationChildSensors);
  const dataStore = DataStore.getInstance();

  const [loading, setLoading] = useState<boolean>(false);
  const [downloadData, setDownloadData] = useState<boolean>(false);
  const [locRawData, setlocRawData] = useState<DailyMetricItem[]>([]);
  const [locDailyData, setlocDailyData] = useState<DailyMetricItem[]>([]);
  const [hoveredPlot, setHoveredPlot] = useState<HoveredPlot>();
  const [selectionType, setSelectionType] = useState<CalendarSelectionType>(
    CalendarSelectionType.week
  );

  useEffect(() => setlocRawData([]), [currentLocation]);

  const hourSet = useMemo(() => {
    const { selectHours, startHour, endHour } = selectedHours;
    return new Set(getHourSet(selectHours, startHour, endHour));
  }, [selectedHours]);

  // update min/max/avg/tot value based on hourset
  useEffect(() => {
    const allHours = Array.from(hourSet);
    if (allHours.length === 24) setlocDailyData(locRawData);
    else setlocDailyData(processRawData(locRawData, allHours));
  }, [hourSet, locRawData]);

  // fetch the data from api and update data store
  useEffect(() => {
    setLoading(true);
    dataStore
      .getLocDailyMetricHistory(
        currentLocation,
        activeMarker,
        new Date(startDate).getTime(),
        new Date(endDate).getTime()
      )
      .then((history) => {
        if (history) {
          setlocRawData(history);
          setLoading(false);
        }
      })
      .catch(() => {
        setlocRawData([]);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMarker, currentLocation, startDate, endDate]);

  // create an array for sensors in loc with its summary values
  const sensorSummaryValues = useMemo(() => {
    const activeMarkerSensors = sensorsInLoc?.filter((sensor: SensorLatest) =>
      sensor?.data?.some((data) => data?.varName === activeMarker)
    );
    const sensorAverages: SensorValueItem[] = [];
    for (let i = 0; i < activeMarkerSensors.length; i++) {
      const avgValue = getAvgValue(
        locDailyData,
        activeMarkerSensors[i].id,
        activeMarker,
        selectedHours,
        hoveredPlot
      );
      if (avgValue !== undefined) {
        const hasValue = sensorAverages.some((data) => data.id === activeMarkerSensors[i].id);
        if (!hasValue) sensorAverages.push({ id: activeMarkerSensors[i].id, avg: avgValue });
        else {
          const sensorData = sensorAverages.find((data) => data.id === activeMarkerSensors[i].id);
          if (sensorData && sensorData.avg !== avgValue) {
            const valuePos = sensorAverages.indexOf(sensorData);
            sensorAverages[valuePos].avg = avgValue;
          }
        }
      }
    }
    return sensorAverages;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locDailyData, selectedHours, hoveredPlot, activeMarker]);

  return (
    <MapViewWrapper sensorSummaryValues={sensorSummaryValues}>
      <Box sx={{ paddingLeft: '10px' }}>
        <CalendarTimeSelection
          selectionType={selectionType}
          setSelectionType={setSelectionType}
          setDownloadData={setDownloadData}
        />
        <CalendarViewDataContainer
          sensorSummaryValues={sensorSummaryValues}
          locDailyData={locDailyData}
          hoveredPlot={hoveredPlot}
          setHoveredPlot={setHoveredPlot}
          selectionType={selectionType}
          downloadData={downloadData}
          setDownloadData={setDownloadData}
          loading={loading}
        />
      </Box>
    </MapViewWrapper>
  );
}

export default CalendarView;
