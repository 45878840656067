/* eslint-disable import/prefer-default-export */
import { PropLocationItem } from '../services/api';
import { fetchLocationProperties } from '../services/apiService';
import { LocationTreeNode } from '../state/types';

export enum LocationNameType {
  name = 'name',
  shortName = 'shortName',
}

export function getLocationName(
  locations: Map<string, LocationTreeNode> | undefined,
  locationId: string | undefined,
  type?: LocationNameType
): string {
  let name = '';
  if (!locationId) {
    return '';
  }

  if (locationId === '#') {
    return 'All';
  }

  if (!locations) {
    return locationId;
  }

  const path = locationId.slice(1).split('#');
  let currentNode = locations.get(path[0]);
  if (currentNode) {
    for (let i = 1; i < path.length; i++) {
      const nextNode: LocationTreeNode | undefined = currentNode?.children?.get(path[i]);
      if (!nextNode) {
        break;
      }
      currentNode = nextNode;
    }
  }

  if (type && type === LocationNameType.shortName) name = currentNode?.raw.shortName ?? locationId;
  else name = currentNode?.raw.name ?? locationId;

  return name;
}

// get relative parent information from current location
export function getParentLocationNode(
  locations: Map<string, LocationTreeNode> | undefined,
  locationId: string | undefined,
  stateLocId: string
): LocationTreeNode | undefined {
  if (!locationId || !locations || !stateLocId) {
    return undefined;
  }
  // compare child nodes and eliminate repeating ones
  const locPath = locationId.slice(1).split('#');
  const stateLocPath = stateLocId.slice(1).split('#');
  const initialNode = locations.get(locPath[0]);
  // use parentLoc[0] as the relative node for current location
  const parentLoc = locPath.filter((path) => !stateLocPath.includes(path));
  let currentNode = initialNode;
  let parentNode;
  if (currentNode && locPath.length >= 2) {
    if (stateLocId === '#') parentNode = initialNode;
    // dive into tree until parentLoc[0] matches as a key
    for (let i = 1; i < locPath.length; i++) {
      const nextNode: LocationTreeNode | undefined = currentNode?.children?.get(locPath[i]);
      if (locPath[i] === parentLoc[0] && nextNode?.id !== locationId) {
        parentNode = nextNode;
      }
      if (!nextNode) {
        break;
      }
      currentNode = nextNode;
    }
  }
  return parentNode;
}

export const getLocationData = async (
  locations: Map<string, LocationTreeNode>,
  locationId: string
): Promise<PropLocationItem | undefined> => {
  let locationDetails: PropLocationItem | undefined;
  if (locationId === '#') {
    // return undefined for error when fetching location properties
    locationDetails = await fetchLocationProperties('#').catch(() => undefined);
  } else {
    const path = locationId.slice(1).split('#');
    let currentNode = locations.get(path[0]);
    if (currentNode) {
      for (let i = 1; i < path.length; i++) {
        const nextNode: LocationTreeNode | undefined = currentNode?.children?.get(path[i]);
        if (!nextNode) {
          break;
        }
        currentNode = nextNode;
      }
    }
    locationDetails = currentNode?.raw ?? { id: '', location: locationId };
  }
  return locationDetails;
};

export const updateLocation = (
  newDetails: PropLocationItem,
  allLocations: Map<string, LocationTreeNode>
): Map<string, LocationTreeNode> => {
  const locationId = newDetails.id;
  const path = locationId.slice(1).split('#');
  if (path.length === 1) {
    const [id] = path;
    const nodeInMap = allLocations.get(id);
    if (!nodeInMap) {
      allLocations.set(id, {
        id: newDetails.id,
        raw: newDetails,
        children: new Map(),
      });
    }
  } else {
    let parentNode = allLocations.get(path[0]);
    for (let i = 1; i < path.length - 1; i++) {
      parentNode = parentNode?.children?.get(path[i]);
    }
    const finalNode = path[path.length - 1];
    parentNode?.children?.set(finalNode, {
      id: newDetails.id,
      raw: newDetails,
      children: new Map(),
    });
  }
  return allLocations;
};

export const removeLocation = (
  locationId: string,
  locations: Map<string, LocationTreeNode>
): Map<string, LocationTreeNode> => {
  const path = locationId.slice(1).split('#');
  let parentNode = locations.get(path[0]);
  for (let i = 1; i < path.length - 1; i++) {
    parentNode = parentNode?.children?.get(path[i]);
  }
  const finalNode = path[path.length - 1];
  parentNode?.children?.delete(finalNode);
  return locations;
};

export const getLocationNameTree = (
  locId: string,
  allLocations: Map<string, LocationTreeNode>,
  type?: LocationNameType
) => {
  const path = locId === '#' ? [] : locId?.slice(1).split('#');
  const breadCrumb = [];
  for (let i = 0; i <= path.length; ++i) {
    let id = '';
    for (let j = 0; j < i; j++) {
      id += `#${path[j]}`;
    }
    if (id === '') {
      id = '#';
    }
    const breadCrumbItem = { id, name: getLocationName(allLocations, id, type) };
    breadCrumb.push(breadCrumbItem);
  }
  return breadCrumb;
};
