import React, { useMemo } from 'react';
import { useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import chunk from 'lodash/chunk';
import Plot from '../PlotlyCustom';

import { DailyMetricItem, VarName } from '../../../services/api';
import {
  getColorScaleValue,
  getWeekPlotLayout,
  getWeekXValues,
  getWeekYValues,
  getWeekZValue,
  getZmaxValue,
  nullReplacementData,
  getSubPlotResult,
  getHighlightedItems,
  getMinMaxAvgPerDay,
  getAvgPerColumn,
  getMinMaxAvgPerColumn,
  getMainPlotData,
  getMinMaxAvgPerDayData,
  getAvgPerColumnData,
  getMinMaxAvgPerColumnData,
  getCsvData,
} from './calendarViewPlotHelpers';
import {
  getSelectedHours,
  getSelectedStartDate,
  getSelectedEndDate,
} from '../../../state/selectors';
import {
  CalendarSelectionType,
  getMinDataMetricValue,
  HoveredPlot,
  PlotType,
  ValueType,
} from '../../CalendarView/helpers';
import { TimeRange } from '../plotCommon';
import { varNameBandParams } from '../../../utils/dataBandParams';
import useStyles from '../../../styles/calendarView';

interface WeekViewPlotProps {
  historyData: DailyMetricItem[];
  varName: VarName;
  valueType: ValueType | undefined;
  showGradient?: boolean;
  setHoveredPlot?: ((value: HoveredPlot | undefined) => void) | undefined;
  downloadData: boolean;
  setDownloadData: (value: boolean) => void;
  sourceLabel: string;
}

function WeekViewPlot({
  historyData,
  varName,
  valueType,
  showGradient,
  setHoveredPlot,
  downloadData,
  setDownloadData,
  sourceLabel,
}: WeekViewPlotProps): JSX.Element | null {
  const classes = useStyles();
  const theme = useTheme();
  const selectedHours = useSelector(getSelectedHours);
  const startDate = useSelector(getSelectedStartDate);
  const endDate = useSelector(getSelectedEndDate);

  const selectedTimeRange: TimeRange = useMemo(
    () => ({
      start: dayjs(startDate).unix() * 1000,
      end: dayjs(endDate).unix() * 1000,
    }),
    [startDate, endDate]
  );

  const mainPlotXValues = useMemo(() => getWeekXValues(selectedHours), [selectedHours]);
  const mainPlotYValues = useMemo(() => getWeekYValues(selectedTimeRange), [selectedTimeRange]);
  const highlightTimes: TimeRange[] = getHighlightedItems(mainPlotYValues, selectedHours);

  const initialMainPlotZvalues = useMemo(
    () => getWeekZValue(varName, historyData, selectedTimeRange, selectedHours, valueType),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [historyData, selectedHours, valueType]
  );

  const zmax = useMemo(
    () => getZmaxValue(initialMainPlotZvalues, varName),
    [initialMainPlotZvalues, varName]
  );

  const zmin = useMemo(() => {
    const bands = varNameBandParams[varName];
    let zminValue = bands ? bands[0]?.upperBound : 0;
    const minValue = getMinDataMetricValue(historyData, varName);
    if (minValue !== null && minValue < zminValue) zminValue = minValue;
    return zminValue;
  }, [historyData, varName]);

  const colorscaleValue = useMemo(
    () => getColorScaleValue(varName, zmin, zmax, showGradient),
    [varName, zmin, zmax, showGradient]
  );

  const mainPlotZValues = useMemo(() => {
    let zValueData;
    if (mainPlotXValues) {
      const nullReplacedData = nullReplacementData(zmin, initialMainPlotZvalues);
      const filteredData = chunk(nullReplacedData, mainPlotXValues.length);
      if (filteredData) zValueData = filteredData;
    }
    return zValueData;
  }, [initialMainPlotZvalues, mainPlotXValues, zmin]);

  // zValues for subplot that shows min/max/avg from the main plot for each day
  const minMaxAvgPerDay: number[][] = getMinMaxAvgPerDay(
    mainPlotYValues,
    varName,
    historyData,
    zmin - 0.1,
    mainPlotZValues
  );

  // zValues for subplot that shows just the avg from the main plot for each hours column
  const avgPerHourColumn = getAvgPerColumn(
    mainPlotZValues,
    zmin,
    mainPlotYValues,
    selectedHours.includeWeekends
  );

  // zValues for subplot that shows actual min, max and avg of all time
  const minMaxAvgPerColumn = getMinMaxAvgPerColumn(
    minMaxAvgPerDay,
    zmin,
    mainPlotYValues,
    selectedHours.includeWeekends
  );

  const mainPlotData = getMainPlotData(
    colorscaleValue,
    mainPlotXValues,
    mainPlotYValues,
    mainPlotZValues,
    'Hour',
    zmin,
    zmax
  );

  // plotly data for subplot that shows min/max/avg from the main plot for each day
  const minMaxAvgPerDayData = getMinMaxAvgPerDayData(
    colorscaleValue,
    minMaxAvgPerDay,
    mainPlotYValues,
    zmin,
    zmax
  );

  // plotly data for subplot that shows just the avg from the main plot for each hours row
  const avgPerHourColumnData = getAvgPerColumnData(
    colorscaleValue,
    avgPerHourColumn,
    mainPlotXValues,
    zmin,
    zmax
  );

  // plotly data for subplot that shows min, max and avg of all time
  const minMaxAvgPerColumnData = getMinMaxAvgPerColumnData(
    colorscaleValue,
    minMaxAvgPerColumn,
    zmin,
    zmax
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const subPlots = [
    {
      x: [PlotType.min, PlotType.avg, PlotType.max],
      y: mainPlotYValues,
      z: minMaxAvgPerDay,
      xRef: 'x2',
      yRef: 'y',
    },
    { x: mainPlotXValues, y: [PlotType.avg], z: avgPerHourColumn, xRef: 'x', yRef: 'y2' },
    {
      x: [PlotType.min, PlotType.avg, PlotType.max],
      y: [PlotType.avg],
      z: minMaxAvgPerColumn,
      xRef: 'x2',
      yRef: 'y2',
    },
  ];

  const layout = useMemo(() => {
    let plotlyLayout;
    if (mainPlotXValues && mainPlotYValues && mainPlotZValues && zmin !== undefined) {
      plotlyLayout = getWeekPlotLayout(
        mainPlotXValues,
        mainPlotYValues,
        mainPlotZValues,
        zmin,
        valueType,
        highlightTimes,
        theme
      );
    }
    for (let i = 0; i < subPlots.length; i++) {
      const { x, y, z, xRef, yRef } = subPlots[i];
      const subPlotResult = getSubPlotResult(x, y, z, xRef, yRef, zmin, false, valueType);
      if (subPlotResult && plotlyLayout?.annotations) {
        const updatedLayout = plotlyLayout?.annotations.concat(subPlotResult);
        plotlyLayout.annotations = updatedLayout;
      }
    }
    return plotlyLayout;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zmin, valueType, highlightTimes]);

  const handleHover = (plotDatum: HoveredPlot | undefined) => {
    if (setHoveredPlot !== undefined) {
      setHoveredPlot(plotDatum);
    }
  };

  const hasAllPlotData =
    mainPlotData && minMaxAvgPerDayData && avgPerHourColumnData && minMaxAvgPerColumnData;

  const handleDownload = () => {
    setDownloadData(false);
    const csvData = getCsvData(
      zmin,
      mainPlotXValues,
      mainPlotYValues,
      mainPlotZValues,
      minMaxAvgPerDay,
      avgPerHourColumn,
      minMaxAvgPerColumn,
      CalendarSelectionType.week
    );
    if (csvData) {
      const csvFile = new Blob([csvData], { type: 'text/csv' });
      const downloadLink = document.createElement('a');
      downloadLink.download = `${sourceLabel}-${varName}-${dayjs(startDate).format('LL')}-${dayjs(
        endDate
      ).format('LL')}.csv`;
      downloadLink.href = window.URL.createObjectURL(csvFile);
      downloadLink.style.display = 'none';
      document.body.appendChild(downloadLink);
      downloadLink.click();
    }
    return null;
  };

  if (hasAllPlotData && layout) {
    return (
      <>
        {downloadData && handleDownload()}
        <Plot
          data={[mainPlotData, minMaxAvgPerDayData, avgPerHourColumnData, minMaxAvgPerColumnData]}
          layout={layout}
          className={classes.plotContainer}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onHover={(e) => handleHover({ x: e.points[0].x, y: e.points[0].y, z: e.points[0].z })}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onUnhover={() => handleHover(undefined)}
          config={{ displayModeBar: false }}
          useResizeHandler
        />
      </>
    );
  }
  return null;
}

WeekViewPlot.defaultProps = {
  showGradient: true,
  setHoveredPlot: undefined,
};

export default WeekViewPlot;
