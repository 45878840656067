import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { getLocationName, getParentLocationNode } from '../../utils/locations';
import { setCurrentLocation } from '../../state/actions';
import { getAvailableLocations, getCurrentLocation } from '../../state/selectors';
import useStyles from '../../styles';

interface LocationIdentifierProps {
  location: string;
}

function LocationIdentifier({ location }: LocationIdentifierProps): JSX.Element | null {
  const dispatch = useDispatch();
  const classes = useStyles();
  const availableLocations = useSelector(getAvailableLocations);
  const currentLocationId = useSelector(getCurrentLocation);
  const parentLocationNode = getParentLocationNode(availableLocations, location, currentLocationId);
  const locationName = getLocationName(availableLocations, location);
  const [hasMissingChildren, setHasMissingChildren] = useState<boolean>();

  useEffect(() => {
    const locPath = location.slice(1).split('#');
    const stateLocPath = currentLocationId.slice(1).split('#');
    const parentLoc = locPath.filter((path) => !stateLocPath.includes(path));
    setHasMissingChildren(parentLoc.length > 2);
  }, [currentLocationId, availableLocations, location]);

  const onLocationClick = (locationId: string | undefined) => {
    if (locationId) dispatch(setCurrentLocation(locationId));
  };

  // if we are in the location of the sensor we don't need a shortcut to go to it's location
  if (currentLocationId === location) return null;

  return (
    <>
      {parentLocationNode && parentLocationNode.raw.id !== currentLocationId && (
        <Box style={{ display: 'flex' }}>
          <Button
            startIcon={<LocationOnIcon />}
            onClick={() => onLocationClick(parentLocationNode.raw.id)}
            onKeyDown={() => onLocationClick(parentLocationNode.raw.id)}
            className={classes.baseSummaryLocationBtn}
          >
            {parentLocationNode.raw.name}
          </Button>
          <ArrowRightIcon style={{ alignSelf: 'center' }} fontSize="small" />
        </Box>
      )}
      {hasMissingChildren && (
        <>
          ...
          <ArrowRightIcon style={{ alignSelf: 'center' }} fontSize="small" />
        </>
      )}
      <Button
        startIcon={!parentLocationNode && <LocationOnIcon />}
        onClick={() => onLocationClick(location)}
        onKeyDown={() => onLocationClick(location)}
        className={classes.baseSummaryLocationBtn}
      >
        {locationName}
      </Button>
    </>
  );
}

export default LocationIdentifier;
