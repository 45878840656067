import React from 'react';
import { useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import PrintIcon from '@mui/icons-material/Print';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';

import { VarName, varNameDetails } from '../utils/varNames';
import { DailyMetricItem, HistoricData } from '../services/api';
import { getDataBandParams, varNameBandParams } from '../utils/dataBandParams';
import {
  isHistoryItem,
  isMetricItem,
  SensorHistoryPlotItem,
  SensorMetricPlotItem,
} from '../Widgets/Plots/plotCommon';
import varNameDescriptions, { BandParamDescription } from './varNameDescriptions';

import useStyles from '../styles/report';
import whiteLogo from '../logo_512x512_white.png';
import greenLogo from '../logo_512x512_teal.png';
import '../utils/print.css';
import { getThemeMode } from '../state/selectors';
import { ThemeMode } from '../state/types';

dayjs.extend(advancedFormat);

export interface ReportProps {
  stats: Map<VarName, SensorStatsSummary[]>;
  dateRange: [Date, Date];
  selectedHours: [number, number];
  includeWeekends: boolean;
  location: string;
  onClose: () => void;
  fetchingSensorData: boolean;
}

type MaxMinAvg = {
  max: number;
  min: number;
  mean: number;
};

type Stats = {
  bandTimes: Map<string, number>;
  totalTime: number;
  maxMinAvg: MaxMinAvg;
  totalAdded: number;
};

export type SensorStatsSummary = {
  sensorId: string;
  sensorName: string;
  stats: Stats;
};

const isSelectedTime = (
  time: number,
  selectedHours: [number, number],
  includeWeekends: boolean
): boolean => {
  // Checks whether a given time matches our selection criteria
  const m = dayjs.unix(time);
  const hour = m.hour();
  const day = m.day();
  if (
    hour < selectedHours[0] ||
    hour >= selectedHours[1] ||
    (!includeWeekends && (day === 0 || day === 6))
  ) {
    return false;
  }
  return true;
};

const calculateStats = (
  varName: VarName,
  history: HistoricData,
  selectedHours: [number, number],
  includeWeekends: boolean
): Stats => {
  const bandTimes: Map<string, number> = new Map();
  let totalTime = 0;
  let totalAdded = 0;

  let max = -Infinity;
  let min = Infinity;
  const values: number[] = [];
  for (let i = 1; i < history.time.length; i++) {
    const lastTime = history.time[i - 1];
    const time = history.time[i];

    if (isSelectedTime(time, selectedHours, includeWeekends)) {
      const band = getDataBandParams(varName, history.value[i]);
      // Update the statistics to include this chunk of time
      //  (our chunk of time is the size of the gap between this data point and the last one)
      bandTimes.set(band.label, (bandTimes.get(band.label) ?? 0) + (time - lastTime));
      // Update our count of time we have done statistics for to include this chunk
      totalTime += time - lastTime;
      // Update max/min if needed
      max = Math.max(max, history.value[i]);
      min = Math.min(min, history.value[i]);
      values.push(history.value[i]);
      const added = history.value[i] - history.value[i - 1] ?? 0;
      if (added > 0) totalAdded += added;
    }
  }
  const sum = values?.reduce((a, b) => a + b, 0) ?? 0;
  const mean = sum / Math.max(values.length, 1);

  return { bandTimes, totalTime, maxMinAvg: { max, min, mean }, totalAdded };
};

interface GapInfo {
  start: DailyMetricItem;
  end: DailyMetricItem;
  gapHours: number;
}

function findGaps(data: DailyMetricItem[]): GapInfo[] {
  // Parse the date strings into Date objects and sort the array by date
  const sortedData = data
    .map((item) => ({
      ...item,
      parsedDate: new Date(item.date ?? 0),
    }))
    .sort((a, b) => a.parsedDate.getTime() - b.parsedDate.getTime());
  const gaps: GapInfo[] = [];
  for (let i = 1; i < sortedData.length; i++) {
    const previousItem = sortedData[i - 1];
    const currentItem = sortedData[i];
    // Calculate the difference in hours between the two dates
    const diffInMs = currentItem.parsedDate.getTime() - previousItem.parsedDate.getTime();
    const diffInHours = diffInMs / (1000 * 60 * 60);
    if (diffInHours > 1) {
      gaps.push({
        start: previousItem,
        end: currentItem,
        gapHours: diffInHours, // Note: e.g. a 2 hour gap is only missing 1 item
      });
    }
  }
  return gaps;
}

const calculateMetricStats = (
  varName: VarName,
  metrics: DailyMetricItem[],
  selectedHours: [number, number],
  includeWeekends: boolean
): Stats => {
  // TODO XXX Check/improve calculations vs. expectation
  //  - Compare with raw calculations
  //  - If max/min in other band to avg increase the total in that band slighly (1/6?)
  let filteredMetrics = [];
  if ('hours' in metrics[0]) {
    // is daily data make one item per hour
    filteredMetrics = metrics.flatMap(
      (item) =>
        item.hours
          ?.map(({ h, ...props }) => {
            if (!item.date) return null;
            const dayOfWeek = new Date(item.date).getDay();
            const isWeekend = dayOfWeek === 0 || dayOfWeek === 6; // 0 = Sunday, 6 = Saturday
            const isInSelectedHours = h >= selectedHours[0] && h < selectedHours[1];

            if (!isInSelectedHours || (isWeekend && !includeWeekends)) {
              return null; // Exclude this item
            }

            return {
              id: item.id,
              date: `${item.date} ${String(h).padStart(2, '0')}:00:00`,
              ...props,
            } as DailyMetricItem;
          })
          .filter(Boolean) || [] // Filter out null values and return an empty array if hours is undefined
    ) as DailyMetricItem[];
  } else {
    filteredMetrics = metrics.filter((item) => {
      if (!item.date) return false;
      const hour = new Date(item.date).getHours();
      const day = new Date(item.date).getDay();
      return (
        hour >= selectedHours[0] &&
        hour < selectedHours[1] &&
        (includeWeekends || (day !== 0 && day !== 6))
      );
    });
  }

  const bandTimes: Map<string, number> = new Map();
  let totalTime = 0;
  let totalAdded = 0;

  let max = -Infinity;
  let min = Infinity;
  const values: number[] = [];
  filteredMetrics.forEach((item) => {
    if (item.max && item.min && item.avg) {
      const band = getDataBandParams(varName, item.avg);
      bandTimes.set(band.label, (bandTimes.get(band.label) ?? 0) + 1);
      totalTime += 1;
      max = Math.max(max, item.max);
      min = Math.min(min, item.min);
      values.push(item.avg);
      const added = item.tot ?? 0;
      if (added > 0) totalAdded += added;
    }
  });
  const sum = values?.reduce((a, b) => a + b, 0) ?? 0;
  const mean = sum / Math.max(values.length, 1);

  // For total data if there are gaps see if we can work out the missing total
  // If we are only considering specific times we can't work out a total from max/min
  // since don't know which specific hours/days the data occurs in
  if (totalAdded > 0 && selectedHours[0] === 0 && selectedHours[1] === 24 && includeWeekends) {
    const gaps = findGaps(filteredMetrics);
    gaps.forEach((gap) => {
      const change = (gap.end.min ?? 0) - (gap.start.max ?? 0);
      // If meter reset during gap then number might be -ve and we don't know what to add
      if (change > 0) totalAdded += change;
    });
  }

  return { bandTimes, totalTime, maxMinAvg: { max, min, mean }, totalAdded };
};

interface AnalysisData {
  varName: VarName;
  statsPerSensor: SensorStatsSummary[] | undefined;
}
function AnalysisSection({ varName, statsPerSensor }: AnalysisData): JSX.Element {
  const classes = useStyles();

  if (!statsPerSensor?.length) {
    return <h3>No data</h3>;
  }

  const bandDetails = varNameBandParams[varName];

  let max = -Infinity;
  let min = Infinity;
  const means: number[] = [];

  if (bandDetails) {
    const bandAnalysis = bandDetails.map((band) => {
      let totalTime = 0;
      let totalValue = 0;

      statsPerSensor.forEach(({ stats }) => {
        totalTime += stats.totalTime;
        totalValue += stats.bandTimes.get(band.label) ?? 0;
        max = Math.max(max, stats.maxMinAvg.max);
        min = Math.min(min, stats.maxMinAvg.min);
        means.push(stats.maxMinAvg.mean);
      });

      return {
        band,
        totalValue,
        totalTime,
        pct: (100 * totalValue) / totalTime,
      };
    });
    const sum = means?.reduce((a, b) => a + b, 0) ?? 0;
    const mean = sum / Math.max(means.length, 1);
    const maxMinAvg: MaxMinAvg = { max, min, mean };

    return (
      <>
        <h3>Analysis</h3>
        {/* <h4>Worst</h4>
        The worst performing space was *** which spent *** hours (***%) in band ***
        <h4>Best</h4>
        The best performing space was *** which spent *** hours (***%) in band *** */}
        <h4>Summary</h4>
        Table (below): Minimum, average (mean) and maximum values per sensor.
        <table className={classes.summaryTable}>
          <tbody>
            <tr key={`${varName}-vals-header`}>
              <th> </th>
              <th>Minimum</th>
              <th>Average</th>
              <th>Maximum</th>
            </tr>
            <tr key={`${varName}-AllSensors`}>
              <td>
                <b>All sensors</b>
              </td>
              <td
                className={classes.summaryTableBandCell}
                style={{ background: getDataBandParams(varName, maxMinAvg.min).color }}
              >
                {`${maxMinAvg.min} ${varNameDetails[varName].metric ?? ''}`}
              </td>
              <td
                className={classes.summaryTableBandCell}
                style={{ background: getDataBandParams(varName, maxMinAvg.mean).color }}
              >
                {`${maxMinAvg.mean.toFixed(0)} ${varNameDetails[varName].metric ?? ''}`}
              </td>
              <td
                className={classes.summaryTableBandCell}
                style={{ background: getDataBandParams(varName, maxMinAvg.max).color }}
              >
                {`${maxMinAvg.max} ${varNameDetails[varName].metric ?? ''}`}
              </td>
            </tr>
            {statsPerSensor
              .sort((a, b) => a.sensorName.localeCompare(b.sensorName))
              .map((s) => (
                <tr key={`${varName}-${s.sensorId}`}>
                  <td>{s.sensorName}</td>
                  <td
                    className={classes.summaryTableBandCell}
                    style={{ background: getDataBandParams(varName, s.stats.maxMinAvg.min).color }}
                  >
                    {`${s.stats.maxMinAvg.min} ${varNameDetails[varName].metric ?? ''}`}
                  </td>
                  <td
                    className={classes.summaryTableBandCell}
                    style={{ background: getDataBandParams(varName, s.stats.maxMinAvg.mean).color }}
                  >
                    {`${s.stats.maxMinAvg.mean.toFixed(0)} ${varNameDetails[varName].metric ?? ''}`}
                  </td>
                  <td
                    className={classes.summaryTableBandCell}
                    style={{ background: getDataBandParams(varName, s.stats.maxMinAvg.max).color }}
                  >
                    {`${s.stats.maxMinAvg.max} ${varNameDetails[varName].metric ?? ''}`}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <p />
        Table (below): Percentage of time measured in each band range. (For example if the sensor
        spends 1 of every 4 hours within a particular range and 3 of 4 in another those bands will
        show 25% and 75%.)
        <table className={classes.summaryTable}>
          <tbody>
            <tr key={`${varName}-header`}>
              <th> </th>
              {bandDetails.map((b) => (
                <th key={`${varName}-band-${b.label}`}>{b.label}</th>
              ))}
            </tr>
            <tr key={`${varName}-AllSensors`}>
              <td>
                <b>All sensors</b>
              </td>
              {bandAnalysis.map((b) => (
                <td
                  key={`${varName}-bandAnalysis-${b.band.label}`}
                  className={classes.summaryTableBandCell}
                  style={{ background: b.band.color }}
                >{`${b.pct.toFixed(1)}%`}</td>
              ))}
            </tr>
            {statsPerSensor
              .sort((a, b) => a.sensorName.localeCompare(b.sensorName))
              .map((s) => (
                <tr key={`${varName}-${s.sensorId}`}>
                  <td>{s.sensorName}</td>
                  {bandDetails.map((b) => {
                    const pct = (100 * (s.stats.bandTimes.get(b.label) ?? 0)) / s.stats.totalTime;
                    return (
                      <td
                        key={`${varName}-${s.sensorId}-band-${b.label}`}
                        className={classes.summaryTableBandCell}
                        style={pct > 0 ? { background: b.color } : { background: 'lightgrey' }}
                      >
                        {pct.toFixed(1)}%
                      </td>
                    );
                  })}
                </tr>
              ))}
          </tbody>
        </table>
      </>
    );
  }

  // Cumulative data calculate the total
  if (varName === VarName.EnergyInkWh) {
    const totals: number[] = [];
    statsPerSensor.forEach(({ stats }) => {
      totals.push(stats.totalAdded);
    });

    const sum = totals.reduce((a, b) => a + b);
    return (
      <>
        <h3>Analysis</h3>
        <h4>Summary</h4>
        Table (below): Total increase in recorded energy per sensor.
        <table className={classes.summaryTable}>
          <tbody>
            <tr key={`${varName}-vals-header`}>
              <th> </th>
              <th>Total</th>
            </tr>
            <tr key={`${varName}-AllSensors`}>
              <td>
                <b>All sensors</b>
              </td>
              <td
                className={classes.summaryTableBandCell}
                style={{ background: getDataBandParams(varName, sum).color }}
              >
                {`${sum.toFixed(1)} ${varNameDetails[varName].metric ?? ''}`}
              </td>
            </tr>
            {statsPerSensor
              .sort((a, b) => a.sensorName.localeCompare(b.sensorName))
              .map((s) => (
                <tr key={`${varName}-${s.sensorId}`}>
                  <td>{s.sensorName}</td>
                  <td className={classes.summaryTableBandCell} style={{ background: 'grey' }}>
                    {`${s.stats.totalAdded.toFixed(1)} ${varNameDetails[varName].metric ?? ''}`}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </>
    );
  }

  // No band details, do a basic summary
  statsPerSensor.forEach(({ stats }) => {
    max = Math.max(max, stats.maxMinAvg.max);
    min = Math.min(min, stats.maxMinAvg.min);
    means.push(stats.maxMinAvg.mean);
  });

  const sum = means.reduce((a, b) => a + b);
  const mean = sum / means.length;
  const maxMinAvg: MaxMinAvg = { max, min, mean };
  return (
    <>
      <h3>Analysis</h3>
      <h4>Summary</h4>
      Table (below): Minimum, average (mean) and maximum values per sensor.
      <table className={classes.summaryTable}>
        <tbody>
          <tr key={`${varName}-vals-header`}>
            <th> </th>
            <th>Minimum</th>
            <th>Average</th>
            <th>Maximum</th>
          </tr>
          <tr key={`${varName}-AllSensors`}>
            <td>
              <b>All sensors</b>
            </td>
            <td
              className={classes.summaryTableBandCell}
              style={{ background: getDataBandParams(varName, maxMinAvg.min).color }}
            >
              {`${maxMinAvg.min} ${varNameDetails[varName].metric ?? ''}`}
            </td>
            <td
              className={classes.summaryTableBandCell}
              style={{ background: getDataBandParams(varName, maxMinAvg.mean).color }}
            >
              {`${maxMinAvg.mean.toFixed(0)} ${varNameDetails[varName].metric ?? ''}`}
            </td>
            <td
              className={classes.summaryTableBandCell}
              style={{ background: getDataBandParams(varName, maxMinAvg.max).color }}
            >
              {`${maxMinAvg.max} ${varNameDetails[varName].metric ?? ''}`}
            </td>
          </tr>
          {statsPerSensor
            .sort((a, b) => a.sensorName.localeCompare(b.sensorName))
            .map((s) => (
              <tr key={`${varName}-${s.sensorId}`}>
                <td>{s.sensorName}</td>
                <td className={classes.summaryTableBandCell} style={{ background: 'grey' }}>
                  {`${s.stats.maxMinAvg.min} ${varNameDetails[varName].metric ?? ''}`}
                </td>
                <td className={classes.summaryTableBandCell} style={{ background: 'grey' }}>
                  {`${s.stats.maxMinAvg.mean.toFixed(0)} ${varNameDetails[varName].metric ?? ''}`}
                </td>
                <td className={classes.summaryTableBandCell} style={{ background: 'grey' }}>
                  {`${s.stats.maxMinAvg.max} ${varNameDetails[varName].metric ?? ''}`}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <p>No band details</p>
    </>
  );
}

export function statsPerVarNamePerSensor(
  data: SensorHistoryPlotItem[] | SensorMetricPlotItem[],
  selectedHours: [number, number],
  includeWeekends: boolean
) {
  const stats = new Map<VarName, SensorStatsSummary[]>();
  data.forEach((item) => {
    if ((isHistoryItem(item) && item.history.time.length > 0) || isMetricItem(item)) {
      let sensors = stats.get(item.varName);
      const sensorStats = isHistoryItem(item)
        ? calculateStats(item.varName, item.history, selectedHours, includeWeekends)
        : calculateMetricStats(item.varName, item.metrics, selectedHours, includeWeekends);
      const sensor = {
        sensorId: item.sensorId,
        sensorName: item.sensorName,
        stats: sensorStats,
      };
      if (!sensors) {
        sensors = [sensor];
        stats.set(item.varName, sensors);
      } else {
        sensors.push(sensor);
      }
    }
  });

  return stats;
}

function Report({
  stats,
  dateRange,
  selectedHours,
  includeWeekends,
  location,
  onClose,
  fetchingSensorData,
}: ReportProps): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const themeMode = useSelector(getThemeMode);

  const print = () => {
    window.print();
  };

  // ***********************************************************************************
  // NOTE: It is IMPORTANT that we only use HTML elements within the <main> content
  // so that we can apply the CSS print styles effectively when the user wants to print.
  // Using Typography from Material or other such components will complicate things.
  // ***********************************************************************************
  return (
    <div className={`${classes.report} print`}>
      <AppBar className={`${classes.header} no-print`}>
        <Toolbar>
          <img
            src={themeMode === ThemeMode.dark ? whiteLogo : greenLogo}
            alt="logo"
            height="35"
            width="35"
          />
          <div style={{ flex: '1 1 auto' }} />
          {fetchingSensorData && <CircularProgress style={{ color: 'white' }} size={20} />}
          <Button onClick={print} className={classes.headerButton} startIcon={<PrintIcon />}>
            Print
          </Button>
          <Button onClick={onClose} className={classes.headerButton} startIcon={<CloseIcon />}>
            Close
          </Button>
        </Toolbar>
      </AppBar>
      <main className={classes.main}>
        <h1>Report for {location}</h1>
        <p>Date of report: {dayjs().format('dddd MMMM Do YYYY, h:mm a')}</p>
        <p>
          Reporting for period {dayjs(dateRange[0]).format('dddd MMMM Do YYYY, h:mm a')} to{' '}
          {dayjs(dateRange[1]).format('dddd MMMM Do YYYY, h:mm a')}.
        </p>
        <p>
          Hours analysed are between {selectedHours[0]}:00 and {selectedHours[1]}:00. Weekends{' '}
          <b>are {!includeWeekends && 'not '}</b>included in the analysis.
        </p>
        <section>
          <h2>About this report</h2>
          <p>
            Using continuous monitoring data from multiple sensors distributed throughout occupied
            areas, we can assess the performance of building systems and policy. This analysis can
            be used ensure the right environment is being provided to occupants in all areas and
            under all conditions of use, insight that cannot be gained from spot checks alone. The
            factors measured can have a dramatic effect on comfort, productivity and the risk of
            viral transmission, this analysis can demonstrate the building health or, if necessary,
            identify areas that can be improved.
          </p>
          <p>The following analysis is split to consider each environmental factor.</p>
        </section>
        {Array.from(stats.keys())
          .sort((a, b) => {
            const pa = varNameDetails[a].reportSortOrder ?? Infinity;
            const pb = varNameDetails[b].reportSortOrder ?? Infinity;
            if (pa < pb) return -1;
            if (pa > pb) return 1;

            const la = varNameDetails[a].label;
            const lb = varNameDetails[b].label;
            if (la < lb) return -1;
            if (la > lb) return 1;

            return 0;
          })
          .map((varName) => (
            <section key={`reportSection-${varName}`}>
              <h2>{varNameDetails[varName].label}</h2>
              {varNameDescriptions[varName].description}
              {varNameBandParams[varName] && <BandParamDescription varName={varName} />}

              <AnalysisSection varName={varName} statsPerSensor={stats.get(varName)} />

              {varNameDescriptions[varName].footnotes && (
                <aside>
                  <h3>References</h3>
                  <ol>
                    {varNameDescriptions[varName].footnotes?.map((footnote) => (
                      <li key={`reportSection-reference-${footnote.id}`}>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={footnote.url}
                          style={{ color: theme.palette.text.primary }}
                        >
                          {footnote.text}
                        </a>
                        ,
                      </li>
                    ))}
                  </ol>
                </aside>
              )}
            </section>
          ))}
      </main>
    </div>
  );
}

export default Report;
