import React, { useMemo } from 'react';
import { useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import chunk from 'lodash/chunk';
import Plot from "../PlotlyCustom";
import { DailyMetricItem, VarName } from '../../../services/api';
import {
  getCalendarPlotLayout,
  getCalendarXvalues,
  getCalendarYvalues,
  getCalendarZvalue,
  getColorScaleValue,
  getZmaxValue,
  nullReplacementData,
  getSubPlotResult,
  getHighlightedItems,
  getMinMaxAvgPerDay,
  getAvgPerColumn,
  getMinMaxAvgPerColumn,
  getMainPlotData,
  getMinMaxAvgPerDayData,
  getAvgPerColumnData,
  getMinMaxAvgPerColumnData,
  getCsvData,
} from './calendarViewPlotHelpers';
import {
  getSelectedStartDate,
  getSelectedEndDate,
  getSelectedHours,
} from '../../../state/selectors';
import {
  CalendarSelectionType,
  getMinDataMetricValue,
  HoveredPlot,
  PlotType,
  ValueType,
} from '../../CalendarView/helpers';
import { TimeRange } from '../plotCommon';
import { varNameBandParams } from '../../../utils/dataBandParams';


interface CalendarViewPlotProps {
  historyData: DailyMetricItem[];
  varName: VarName;
  valueType: ValueType | undefined;
  showGradient?: boolean;
  setHoveredPlot?: ((value: HoveredPlot | undefined) => void) | undefined;
  downloadData: boolean;
  setDownloadData: (value: boolean) => void;
  sourceLabel: string;
}

function CalendarViewPlot({
  historyData,
  varName,
  valueType,
  showGradient,
  setHoveredPlot,
  downloadData,
  setDownloadData,
  sourceLabel,
}: CalendarViewPlotProps): JSX.Element | null {
  const theme = useTheme();
  const selectedHours = useSelector(getSelectedHours);
  const startDate = useSelector(getSelectedStartDate);
  const endDate = useSelector(getSelectedEndDate);

  const selectedTimeRange: TimeRange = useMemo(
    () => ({
      start: dayjs(startDate).unix() * 1000,
      end: dayjs(endDate).unix() * 1000,
    }),
    [startDate, endDate]
  );

  const mainPlotXValues = useMemo(() => getCalendarXvalues(selectedTimeRange), [selectedTimeRange]);
  const mainPlotYValues = useMemo(() => getCalendarYvalues(selectedTimeRange), [selectedTimeRange]);
  const highlightTimes = getHighlightedItems(mainPlotYValues, selectedHours);

  const initialMainPlotZvalues = useMemo(
    () => getCalendarZvalue(varName, historyData, selectedTimeRange, valueType),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [historyData, valueType, varName]
  );

  const zmax = useMemo(
    () => getZmaxValue(initialMainPlotZvalues, varName),
    [initialMainPlotZvalues, varName]
  );

  const zmin = useMemo(() => {
    const bands = varNameBandParams[varName];
    let zminValue = bands ? bands[0]?.upperBound : 0;
    const minValue = getMinDataMetricValue(historyData, varName);
    if (minValue !== null && minValue < zminValue) zminValue = minValue;
    return zminValue;
  }, [historyData, varName]);

  const colorscaleValue = useMemo(
    () => getColorScaleValue(varName, zmin, zmax, showGradient),
    [varName, zmin, zmax, showGradient]
  );

  const mainPlotZValues = useMemo(() => {
    let zValueData: number[][] = [];
    if (mainPlotXValues) {
      const { start, end } = selectedTimeRange;
      const nullReplacedData = nullReplacementData(zmin, initialMainPlotZvalues);
      const totalWeeks = Math.ceil(
        dayjs(end).endOf('week').diff(dayjs(start).startOf('week'), 'days') / 7
      );
      const weeklyData = [];
      for (let i = 0; i < 7; i++) {
        for (let j = 0; j < totalWeeks; j++) {
          weeklyData.push(nullReplacedData[i + j * 7]);
        }
      }
      if (weeklyData.length === totalWeeks * 7) zValueData = chunk(weeklyData, totalWeeks);
    }
    return zValueData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialMainPlotZvalues, zmin, selectedTimeRange]);

  // zValues for subplot that shows min/max/avg from the main plot for each day
  const minMaxAvgPerDay = getMinMaxAvgPerDay(
    mainPlotYValues,
    varName,
    historyData,
    zmin - 0.1,
    mainPlotZValues
  );

  // zValues for subplot that shows just the avg from the main plot for each day column
  const avgPerWeekColumn = getAvgPerColumn(
    mainPlotZValues,
    zmin,
    mainPlotYValues,
    selectedHours.includeWeekends
  );

  // zValues for subplot that shows actual min, max and avg of all time
  const minMaxAvgPerColumn = getMinMaxAvgPerColumn(
    minMaxAvgPerDay,
    zmin,
    mainPlotYValues,
    selectedHours.includeWeekends
  );

  const mainPlotData = getMainPlotData(
    colorscaleValue,
    mainPlotXValues,
    mainPlotYValues,
    mainPlotZValues,
    'Week Start',
    zmin,
    zmax
  );

  // plotly data for subplot that shows min/max/avg from the main plot for each day
  const minMaxAvgPerDayData = getMinMaxAvgPerDayData(
    colorscaleValue,
    minMaxAvgPerDay,
    mainPlotYValues,
    zmin,
    zmax
  );

  // plotly data for subplot that shows just the avg from the main plot
  const avgPerWeekColumnData = getAvgPerColumnData(
    colorscaleValue,
    avgPerWeekColumn,
    mainPlotXValues,
    zmin,
    zmax
  );

  // plotly data for subplot that shows avg of min/max/avg
  const minMaxAvgPerColumnData = getMinMaxAvgPerColumnData(
    colorscaleValue,
    minMaxAvgPerColumn,
    zmin,
    zmax
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const subPlots = [
    {
      x: [PlotType.min, PlotType.avg, PlotType.max],
      y: mainPlotYValues,
      z: minMaxAvgPerDay,
      xRef: 'x2',
      yRef: 'y',
    },
    { x: mainPlotXValues, y: [PlotType.avg], z: avgPerWeekColumn, xRef: 'x', yRef: 'y2' },
    {
      x: [PlotType.min, PlotType.avg, PlotType.max],
      y: [PlotType.avg],
      z: minMaxAvgPerColumn,
      xRef: 'x2',
      yRef: 'y2',
    },
  ];

  const layout = useMemo(() => {
    let plotlyLayout;
    const isLongRange = dayjs(endDate).diff(dayjs(startDate), 'days') > 180;
    if (mainPlotXValues && mainPlotYValues && mainPlotZValues) {
      plotlyLayout = getCalendarPlotLayout(
        mainPlotXValues,
        mainPlotYValues,
        mainPlotZValues,
        zmin,
        isLongRange,
        highlightTimes,
        valueType,
        theme
      );
    }
    for (let i = 0; i < subPlots.length; i++) {
      const { x, y, z, xRef, yRef } = subPlots[i];
      const subPlotResult = getSubPlotResult(x, y, z, xRef, yRef, zmin, isLongRange, valueType);
      if (subPlotResult && plotlyLayout?.annotations) {
        const updatedLayout = plotlyLayout?.annotations.concat(subPlotResult);
        plotlyLayout.annotations = updatedLayout;
      }
    }
    return plotlyLayout;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zmin, highlightTimes, valueType]);

  const hasAllPlotData =
    mainPlotData && minMaxAvgPerDayData && avgPerWeekColumnData && minMaxAvgPerColumnData;

  const handleHover = (plotDatum: HoveredPlot | undefined) => {
    if (setHoveredPlot !== undefined) {
      setHoveredPlot(plotDatum);
    }
  };

  const handleDownload = () => {
    setDownloadData(false);
    const csvData = getCsvData(
      zmin,
      mainPlotXValues,
      mainPlotYValues,
      mainPlotZValues,
      minMaxAvgPerDay,
      avgPerWeekColumn,
      minMaxAvgPerColumn,
      CalendarSelectionType.calendar
    );
    if (csvData) {
      const csvFile = new Blob([csvData], { type: 'text/csv' });
      const downloadLink = document.createElement('a');
      downloadLink.download = `${sourceLabel}-${varName}-${dayjs(startDate).format('LL')}-${dayjs(
        endDate
      ).format('LL')}.csv`;
      downloadLink.href = window.URL.createObjectURL(csvFile);
      downloadLink.style.display = 'none';
      document.body.appendChild(downloadLink);
      downloadLink.click();
    }
    return null;
  };

  if (hasAllPlotData && layout) {
    return (
      <>
        {downloadData && handleDownload()}
        <Plot
          data={[mainPlotData, minMaxAvgPerDayData, avgPerWeekColumnData, minMaxAvgPerColumnData]}
          layout={layout}
          style={{ width: '100%', height: '100%' }}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onHover={(e) => handleHover({ x: e.points[0].x, y: e.points[0].y, z: e.points[0].z })}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onUnhover={() => handleHover(undefined)}
          config={{ displayModeBar: false }}
          useResizeHandler
        />
      </>
    );
  }
  return null;
}

CalendarViewPlot.defaultProps = {
  showGradient: true,
  setHoveredPlot: undefined,
};

export default CalendarViewPlot;
