/* eslint-disable import/prefer-default-export */

import { Position, SensorLatest } from '../services/api';
import { NearBySensor } from '../state/types';

const findPositionBySignalAndMostRecent = async (
  bleSensors: NearBySensor[] | undefined,
  sensorsInLocation: SensorLatest[] | undefined,
  appScanStatus: boolean
) => {
  let targetPosition: Position | undefined;
  let targetPolygon;
  const sum = { x: 0, y: 0, m: 0 };
  if (bleSensors && appScanStatus) {
    const strongestRssi = bleSensors[0].rssi;
    const mostRecent = bleSensors.sort((a, b) => b.lastSeen - a.lastSeen)[0].lastSeen;
    for (let i = 0; i < bleSensors.length; i++) {
      const { id, rssi, lastSeen } = bleSensors[i];
      const sensorPosition = sensorsInLocation?.find((sensor) => sensor.id === id)?.position;
      if (!targetPolygon) targetPolygon = sensorPosition?.polygon;
      if (sensorPosition) {
        const { lat, lng } = sensorPosition;
        if (lat && lng) {
          const signalStrength = 10 ** ((rssi - strongestRssi) / (10 * 2));
          const timeDiff = mostRecent - lastSeen;
          let lastSeenStrength;
          if (timeDiff < 10) lastSeenStrength = 1;
          else if (timeDiff < 60) lastSeenStrength = 0.5;
          else lastSeenStrength = 0.25;
          sum.x += (signalStrength + lastSeenStrength) * lat;
          sum.y += (signalStrength + lastSeenStrength) * lng;
          sum.m += signalStrength + lastSeenStrength;
        }
      }
    }
  }
  if (sum.x && sum.y && sum.m)
    targetPosition = { lat: sum.x / sum.m, lng: sum.y / sum.m, polygon: targetPolygon };
  return targetPosition ?? null;
};

export const getBleSensorTargetPosition = async (
  bleSensors: NearBySensor[] | undefined,
  sensorsInLocation: SensorLatest[] | undefined,
  appScanStatus: boolean
) => findPositionBySignalAndMostRecent(bleSensors, sensorsInLocation, appScanStatus);
