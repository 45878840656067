/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useMemo } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MenuList from '@mui/material/MenuList';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
import MenuIcon from '@mui/icons-material/Menu';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import RefreshIcon from '@mui/icons-material/Refresh';
import HelpIcon from '@mui/icons-material/Help';
import LocationSwitcher from './LocationSwitcher';
import SearchBox from './SearchBox';
import {
  getBleSensors,
  getShowHelp,
  getShowNavDrawer,
  getTempUser,
  getThemeMode,
} from '../state/selectors';
import { setShowNavDrawer, setThemeMode, showHelp } from '../state/actions';
import DataStore from '../services/dataStore';
import { TempUser, ThemeMode } from '../state/types';
import { appSwitchBtnHandler, handleLogout, tempUserExpiryStr } from '../Shell/helpers';
import { themeProps } from '../styles/theme';
import BackBtnIcon from '../styles/icons/BackBtn';

enum SettingsAction {
  help = 'help',
  refresh = 'refresh',
  theme = 'theme',
}

interface HeaderProps {
  showSearchField?: boolean;
  autoSwitchLoc?: boolean; // if we can autoselect next location if there is only one subLocation
  children?: React.ReactChild;
}

function Header({ showSearchField, autoSwitchLoc, children }: HeaderProps): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const themeMode = useSelector(getThemeMode);
  const [userAnchor, setUserAnchor] = useState<null | HTMLElement>(null);
  const [settingsAnchor, setSettingsAnchor] = useState<null | HTMLElement>(null);
  const openUserMenu = Boolean(userAnchor);
  const openSettingsMenu = Boolean(settingsAnchor);
  const showingHelp = useSelector(getShowHelp);
  const tempUser: TempUser | null = useSelector(getTempUser);
  const showNavDrawer = useSelector(getShowNavDrawer);
  const bleSensors = useSelector(getBleSensors);
  const { user } = useAuthenticator((context) => [context.user]);

  const navigateToProfile = () => {
    setUserAnchor(null);
    navigate('/userProfile');
  };

  const userName = tempUser?.expiry
    ? tempUserExpiryStr(tempUser.expiry)
    : user?.attributes?.email ?? 'Unknown';

  const onSettingsClick = (type: SettingsAction) => {
    setSettingsAnchor(null);
    if (type === SettingsAction.help) dispatch(showHelp(!showingHelp));
    else if (type === SettingsAction.refresh) DataStore.getInstance().refreshAllData();
  };

  // to avoid multiple background rendering
  const showAppSwitchBtn = useMemo(() => !!bleSensors, [bleSensors]);

  return (
    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
      {showAppSwitchBtn && (
        <Box sx={{ display: { lg: 'none' }, alignSelf: 'center' }}>
          <IconButton
            style={{ paddingTop: '5px', marginRight: '5px' }}
            onClick={() => appSwitchBtnHandler(dispatch)}
          >
            <BackBtnIcon />
          </IconButton>
        </Box>
      )}
      <Box
        sx={{
          alignSelf: 'center',
          width: { xs: '90%', xl: '800px', lg: '50%' },
          overflowX: { xs: 'scroll' },
        }}
      >
        {children || <LocationSwitcher autoSwitchLoc={autoSwitchLoc} />}
      </Box>
      <Box sx={{ display: { xs: 'none', lg: 'flex' } }}>
        {showSearchField && <SearchBox />}
        <IconButton
          onClick={() =>
            dispatch(setThemeMode(themeMode === ThemeMode.dark ? ThemeMode.light : ThemeMode.dark))
          }
          style={{ marginRight: '10px', height: 'fit-content' }}
        >
          {themeMode === ThemeMode.dark ? (
            <LightModeIcon fontSize="small" />
          ) : (
            <DarkModeIcon fontSize="small" />
          )}
        </IconButton>
        <IconButton
          data-testid="settings-btn"
          onClick={(event) => setSettingsAnchor(event.currentTarget)}
          style={{ marginRight: '10px', height: 'fit-content' }}
        >
          <SettingsIcon fontSize="small" />
        </IconButton>
        <Menu
          id="settings-menu"
          anchorEl={settingsAnchor}
          open={openSettingsMenu}
          onClose={() => setSettingsAnchor(null)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MenuList sx={{ padding: 0 }}>
            <MenuItem onClick={() => onSettingsClick(SettingsAction.help)}>
              <HelpIcon />
              <span style={{ marginLeft: '5px' }}>Help</span>
            </MenuItem>
            <MenuItem onClick={() => onSettingsClick(SettingsAction.refresh)}>
              <RefreshIcon />
              <span style={{ marginLeft: '5px' }}>Refresh</span>
            </MenuItem>
          </MenuList>
        </Menu>
        <Button
          variant={themeProps.btnVariant.default}
          data-testid="user-btn"
          style={{
            padding: '5px 10px',
            borderRadius: '8px',
            textTransform: 'lowercase',
            height: 'fit-content',
          }}
          startIcon={<PersonIcon />}
          onClick={(event) => setUserAnchor(event.currentTarget)}
        >
          {userName}
        </Button>
        <Menu
          id="user-menu"
          anchorEl={userAnchor}
          open={openUserMenu}
          onClose={() => setUserAnchor(null)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MenuItem onClick={navigateToProfile}>User Profile</MenuItem>
          <MenuItem onClick={() => handleLogout(tempUser, dispatch)}>Logout</MenuItem>
        </Menu>
      </Box>
      <Box sx={{ display: { lg: 'none' }, alignSelf: 'center' }}>
        <IconButton
          style={{ paddingTop: '5px', marginLeft: '5px' }}
          onClick={() => dispatch(setShowNavDrawer(!showNavDrawer))}
        >
          <MenuIcon />
        </IconButton>
      </Box>
    </div>
  );
}

Header.defaultProps = {
  showSearchField: true,
  autoSwitchLoc: true,
  children: null,
};

export default Header;
