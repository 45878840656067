import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getActiveMarker } from '../../state/selectors';
import { VarName } from '../../utils/varNames';
import { SensorValueItem } from './helpers';
import useGlobalStyles from '../../styles';
import useStyles from '../../styles/calendarView';
import {
  BandParamsType,
  varNameBandParams,
  dataColours,
  motionUtilisationBands,
} from '../../utils/dataBandParams';
import { BandValues } from '../SensorArrayWidgets/base';
import { themeProps } from '../../styles/theme';
import BaseArraySortMenu, { SortPropertyType } from '../HelperComponents/BaseArraySortMenu';

interface CalendarViewBaseSummaryProps {
  sensorSummaryValues: SensorValueItem[];
  expanded: boolean;
}

function CalendarViewBaseSummary({
  sensorSummaryValues,
  expanded,
}: CalendarViewBaseSummaryProps): JSX.Element {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const activeMarker = useSelector(getActiveMarker);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const noDataSensors: string[] = [];

  const allVarNameData = useMemo(() => {
    const data: number[] = [];
    for (let i = 0; i < sensorSummaryValues.length; i++) {
      const avgValue = sensorSummaryValues[i]?.avg;
      if (avgValue !== undefined && avgValue !== null) data.push(avgValue);
      else noDataSensors.push(sensorSummaryValues[i].id);
    }
    return data;
  }, [sensorSummaryValues, noDataSensors]);

  const bandDetails: BandParamsType[] = useMemo(() => {
    let bandParams = varNameBandParams[activeMarker] as BandParamsType[];
    if (activeMarker === VarName.MotionEvent) {
      bandParams = motionUtilisationBands;
    }
    return bandParams;
  }, [activeMarker]);

  const perBandSums: BandValues[] = useMemo(() => {
    let bandSums: BandValues[];
    if (bandDetails) {
      const result: BandValues[] = bandDetails.map(({ color }) => ({
        total: 0,
        pct: 0,
        colour: color,
      }));

      allVarNameData.forEach((value: number) => {
        for (let i = 0; i < result.length; i++) {
          if ((value ?? Infinity) <= bandDetails[i].upperBound) {
            result[i].total += 1;
            break;
          }
        }
      });
      bandSums = result;
    } else {
      bandSums = [{ total: allVarNameData.length, pct: 0, colour: themeProps.colors.infoBlue }];
    }

    return bandSums;
  }, [bandDetails, allVarNameData]);

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      <div>
        {perBandSums.map((band, index) => {
          const { total, colour } = band;
          return (
            <span
              className={`${globalClasses.pillLabel} ${classes.occupancyPill}`}
              style={{ background: colour }}
              key={`${Math.random()}-${band.colour}`}
            >
              {total} {bandDetails ? bandDetails[index].label : ''}
            </span>
          );
        })}
        {noDataSensors.length > 0 && (
          <span
            className={`${globalClasses.pillLabel} ${classes.occupancyPill}`}
            style={{ background: dataColours.grey }}
          >
            {noDataSensors.length} No Data
          </span>
        )}
      </div>
      {expanded && (
        <BaseArraySortMenu sortProperties={[SortPropertyType.name, SortPropertyType.value]} />
      )}
    </div>
  );
}

export default CalendarViewBaseSummary;
