import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import FormGroup from '@mui/material/FormGroup';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import dayjs from 'dayjs';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
  DailyMetricItem,
  DailyMetricProjectTypes,
  SensorLatest,
  VarName,
} from '../../services/api';
import { CalendarSelectionType, getHourSet, ValueType } from '../CalendarView/helpers';
import { getSensorDailyData } from '../../services/apiService';
import { getSelectedHours, getSelectedStartDate, getSelectedEndDate } from '../../state/selectors';
import CalendarViewPlot from '../Plots/HeatMap/CalendarViewPlot';
import useStyles from '../../styles/index';
import WeekViewPlot from '../Plots/HeatMap/WeekViewPlot';
import CalendarTimeSelection from '../CalendarView/CalendarTimeSelection';
import LoaderWithBackdrop from '../HelperComponents/LoaderWithBackdrop';
import { themeProps } from '../../styles/theme';

interface CalendarViewDialogProps {
  showDialog: boolean;
  sensor: SensorLatest;
  varName: VarName;
  cancelHandler: () => void;
  historyData?: DailyMetricItem[];
}

function CalendarViewDialog({
  showDialog,
  sensor,
  varName,
  cancelHandler,
  historyData,
}: CalendarViewDialogProps): JSX.Element {
  const classes = useStyles();
  const [valueType, setValueType] = useState(ValueType.avg);
  const [sensorHistoryData, setSensorHistoryData] = useState<DailyMetricItem[]>([]);
  const [showGradient, setShowGradient] = useState<boolean>(true);
  const [selectionType, setSelectionType] = useState<CalendarSelectionType>(
    CalendarSelectionType.week
  );
  const [loading, setLoading] = useState<boolean>();
  const [downloadData, setDownloadData] = useState<boolean>(false);
  const selectedHours = useSelector(getSelectedHours);
  const startDate = useSelector(getSelectedStartDate);
  const endDate = useSelector(getSelectedEndDate);

  const hasHourValues = sensorHistoryData.find((data) => data.hours !== undefined);
  const allValueType = [ValueType.avg, ValueType.min, ValueType.max];
  const motionValueType = [ValueType.utl, ValueType.occ];
  const formValueTypeMenu = varName === VarName.MotionEvent ? motionValueType : allValueType;

  const hourSet = useMemo(() => {
    const { selectHours, startHour, endHour } = selectedHours;
    return new Set(getHourSet(selectHours, startHour, endHour));
  }, [selectedHours]);

  const getSensorHistory = () => {
    setLoading(true);
    let project: DailyMetricProjectTypes[] = [DailyMetricProjectTypes.All];
    if (selectionType === CalendarSelectionType.calendar) {
      project = [DailyMetricProjectTypes.Days];
    }
    getSensorDailyData(
      varName,
      sensor.id,
      dayjs(startDate).format('YYYY-MM-DD'),
      dayjs(endDate).format('YYYY-MM-DD'),
      hourSet,
      project
    )
      .then((history) => {
        if (history) {
          setSensorHistoryData(history);
          setLoading(false);
        }
      })
      .catch(() => setLoading(false));
  };

  // check if historyData is passed on the first load
  useEffect(() => {
    if (historyData) {
      setSensorHistoryData(historyData.filter((data) => data.id === sensor.id));
    } else getSensorHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historyData]);

  // fetch api when parameters are changed
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getSensorHistory(), [endDate, selectionType, startDate, varName]);

  useEffect(() => {
    if (varName === VarName.MotionEvent) setValueType(ValueType.utl);
    else setValueType(ValueType.avg);
  }, [varName]);

  const showCalendarView = () => (
    <>
      <CalendarTimeSelection
        selectionType={selectionType}
        setSelectionType={setSelectionType}
        setDownloadData={setDownloadData}
      />
      <div>
        <FormGroup
          row
          className={classes.reportToolbar}
          style={{ padding: '0 2rem', justifyContent: 'center' }}
        >
          {setValueType !== undefined && (
            <FormGroup row sx={{ marginTop: '10px' }}>
              <InputLabel id="time-year">Select value type</InputLabel>
              <Select
                label="year"
                labelId="time-year"
                defaultValue={valueType}
                style={{ marginBottom: '10px', width: '100%' }}
                onChange={(e) => setValueType(e.target.value as ValueType)}
                variant="standard"
              >
                {formValueTypeMenu.map((type) => (
                  <MenuItem value={type} key={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormGroup>
          )}
        </FormGroup>
      </div>
      {sensorHistoryData && (
        <>
          <Button
            onClick={() => setShowGradient(!showGradient)}
            style={{ display: 'flex', marginLeft: 'auto' }}
            variant="outlined"
          >
            {showGradient ? 'Change color layout' : 'Show Gradient'}
          </Button>
          <div style={{ position: 'relative' }}>
            {loading && <LoaderWithBackdrop />}
            {hasHourValues ? (
              <WeekViewPlot
                historyData={sensorHistoryData}
                varName={varName}
                valueType={valueType}
                showGradient={showGradient}
                downloadData={downloadData}
                setDownloadData={setDownloadData}
                sourceLabel={sensor?.name ?? ''}
              />
            ) : (
              <CalendarViewPlot
                historyData={sensorHistoryData}
                varName={varName}
                valueType={valueType}
                showGradient={showGradient}
                downloadData={downloadData}
                setDownloadData={setDownloadData}
                sourceLabel={sensor?.name ?? ''}
              />
            )}
          </div>
        </>
      )}
    </>
  );

  const showHeader = () => (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Typography variant="h5">{sensor?.name ?? ''}</Typography>
    </div>
  );

  return (
    <Dialog open={showDialog} fullWidth maxWidth="lg">
      <DialogTitle id="alert-dialog-title">{showHeader()}</DialogTitle>
      <DialogContent>{showCalendarView()}</DialogContent>
      <DialogActions>
        <Button color="secondary" variant={themeProps.btnVariant.default} onClick={cancelHandler}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CalendarViewDialog.defaultProps = {
  historyData: undefined,
};

export default CalendarViewDialog;
