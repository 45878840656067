import React, { useState, useEffect, ChangeEvent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InfoIcon from '@mui/icons-material/Info';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink as RouterLink } from 'react-router-dom';
import {
  updateSensorProperties,
  fetchSensorLatest,
  fetchLocationFloorplan,
  fetchVisibleItems,
} from '../../../services/apiService';
import {
  SensorLatest,
  GeoJSON,
  GatewaySensorType,
  BacnetSettings,
} from '../../../services/api/api';
import { setSensorsById } from '../../../state/actions';
import { SensorItem, SensorPropsType, sensorProperties } from '../../../utils/sensorProperties';
import useStyles from '../../../styles';
import {
  getAllLocationFloorplan,
  getAvailableLocations,
  getSensorsById,
} from '../../../state/selectors';
import AdvancedSensordetails from './AdvancedSensorDetails';
import { getShortTimeStr } from '../../../utils/functions';
import SensorPosition from './SensorPosition';
import EditBtnIcon from '../../HelperComponents/EditBtnIcon';
import { AlertMsg } from '../../LocationConfig/LocationProperties';
import RSSILookup from '../RSSILookup';
import SensorBacnetConfig from './SensorBacnetConfig';
import { getLocationNameTree } from '../../../utils/locations';

interface SensorDetailsProps {
  sensorId: string;
  sensorDetails?: SensorLatest;
  bacnetConfig?: BacnetSettings;
  isBacnetEditable?: boolean;
  bacnetAlertMsg?: string;
}

function SensorDetails({
  sensorId,
  sensorDetails,
  bacnetConfig,
  isBacnetEditable,
  bacnetAlertMsg,
}: SensorDetailsProps): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isEditing, setIsEditing] = useState(false);
  const [sensorItem, setSensorItem] = useState<SensorItem>();
  const [sensor, setSensor] = useState<SensorLatest | undefined>();
  const [alertMsg, setAlertMsg] = useState<AlertMsg>();
  const [floorPlan, setFloorPlan] = useState<GeoJSON>();
  const [sensorRSSI, setSensorRSSI] = useState<number>();
  const [rssiTimeStamp, setRSSITimeStamp] = useState<string>();

  const allFloorPlan = useSelector(getAllLocationFloorplan);
  const allLocations = useSelector(getAvailableLocations);
  const sensorsById = useSelector(getSensorsById);

  const sensorIsGateway = Object.values(GatewaySensorType).includes(
    sensor?.type as GatewaySensorType
  );

  useEffect(() => {
    async function getSensorRSSI() {
      if (!sensorIsGateway && sensor) {
        const { gateway } = sensor;
        if (gateway) {
          const visibleSubsensors = (await fetchVisibleItems(gateway)).subsensors;
          const subSensorData = visibleSubsensors?.find(
            (visibleSubsensor) => visibleSubsensor.suggested_id === sensor.id
          );
          if (subSensorData?.rssi) setSensorRSSI(subSensorData.rssi);
          if (subSensorData?.last_seen) setRSSITimeStamp(getShortTimeStr(subSensorData.last_seen));
        }
      }
    }
    getSensorRSSI();
  }, [sensor, sensorIsGateway]);

  const getFloorPlan = useCallback(() => {
    if (sensorDetails?.location) {
      const currentLocationFloorPlan = allFloorPlan.get(sensorDetails.location);
      if (currentLocationFloorPlan) {
        setFloorPlan(currentLocationFloorPlan);
      } else {
        fetchLocationFloorplan(sensorDetails.location).then((floorplan) => setFloorPlan(floorplan));
      }
    }
  }, [allFloorPlan, sensorDetails?.location]);

  useEffect(() => {
    if (sensorDetails) {
      setSensor(sensorDetails);
      getFloorPlan();
      if (sensorDetails.location)
        fetchLocationFloorplan(sensorDetails.location)
          .then((floorplan) => setFloorPlan(floorplan))
          .catch((err) => setAlertMsg({ success: false, msg: err.cause, alertType: 'error' }));
    } else {
      fetchSensorLatest(sensorId)
        .then((details) => {
          setSensor(details);
          dispatch(setSensorsById([details]));
        })
        .catch((err) => setAlertMsg({ success: false, msg: err.cause, alertType: 'error' }));
    }
  }, [dispatch, getFloorPlan, sensorDetails, sensorId]);

  const handleUpdate = () => {
    setIsEditing(false);
    const item: SensorItem = { ...sensorItem };
    updateSensorProperties(sensorId, item)
      .then((response) => {
        const mergedData = { ...sensor, ...response };
        setAlertMsg({ success: true, msg: 'Update successful', alertType: 'success' });
        setSensor(mergedData);
        dispatch(setSensorsById([mergedData]));
      })
      .catch((err) => setAlertMsg({ success: false, msg: err.cause, alertType: 'error' }));
  };

  const onChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, key: string) => {
    const { value } = e.target;
    const item = { ...sensorItem };
    item[key] = value;
    setSensorItem(item);
  };

  const handleEdit = (editing: boolean) => {
    setSensorItem(undefined);
    setIsEditing(editing);
  };

  const metadata = sensor?.metadata as { [unit: string]: number };

  const showLocationBreadCrumb = (locId: string) => {
    const breadCrumb = getLocationNameTree(locId, allLocations);

    return (
      <Breadcrumbs aria-label="breadcrumb" separator="›" color="textPrimary">
        {breadCrumb.map((item) => (
          <Link
            key={item.id}
            component={RouterLink}
            to={`/location/${encodeURIComponent(item.id)}`}
          >
            <ListItemText primary={item.name} />
          </Link>
        ))}
      </Breadcrumbs>
    );
  };

  const showNameFields = () => {
    const nameItems = ['name', 'shortName'];
    return (
      <Box>
        {sensor &&
          Object.entries(sensor)
            .filter(([sensorProps]) => nameItems.includes(sensorProps))
            .map((sensorProps) => {
              const data = sensorProperties[sensorProps[0] as SensorPropsType];
              return (
                <ListItem>
                  <Grid item sm={5} xs={5}>
                    <ListItemText primary={data?.label} />
                  </Grid>
                  <Grid item sm={7} xs={7}>
                    {isEditing ? (
                      <TextField
                        type="text"
                        defaultValue={
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          (sensor as { [sensorProps: string]: any })[sensorProps[0]] ?? ''
                        }
                        onChange={(e) => onChange(e, sensorProps[0])}
                        style={{ width: '100%' }}
                        variant="standard"
                      />
                    ) : (
                      <ListItemText
                        primary={sensorProps[1] ?? '-'}
                        className={isEditing ? classes.unixTime : ''}
                      />
                    )}
                    <p className={classes.updatedTime}>
                      {metadata &&
                        metadata[sensorProps[0]] &&
                        getShortTimeStr(metadata[sensorProps[0]])}
                    </p>
                  </Grid>
                </ListItem>
              );
            })}
      </Box>
    );
  };

  return (
    <>
      {alertMsg?.msg && (
        <Alert
          severity={alertMsg.alertType ?? 'info'}
          onClose={() => setAlertMsg({ success: true, msg: '', alertType: 'success' })}
          className={classes.alertMsg}
        >
          {alertMsg?.msg}
        </Alert>
      )}
      {sensor ? (
        <List>
          <ListItem>
            <Grid item sm={8} xs={8} style={{ display: 'flex' }}>
              <InfoIcon className={classes.icon} style={{ alignSelf: 'center' }} />
              <Typography variant="h6" style={{ marginLeft: '10px' }}>
                Sensor Details
              </Typography>
            </Grid>
            <Tooltip title="edit">
              <Grid item sm={4} xs={4} style={{ textAlign: 'right' }}>
                <EditBtnIcon
                  isEditing={isEditing}
                  handleEdit={handleEdit}
                  changedItem={sensorItem}
                  handleUpdate={handleUpdate}
                />
              </Grid>
            </Tooltip>
          </ListItem>
          <Divider />
          <ListItem>
            <Grid item sm={5} xs={5}>
              <ListItemText primary="Id" />
            </Grid>
            <Grid item sm={7} xs={7}>
              <Link component={RouterLink} to={`/sensors/${sensorId}`}>
                <ListItemText primary={sensorId} />
              </Link>
            </Grid>
          </ListItem>
          <ListItem>
            <Grid item sm={5} xs={5}>
              <ListItemText primary="Location" />
            </Grid>
            <Grid item sm={7} xs={7}>
              {isEditing ? (
                <TextField
                  type="text"
                  defaultValue={sensor.location}
                  onChange={(e) => onChange(e, 'location')}
                  style={{ width: '100%' }}
                  variant="standard"
                />
              ) : (
                <Box>{showLocationBreadCrumb(sensor?.location ?? '#')}</Box>
              )}
            </Grid>
          </ListItem>
          {showNameFields()}
          {sensor.gateway && (
            <ListItem>
              <Grid item sm={5} xs={5}>
                <ListItemText primary="Gateway" />
              </Grid>
              <Grid item sm={7} xs={7}>
                <Box sx={{ display: 'flex' }}>
                  {!sensorIsGateway && (
                    <RSSILookup RSSI={sensorRSSI} rssiTimeStamp={rssiTimeStamp} />
                  )}
                  <Link
                    component={RouterLink}
                    to={`/sensors/${sensor.gateway}`}
                    style={{ marginLeft: '10px' }}
                  >
                    <ListItemText
                      primary={sensorsById.get(sensor.gateway)?.name ?? sensor.gateway}
                    />
                  </Link>
                </Box>
                {metadata?.gateway ? (
                  <p className={classes.updatedTime}>
                    last updated: {getShortTimeStr(metadata?.gateway)}
                  </p>
                ) : (
                  ''
                )}
              </Grid>
            </ListItem>
          )}
          {sensor && <AdvancedSensordetails sensorDetails={sensor} />}
          {sensor && (
            <SensorBacnetConfig
              sensorDetails={sensor}
              bacnetConfig={bacnetConfig}
              isBacnetEditable={isBacnetEditable}
              bacnetAlertMsg={bacnetAlertMsg}
            />
          )}
          <SensorPosition sensorDetails={sensor} floorPlan={floorPlan} />
        </List>
      ) : (
        ''
      )}
    </>
  );
}

SensorDetails.defaultProps = {
  sensorDetails: undefined,
  bacnetConfig: undefined,
  isBacnetEditable: false,
  bacnetAlertMsg: undefined,
};

export default SensorDetails;
