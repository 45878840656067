import {
  GatewaySensorType,
  LocationDirectChildren,
  SensorLatest,
  VisibleSubSensor,
} from '../../services/api';
import {
  fetchLocationDirectChildren,
  fetchVisibleItems,
  fetchSensorLatest,
} from '../../services/apiService';
import { isDataExpired } from '../../utils/functions';

export interface GatewayDataType {
  gatewayId: string;
  RSSI: number;
}

export interface GatewayVisibleSubsensors {
  gatewayId: string;
  visibleSubsensors: VisibleSubSensor[];
}

export const getAllGatewayVisibleSubsensors = async (
  locationId: string,
  directChildren?: LocationDirectChildren
): Promise<GatewayVisibleSubsensors[]> => {
  let sensors;
  if (directChildren) sensors = directChildren.sensors;
  else {
    const childProps = await fetchLocationDirectChildren(locationId).catch(() => ({
      sensors: [],
      locations: [],
    }));
    sensors = childProps.sensors;
  }
  const allGateways =
    sensors?.filter((sensor: SensorLatest) =>
      Object.values(GatewaySensorType).includes(sensor?.type as GatewaySensorType)
    ) ?? [];
  const visibleSubSensors: GatewayVisibleSubsensors[] = [];
  await Promise.all(
    allGateways.map(async (gateway) => {
      // return undefined for error when fetching visible items
      const visibleItems = await fetchVisibleItems(gateway.id).catch(() => undefined);
      const subSensors = visibleItems?.subsensors;
      if (subSensors) {
        const item = { gatewayId: gateway.id, visibleSubsensors: subSensors };
        visibleSubSensors.push(item);
      }
    })
  );
  return visibleSubSensors;
};

export const getAllowedGateways = async (
  allGatewayVisibleSubSensors: GatewayVisibleSubsensors[],
  newSensorId: string
): Promise<GatewayDataType[]> => {
  const filterGatewayData = await Promise.all(
    allGatewayVisibleSubSensors.map((gateway) => {
      const subSensors = gateway.visibleSubsensors;
      const sensorData = subSensors?.find(
        (subsensor: VisibleSubSensor) =>
          subsensor.suggested_id === newSensorId && !isDataExpired(subsensor.last_seen)
      );
      const data: GatewayDataType[] = [];
      if (sensorData) {
        const item: GatewayDataType = { gatewayId: gateway.gatewayId, RSSI: sensorData.rssi };
        data.push(item);
      }
      return data;
    })
  );
  // combine and sort filtered gateway based on signal strength into one array
  return filterGatewayData
    ?.reduce((prev, current) => prev.concat(current))
    .sort((a, b) => b.RSSI - a.RSSI);
};

export const filterHighStrengthGateway = async (
  allGatewayVisibleSubSensors: GatewayVisibleSubsensors[],
  newSensorId: string
): Promise<SensorLatest | undefined> => {
  let highStrengthGatewayDetail: SensorLatest | undefined;
  if (allGatewayVisibleSubSensors.length > 0) {
    // filter gateways that has sensor in visible list
    const filteredGatewayData = await getAllowedGateways(allGatewayVisibleSubSensors, newSensorId);

    if (filteredGatewayData && filteredGatewayData.length > 0) {
      highStrengthGatewayDetail = await fetchSensorLatest(filteredGatewayData[0].gatewayId).catch(
        () => undefined
      );
    } else {
      highStrengthGatewayDetail = { id: '' };
    }
  } else {
    highStrengthGatewayDetail = { id: '' };
  }
  return highStrengthGatewayDetail;
};

export const filterNewSensorsInLocation = async (
  locationId: string,
  gatewayVisibleSubSensors?: GatewayVisibleSubsensors[],
  directChildren?: LocationDirectChildren
): Promise<VisibleSubSensor[] | undefined> => {
  let flattenAllSubSensors;
  let configuredSensors;

  // combine all the visible subsensors from possible gateways into one array
  if (directChildren) {
    configuredSensors = directChildren;
  } else {
    configuredSensors = await fetchLocationDirectChildren(locationId).catch(() => ({
      sensors: [],
      locations: [],
    }));
  }
  if (gatewayVisibleSubSensors) {
    flattenAllSubSensors = gatewayVisibleSubSensors
      .reduce((prev, current) => prev.concat(current.visibleSubsensors), [] as VisibleSubSensor[])
      .filter((sensor) => !isDataExpired(sensor.last_seen));
  } else {
    const allGateways =
      configuredSensors.sensors?.filter((sensor: SensorLatest) =>
        Object.values(GatewaySensorType).includes(sensor?.type as GatewaySensorType)
      ) ?? [];

    const filteredSubSensors = await Promise.all(
      allGateways.map((gateway) =>
        fetchVisibleItems(gateway.id).then((visibleItems) => {
          const subSensors = visibleItems.subsensors.filter(
            (sensor) => !isDataExpired(sensor.last_seen)
          );
          const allVisibleSubSensors = [];
          if (subSensors && subSensors.length > 0) {
            allVisibleSubSensors.push(subSensors);
          }
          return allVisibleSubSensors;
        })
      )
    );
    const allSubSensors = filteredSubSensors?.reduce((prev, current) => prev.concat(current), []);
    flattenAllSubSensors = Array.prototype.concat.apply([], allSubSensors);
  }

  // sort sensors based on signal strength,
  flattenAllSubSensors?.sort((a, b) => {
    const va = a.rssi;
    const vb = b.rssi;
    if (va > vb) return -1;
    if (va < vb) return 1;
    return 0;
  });

  // some sensors might be visible to multiple gateways
  const filteredDuplicateAddress: VisibleSubSensor[] = Object.values(
    flattenAllSubSensors.reduce((acc, cur) => Object.assign(acc, { [cur.address]: cur }), {})
  );

  // create an array of sensorId
  const configuredSensorFilter =
    configuredSensors && configuredSensors?.sensors?.map((sensor: SensorLatest) => sensor.id);

  // check if sensorId exists in direct children sensors list from visible items list, if not they are new unconfigured sensors
  const unconfiguredSensors =
    filteredDuplicateAddress &&
    configuredSensorFilter &&
    filteredDuplicateAddress.filter(
      (sensor) => !configuredSensorFilter.includes(sensor?.suggested_id)
    );

  return unconfiguredSensors;
};
