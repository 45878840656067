import React from 'react';
import { HoverLabel } from 'plotly.js';
import { useTheme } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import Plot from './PlotlyCustom';
import { isDataExpired } from '../../utils/functions';
import {
  getActiveMarker,
  getAvailableLocations,
  getCurrentLocation,
  getSensorsById,
  getThemeMode,
} from '../../state/selectors';
import { LocationTreeNode, ThemeMode } from '../../state/types';
import { DataTreeItem, VarName } from '../../services/api';
import { setActiveMarker, setCurrentLocation, setHighlightedItem } from '../../state/actions';
import { themeProps } from '../../styles/theme';
import { dataColours } from '../../utils/dataBandParams';
import { varNameDetails } from '../../utils/varNames';
import { getLocationName } from '../../utils/locations';

interface SunburstProps {
  ids: string[];
  values: number[]; // Individual values
  labels: string[]; // Labels for the values
  parents: string[]; // Parent id for each id
  total: number; // Sum of all the data
  varName: VarName;
  locName: string;
  locMarkerId: string | undefined;
}

function SunburstOccupancyPlot({
  ids,
  labels,
  parents,
  values,
  total,
  varName,
  locName,
  locMarkerId,
}: SunburstProps): JSX.Element {
  const theme = useTheme();
  const trimmedTotal = `${total.toFixed(2).replace(/[.,](00)|0$/, '')}`;
  const activeMarker = useSelector(getActiveMarker);
  const themeMode = useSelector(getThemeMode);
  const metricText = varNameDetails[varName].metric ?? '';

  let lineColour = theme.palette.text.secondary;
  // HACK - Manually set the line colour to match the highlighted background
  if (varName === activeMarker) {
    if (themeMode === ThemeMode.dark) {
      lineColour = themeProps.colors.summaryLineDark;
    } else {
      lineColour = themeProps.colors.summaryLineLight;
    }
  }

  const layout = {
    width: locMarkerId ? 60 : 200,
    height: locMarkerId ? 60 : 200,
    margin: {
      l: locMarkerId ? 0 : 10,
      r: locMarkerId ? 0 : 10,
      b: locMarkerId ? 0 : 10,
      t: locMarkerId ? 0 : 10,
    },
    font: { family: themeProps.fontFamily.body },
    annotations: [
      {
        font: {
          size: locMarkerId ? 14 : 18,
          color: theme.palette.text.primary,
        },
        showarrow: false,
        text: trimmedTotal,
        x: 0.5,
        y: 0.5,
        hovertext: locMarkerId
          ? ''
          : `${locName || 'All'} <br />${trimmedTotal} ${varNameDetails[varName].metric ?? ''}`, // click event navigates to root location
        hoverlabel: { align: 'right' } as HoverLabel, // align right to avoid blocking the annotation text
      },
      {
        font: {
          size: 12,
          color: theme.palette.text.primary,
        },
        showarrow: false,
        text: locMarkerId ? '' : `${metricText}`,
        x: 0.5,
        y: 0.4,
      },
    ],

    showlegend: false,
    plot_bgcolor: `${theme.palette.primary.main}00`, // transparent
    paper_bgcolor: `${theme.palette.primary.main}00`,
    sunburstcolorway: [dataColours.blue, dataColours.green],
  };

  const data: Plotly.Data[] = [
    {
      type: 'sunburst',
      ids,
      values,
      labels,
      parents,
      text: locMarkerId
        ? ''
        : values.flatMap((item, i) => `${labels[i]} <br />${item} ${metricText}`),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      maxdepth: 3,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      leaf: { opacity: 0.8 },
      marker: { line: { width: 2, color: lineColour } },
      branchvalues: 'total',
      textinfo: 'none',
      hoverinfo: `text`,
    },
  ];

  // Handle changing location via clicking on plot
  const dispatch = useDispatch();
  const currentLocation = useSelector(getCurrentLocation);
  const parentLocation = currentLocation.split('#').slice(0, -1).join('#') || '#';
  const handleClick = (event: Plotly.PlotMouseEvent) => {
    if (locMarkerId) dispatch(setCurrentLocation(locMarkerId));
    else {
      dispatch(setActiveMarker(varName));
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { nextLevel } = event;
      if (nextLevel && nextLevel.startsWith('#')) {
        if (nextLevel !== currentLocation) {
          dispatch(setCurrentLocation(nextLevel));
        }
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const isAll = event.points[0].currentPath === '/';
        if (isAll && parentLocation !== currentLocation) {
          dispatch(setCurrentLocation(parentLocation));
        }
      }
    }
  };

  const handleHover = (event: Plotly.PlotMouseEvent) => {
    const { points } = event;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const hoveredId = points[0]?.id ?? '';
    if (hoveredId && !hoveredId.startsWith('#'))
      dispatch(setHighlightedItem({ id: hoveredId, varName }));
  };

  const handleMouseLeave = (event: Plotly.PlotMouseEvent) => {
    const { points } = event;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const hoveredId = points[0]?.id ?? '';
    if (hoveredId && !hoveredId.startsWith('#')) {
      dispatch(setHighlightedItem({ id: '' }));
    }
  };

  return (
    <Plot
      data={data}
      layout={layout}
      useResizeHandler
      config={{ displayModeBar: false, responsive: true }}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onSunburstClick={handleClick}
      onClickAnnotation={() => dispatch(setCurrentLocation(parentLocation))}
      onHover={handleHover}
      onUnhover={handleMouseLeave}
    />
  );
}

interface SunburstContructionItem {
  id: string;
  name: string;
  value: number;
  parent: string;
}

function generateParentItems(
  sensorValue: number,
  sensorParent: string,
  availableLocatons: Map<string, LocationTreeNode>,
  currentLocation: string
) {
  const results = [] as SunburstContructionItem[];

  // Walk the tree and add to parent nodes
  const path = sensorParent?.slice(1).split('#');
  // A map containing location nodes of children to a location
  let nodeInPath: Map<string, LocationTreeNode> | null | undefined = availableLocatons;
  let parent = ''; // id of the parent for the location
  let name = 'All'; // Name of the location
  for (let i = 0; i <= path.length; ++i) {
    const positionInPath = path[i] ?? '#';

    let id = '';
    for (let j = 0; j < i; j++) {
      id += `#${path[j]}`;
    }
    if (id === '') {
      id = '#'; // Make sure there is a fallback path
    }

    // Don't need a root location item
    if (id !== '#' && id !== currentLocation) {
      results.push({
        id,
        name,
        value: sensorValue,
        parent,
      });
    }

    // Set the details for the next location in the path
    parent = id === '#' || id === currentLocation ? 'All' : id;
    name = nodeInPath?.get(positionInPath)?.raw.name ?? 'UNKNOWN';
    nodeInPath = nodeInPath?.get(positionInPath)?.children;
  }
  // Return only the items from the currentLocation up
  return results.filter((item) => !currentLocation.includes(`${item.id}#`));
}

// Same interface as other Widget Wrappers
interface WidgetProps {
  data: DataTreeItem[];
  varName: VarName;
  locMarkerId?: string; // to generate small sized summary ring plot for child locations when called from map
}

function SensorSunburst({ data, varName, locMarkerId }: WidgetProps): React.ReactElement {
  const sensorsById = useSelector(getSensorsById);

  const availableLocatons = useSelector(getAvailableLocations);
  const currentLocation = useSelector(getCurrentLocation);

  // Create object for each sensor with required parameters
  const sensors = [] as SunburstContructionItem[];
  let total = 0;
  data.forEach((item) => {
    // Don't include old data
    if (isDataExpired(item.time)) return;
    if (item.value === 0) return;
    // Get properties
    sensors.push({
      id: item.id,
      name: sensorsById.get(item.id)?.name ?? item.id,
      value: item.value ?? 0,
      parent: item.location ?? 'UNKNOWN',
    });
    total += item.value ?? 0;
  });

  // For each sensor add it's value to it's parent and
  // for each subsequent parent until '#' (or no parent)
  // Create array of ids, labels, values (including location sum), parents (including location)
  const locationDict = new Map() as Map<string, SunburstContructionItem>;
  let idsOut = [] as string[];
  let labelsOut = [] as string[];
  let parentsOut = [] as string[];
  let valuesOut = [] as number[];
  sensors.forEach((sensor) => {
    // addToParent(sensor.value, sensor.parent, locationDict, locations);
    const parentItems = generateParentItems(
      sensor.value,
      sensor.parent,
      availableLocatons,
      locMarkerId || currentLocation
    );
    parentItems.forEach((item) => {
      const sum = item.value + (locationDict?.get(item.id)?.value ?? 0);
      locationDict.set(item.id, { ...item, value: sum });
    });
    idsOut.push(sensor.id);
    labelsOut.push(sensor.name);
    parentsOut.push(sensor.parent === currentLocation ? 'All' : sensor.parent);
    valuesOut.push(sensor.value);
  });
  locationDict.forEach((location) => {
    idsOut.push(location.id);
    labelsOut.push(location.name);
    parentsOut.push(location.parent);
    valuesOut.push(location.value);
  });

  // FIXME - Lazy hack to show something if there is no occupancy
  if (idsOut.length === 0) {
    idsOut = ['#', '#None'];
    labelsOut = ['Unoccupied', 'Unoccupied'];
    valuesOut = [1, 1];
    parentsOut = ['', '#'];
  }

  return (
    <SunburstOccupancyPlot
      ids={idsOut}
      labels={labelsOut}
      values={valuesOut}
      parents={parentsOut}
      total={total}
      varName={varName}
      locName={getLocationName(availableLocatons, currentLocation)}
      locMarkerId={locMarkerId}
    />
  );
}

SensorSunburst.defaultProps = {
  locMarkerId: undefined,
};

export default SensorSunburst;
