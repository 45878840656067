import { makeStyles } from '@mui/styles';
import { lighten } from '@mui/material/styles';

import { Theme } from '@mui/material';
import { themeProps } from './theme';

const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '7px',
      height: '7px',
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: '#80808094',
    },
  },
  header: {
    background: theme.palette.primary.main,
    flex: '0 0 auto',
    [theme.breakpoints.down('lg')]: {
      height: 'auto',
      position: 'fixed',
      top: '0',
      left: '0',
      right: '0',
    },
  },
  shell: {
    display: 'flex',
    minHeight: '100vh',
    height: '100vh',
    width: '100%',
    flexWrap: 'nowrap',
    alignContent: 'stretch',
    paddingLeft: '57px',
    [theme.breakpoints.down('lg')]: {
      height: 'auto',
      paddingLeft: '0',
    },
    [theme.breakpoints.down('sm')]: {
      overflow: 'hidden',
    },
  },
  shellNoPadding: {
    paddingTop: '0 !important',
  },
  mainContent: {
    flexGrow: 1,
    width: '100%',
  },
  bodyContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    flexWrap: 'nowrap',
    alignContent: 'stretch',
    padding: '10px',
    [theme.breakpoints.down('lg')]: {
      display: 'block',
      height: 'auto',
    },
  },
  navLogo: {
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },

  gridWithOverflow: {
    height: '100%',
    [theme.breakpoints.down('lg')]: {
      height: 'auto',
    },
  },
  amplifyContainer: {
    paddingTop: '2rem',
    display: 'flex',
    width: 'auto',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'center',
    margin: 'auto',
    [theme.breakpoints.down('lg')]: {
      paddingTop: '6rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '4rem',
    },
  },
  amplifyErrorMessage: {
    padding: '0.25rem 0.5rem',
    marginBottom: '-40px',
    marginTop: '10px',
    background: 'red',
    color: 'white',
    borderRadius: '8px',
  },

  sourceBtn: {
    padding: '0 5px',
    textTransform: 'capitalize',
    marginRight: '5px',
  },
  sourcesMenu: {
    flex: '0 0 auto',
  },
  mapContainer: {
    color: theme.palette.text.secondary,
    height: '100%',
    position: 'relative',
    minHeight: '300px',
    background: theme.palette.primary.main,
    '& button': {
      fontSize: themeProps.textSize.default,
      textTransform: 'capitalize',
      minWidth: '0',
      padding: '5px',
    },
    [theme.breakpoints.down('lg')]: {
      minHeight: '50vh',
    },
  },
  widget: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '100%',
    minHeight: '250px',
  },
  widgetSingle: {},
  widgetSummary: {},
  widgetSummaryElement: {
    textAlign: 'center',
    margin: '0',
    padding: '0',
    flex: '1 1 auto',
  },
  widgetSummaryMainElement: {
    textAlign: 'center',
    margin: '0 1rem',
    padding: '0',
    flex: '1 1 auto',
  },
  widgetValueRow: {
    display: 'flex',
    alignItems: 'flex-end',
    flexWrap: 'nowrap',
    width: '100%',
    marginTop: '0.5rem',
  },
  logoutButton: {
    marginLeft: '1rem',
  },
  mapToolbar: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '60px',
    right: '5px',
    zIndex: 1000,
    color: '#fff',
    background: theme.palette.primary.main,
    borderRadius: '8px',
    opacity: '0.8',
  },
  mapToolbarActiveButton: {
    color: '#fb0',
  },

  sourceToolbar: {
    display: 'inline-table',
    flexDirection: 'row',
    top: '5px',
    left: '5px',
    margin: 'auto',
  },

  tooltipActiveReading: {
    color: '#fb0',
  },
  sensorToolbarActiveButton: {
    color: theme.palette.primary.main,
    background: theme.palette.secondary.main,
    '&:hover': {
      background: `${theme.palette.secondary.main} !important`,
    },
  },
  dashboard: {
    display: 'flex',
    height: '100%',
    flexDirection: 'row',
    alignItems: 'stretch',
    marginTop: '10px',
    [theme.breakpoints.down('lg')]: {
      display: 'block',
      height: 'auto',
    },
  },
  dashboardTile: {
    flex: '1 1 auto',
    height: '100%',
  },
  dashboardTileContainer: {
    flex: '60%',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '60px',
    [theme.breakpoints.down('lg')]: {
      height: 'auto',
      overflowY: 'visible',
      paddingBottom: '0',
    },
  },
  dashboardTileScrollable: {
    overflowY: 'scroll',
    overflowX: 'auto',
    [theme.breakpoints.down('lg')]: {
      height: 'auto',
      overflowY: 'visible',
    },
  },

  locationSensorArrayList: {
    listStyle: 'none',
    margin: '0',
    padding: '0 0 0 1rem',
    [theme.breakpoints.down('lg')]: {
      padding: '1rem 0 0 0',
    },
  },
  locationSensorArray: {
    border: `1px solid ${theme.palette.primary.contrastText}`,
    borderRadius: '8px',
    marginBottom: '1rem',
    boxShadow: '0px 5px 10px 0px rgba(0,0,0,0.20)',
  },
  compareRightContainer: {
    background: theme.palette.primary.main,
    flex: '1 1 auto',
    padding: '10px',
    borderRadius: '8px',
  },
  compareIcons: {
    fontSize: `${themeProps.textSize.h6} !important`,
  },
  compareListPanel: {
    borderRadius: '8px',
    marginBottom: '1rem',
    boxShadow: '0px 5px 10px 0px rgba(0,0,0,0.20)',
    background: theme.palette.primary.main,
  },
  compareListPanelHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0.5rem',
  },
  compareList: {
    background: theme.palette.primary.main,
    width: '100%',
    padding: '10px',
  },
  compareListItem: {
    padding: '8px 10px',
    margin: '5px 0',
    transition: 'all 0.3s',
    background: theme.palette.primary.light,
    borderRadius: '8px',
    // '&:nth-child(even)': {
    //   background: darken(theme.palette.primary.main, 0.2),
    // },
    '&:hover': {
      background: `${themeProps.colors.alertYellow}84 !important`,
      color: '#000',
    },
  },
  mapTooltip: {
    padding: '0.5rem',
    color: '#fff',
    background: 'rgba(0,0,0,0.75)',
    borderRadius: '8px',
    opacity: '0.9',
  },
  mapToolTipSensorData: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    '& > li': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginBottom: '0.25rem',
      '& > *': {
        marginRight: '0.5rem',
      },
    },
  },
  dateRangePicker: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > input': {
      fontSize: 'inherit',
      fontFamily: 'inherit',
    },
    [theme.breakpoints.down('lg')]: {
      width: '50%',
    },
  },
  sensorArraySensorList: {
    width: '100%',
    borderTop: '1px solid #aaa',
    margin: '0',
    padding: '0',
    listStyle: 'none',
    background: theme.palette.primary.main,
  },
  sensorArraySensorListItem: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    minHeight: '3rem',
    padding: '0.5rem',
    transition: 'all 0.3s',
    '& .sensorArraySensorListItemButtons': {
      visibility: 'hidden',
      marginLeft: '1rem',
    },
    '&:nth-child(even)': {
      background: theme.palette.primary.light,
    },
    '&:hover': {
      background: `${themeProps.colors.alertYellow}84 !important`,
      color: '#000',
      '& .sensorArraySensorListItemButtons': {
        visibility: 'visible',
        color: theme.palette.text.primary,
        background: 'none',
      },
    },
    '& > span': {
      marginRight: '1rem',
    },
    '& .sensorArraySensorListItemButtonsActive': {
      visibility: 'visible',
    },
    [theme.breakpoints.down('lg')]: {
      marginLeft: '5px',
      '& .sensorArraySensorListItemButtons': {
        visibility: 'visible',
        marginLeft: '0',
        minWidth: '0',
      },
    },
  },
  highlightedItem: {
    background: `${themeProps.colors.alertYellow}84 !important`,
    color: '#000 !important',
  },
  sensorArraySensorSummaryContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: '0 0 auto',
    width: '20ch',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  sensorArraySensorSummarySpacer: {
    flex: '1 0',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  sensorArraySensorSummary: {
    minHeight: '48px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0 0.5rem 0 0',
    borderRadius: '8px',
    background: theme.palette.primary.main,
    '& > *': {
      marginRight: '0.5rem',
    },
  },
  sensorArraySensorSummaryOptionalRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0.5rem 0.5rem 0.5rem calc(5px + 1rem)',

    '& > *': {
      marginRight: '0.5rem',
    },
  },
  sensorArraySensorSummaryLabel: {
    marginRight: '1rem',
    display: 'block',
  },
  sensorArraySensorSummaryText: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  sensorArraySensorSummaryMarkerButton: {
    cursor: 'pointer',
    borderLeft: '5px solid',
    borderColor: 'transparent',
    paddingLeft: '0.5rem',
    transition: 'all 0.3s',
    '&:hover': {
      borderColor: '#fb0',
      borderLeftWidth: '10px',
      paddingLeft: 'calc(0.5rem - 5px)',
    },
  },
  sensorArraySensorSummaryMarkerButtonActive: {
    borderColor: '#fb0',
  },
  sensorArraySensorSummaryExtended: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0.5rem',
    '& > *': {
      marginRight: '0.5rem',
    },
  },
  pillLabel: {
    color: 'white',
    padding: '3px 8px',
    borderRadius: '1rem',
    fontWeight: 'bold',
    display: 'inline-block',
    border: '2px solid black',
  },
  pillUnitLabel: {
    fontWeight: 'normal',
  },
  interactivePill: {
    cursor: 'pointer',
    userSelect: 'none',
    border: '2px solid black',
    margin: '5px',
    transition: 'all 0.3s',
    '&:hover': {
      boxShadow: '0px 5px 10px 0px rgba(0,0,0,0.20)',
      border: '2px solid #333 !important',
    },
    outline: 'none',
  },
  gradientBar: {
    width: '100%',
    textAlign: 'center',
  },
  sensorGraphContainer: {
    position: 'relative',
    width: '100%',
    margin: '0',
    padding: '0 0 0 1rem',
    [theme.breakpoints.down('lg')]: {
      padding: '1rem 0 0 0',
    },
  },
  sensorGraph: {
    border: `1px solid ${theme.palette.primary.contrastText}`,
    borderRadius: '8px',
    boxShadow: '0px 5px 10px 0px rgba(0,0,0,0.20)',
    width: '100%',
    height: '200px !important',
    position: 'relative',
    background: theme.palette.primary.light,
  },
  helpLinkActive: {
    background: '#fb0',
    color: '#333',
    animation: '$helpButtonAnimation 2s infinite',
  },
  '@keyframes helpButtonAnimation': {
    '0%': {
      background: '#fb0',
    },
    '50%': {
      background: '#fff',
    },
    '100%': {
      background: '#fb0',
    },
  },
  marginLeftNoSm: {
    marginLeft: '1rem',
    [theme.breakpoints.down('lg')]: {
      marginLeft: '0',
    },
  },
  offlineIndicator: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    '& > *': {
      marginRight: '0.5rem',
    },
    color: '#fff',
    background: '#f00',
    padding: '6px 8px',
    fontSize: themeProps.textSize.default,
    borderTop: '1px solid #fff',
    animation: '$offlineAnimation 3s infinite',
    [theme.breakpoints.down('lg')]: {
      position: 'fixed',
      top: '0',
      right: '0',
      width: 'auto',
      borderTop: 'none',
      borderLeft: '1px solid #fff',
      height: '64px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '56px',
    },
  },
  '@keyframes offlineAnimation': {
    '0%': {
      background: '#f00',
    },
    '50%': {
      background: '#a82020',
    },
    '100%': {
      background: '#f00',
    },
  },
  weatherDisplay: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    height: '100%',
    color: '#fff',
    background: 'rgba(0,0,0,0.75)',
    borderRadius: '2rem',
    opacity: '0.8',
    padding: '0.5rem 1rem',
    '& > *': {
      marginRight: '5px',
    },
    transition: 'opacity 0.3s',
  },
  fadeOut: {
    opacity: '0.2 !important',
  },
  sensorGrid: {
    padding: '10px 0 20px 10px',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  sensorDataGrid: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.down('lg')]: {
      display: 'block',
    },
  },
  sensorHeader: {
    marginTop: '20px',
    marginLeft: '10px',
  },
  unixTime: {
    color: themeProps.colors.lightGrey,
  },
  closeBtnIcon: {
    color: theme.palette.error.main,
  },
  cancelBtn: {
    color: 'white',
    backgroundColor: '#ab2e2e',
    '&:hover': {
      backgroundColor: '#ab2e2e',
    },
  },
  expansionSummary: {
    padding: '0 16px 0 16px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 5px',
    },
  },
  expansionGridContainer: {
    marginLeft: '60px !important',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0 !important',
    },
  },
  subSensorSummary: {
    display: 'grid',
    gridTemplateColumns: '75px 2fr 2fr 2fr 1fr',
    width: '100%',
    '& > *': {
      alignSelf: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '46px 3fr 1fr 36px',
    },
  },
  subSensorGridHeader: {
    height: '48px',
    display: 'grid',
    gridTemplateColumns: '75px 2fr 2fr 2fr 1fr 36px',
    padding: '0 16px 0 16px !important',
    '& > *': {
      paddingLeft: '0 !important',
    },
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      gridTemplateColumns: '46px 3fr 2fr 36px',
    },
  },
  hideInSmallScreen: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  hideInMediumScreen: {
    display: 'block',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  hideInBigScreen: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  expandBtn: {
    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },
  },
  enabledIcon: {
    color: 'white',
    backgroundColor: '#f50357',
    borderRadius: '50%',
  },
  progressIcon: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
  },
  listeningGreyIcon: {
    color: 'white',
    backgroundColor: 'grey',
    borderRadius: '50%',
  },
  icon: {
    color: theme.palette.text.primary,
    fontSize: themeProps.textSize.h4,
  },
  sortIcon: {
    marginRight: '5px',
  },
  disabledIcon: {
    color: theme.palette.info.main,
    fontSize: themeProps.textSize.h4,
  },
  primaryBtn: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  updatedTime: {
    color: lighten(themeProps.colors.lightGrey, 0.3),
    marginTop: 0,
    marginBottom: 0,
  },
  provisionContainer: {
    [theme.breakpoints.down('lg')]: {
      height: 'auto',
    },
  },
  provisioningForm: {
    padding: '1rem',
    overflow: 'scroll',
    minWidth: '50%',
    '& > *': {
      marginBottom: '1rem',
    },
  },
  initialProvisionStepper: {
    maxWidth: '800px',
    margin: 'auto',
    marginTop: '1rem',
  },
  provisionFormContainer: {
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column !important',
      height: 'auto',
    },
  },
  provisioningFormRow: {
    marginBottom: '1rem',
    '& > *': {
      marginRight: '1rem !important',
    },
  },
  provisioningFormStepper: {
    padding: '10px 10px 10px 0 !important',
    [theme.breakpoints.down('lg')]: {
      marginBottom: '0',
    },
  },
  provisionFormAutoField: {
    [theme.breakpoints.down('lg')]: {
      marginBottom: '0',
      '& > *': {
        width: 'calc(50% - 16px)',
        marginBottom: '1rem',
      },
    },
  },
  AccessPointsRSSI: {
    display: 'flex',
    '& > *': {
      paddingRight: theme.spacing(1),
    },
  },
  alertMsg: {
    marginTop: '10px',
    marginBottom: '10px',
    width: '100%',
  },
  reportButton: {
    margin: '1rem 0',
    fontSize: themeProps.textSize.small,
  },
  reportToolbar: {
    alignItems: 'center',
    justifyContent: 'end',
    '& > *': {
      marginLeft: '1rem !important',
      [theme.breakpoints.down('sm')]: {
        margin: '0.5rem !important',
      },
    },
  },
  reportContainer: {
    paddingBottom: '40px',
  },
  shadowSummary: {
    display: 'grid',
    gridTemplateColumns: '75px 2fr 2fr 1fr',
    width: '100%',
    '& > *': {
      alignSelf: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 5px',
      gridTemplateColumns: '46px 3fr 2fr 36px',
    },
  },
  RSSI: {
    display: 'flex',
    '& > *': {
      paddingRight: theme.spacing(1),
    },
  },
  shadowGridHeader: {
    height: '48px',
    display: 'grid',
    gridTemplateColumns: '75px 2fr 2fr 1fr 75px',
    padding: '0 16px 0 16px',
    '& > *': {
      paddingLeft: 0,
      paddingRight: 0,

      '& > *': {
        width: '100%',
      },
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 5px',
      gridTemplateColumns: '46px 2fr 2fr 1fr 36px',
    },
  },
  apContainer: {
    marginTop: '10px',
    width: '100%',
    alignItems: 'center',
  },
  apSubListContainer: {
    [theme.breakpoints.down('sm')]: {
      padding: '0 5px',
    },
  },
  apSubListChannel: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'right',
    },
  },
  ssidIconBtn: {
    width: '16px',
    height: '16px',
  },
  breadCrumbMenu: {
    color: theme.palette.text.primary,
    background: theme.palette.primary.main,
    borderRadius: '8px',
    '& ol': {
      flexWrap: 'nowrap',
      overflowX: 'scroll',
    },
  },
  breadCrumbMenuBtn: {
    padding: '8px',
    textTransform: 'capitalize',
    border: 'none !important',
    background: 'none !important',
    color: `${theme.palette.text.primary} !important`,
    '&:hover': {
      background: `${theme.palette.secondary.main} !important`,
      color: `${theme.palette.text.secondary} !important`,
    },
  },
  menuLabel: {
    fontSize: themeProps.textSize.default,
    maxWidth: '150px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '100px',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '50px',
    },
  },
  menuExpandIcon: {
    [theme.breakpoints.down('lg')]: {
      display: 'block',
    },
  },
  filterControls: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      justifyContent: 'space-between',
    },
  },
  baseSummaryLocationBtn: {
    color: theme.palette.text.primary,
    cursor: 'pointer',
    textTransform: 'capitalize',
  },
  qrReaderContainer: {
    width: '600px',
    [theme.breakpoints.down('sm')]: {
      width: '350px',
    },
  },
  createSensorAccordion: {
    margin: '0 10px 0 0 !important',
    [theme.breakpoints.down('sm')]: {
      margin: '0 0 10px 0 !important',
    },
  },
  customBadge: {
    color: theme.palette.text.primary,
  },
  floatingBtn: {
    display: 'none',
    position: 'fixed',
    bottom: '10px',
    right: '10px',
    zIndex: 1,
    lineHeight: 0,
    [theme.breakpoints.down('lg')]: {
      display: 'block',
    },
  },
  compareFloatingBtn: {
    zIndex: 10000,
    display: 'block',
    position: 'sticky',
    marginRight: '10px',
    left: '700px',
    [theme.breakpoints.down('lg')]: {
      position: 'fixed',
      marginRight: 0,
      left: 'unset',
    },
  },
  ellipsisLocName: {
    maxWidth: '70px',
    overflow: 'hidden',
    display: 'inline-block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  plotAlertText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '0.5rem 2rem',
    width: 'auto',
    textAlign: 'center',
    color: '#fff',
    background: 'rgba(0,0,0,0.75)',
    borderRadius: '2rem',
  },

  searchBox: {
    marginRight: '1rem',
    borderRadius: '8px',
    padding: '0.25rem 0.5rem 0 0.5rem',
    transition: 'background 0.3s',
  },

  marker: {
    background: `${themeProps.colors.defaultColor}dd`,
    width: '48px',
    height: '48px',
    color: '#fff',
    borderRadius: '50%',
    textAlign: 'center',
    lineHeight: '40px',
    display: 'flex',
  },

  locationMarker: {
    borderRadius: '8px',
    textAlign: 'center',
    padding: '5px',
    '&:hover': {
      background: `${theme.palette.primary.main}dd`,
    },
  },

  markerContent: {
    alignSelf: 'center',
    textAlign: 'center',
    width: '100%',
    color: 'white',
  },

  highLightedMarker: {
    background: `${themeProps.colors.defaultColor}bd`,
    color: '#fff',
    padding: '10px',
    borderRadius: '50%',
    height: '125px',
    width: '125px',
    display: 'flex',
  },
  chipPills: {
    marginRight: '5px',
    border: 'none',
    fontSize: themeProps.textSize.small,
    padding: '4px 10px 4px 8px',
  },
  sensorDataPickerChip: {
    border: `solid 1px ${theme.palette.primary.contrastText}`,
    cursor: 'pointer',
    marginTop: '5px',
    marginBottom: '5px',
    [theme.breakpoints.down('sm')]: {
      paddingRight: '15px',
      '& .MuiChip-label': {
        display: 'none',
      },
    },
  },
  ghostOffsetBtn: {
    padding: '5px 10px',
    textTransform: 'capitalize',
    fontSize: themeProps.textSize.small,
  },
}));

export default useStyles;
