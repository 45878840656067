import { persistState, retrieveState, StorageTypes } from '../../utils/persistentState';
import { VarName } from '../../utils/varNames';
import { ActionTypes, ActionType } from '../actionTypes';
import { SortType, HoursSelection, UISettingsState, ThemeMode } from '../types';

let initialDashboardPanels: VarName[] = [];
let initialActivePlotVars: VarName[] = [];
let initialActiveMarker: VarName = VarName.TemperatureC;
let initialShowHelp = false;
let initialSortBy: SortType = { property: 'name', ascending: false };
let initialSelectedHours: HoursSelection = {
  selectHours: true,
  startHour: 9,
  endHour: 17,
  includeWeekends: false,
};
let initialMotionThreshold: [number, number] = [3, 9];
let initialThemeMode: ThemeMode = ThemeMode.dark;
const initialGhostParam = { addGhosts: false, weekCount: 1 };

try {
  initialDashboardPanels = (retrieveState(StorageTypes.DashboardPanels) as VarName[]) ?? [];
  initialActivePlotVars = (retrieveState(StorageTypes.PlotVars) as VarName[]) ?? [];
  initialActiveMarker =
    (retrieveState(StorageTypes.ActiveMarker) as VarName) ?? VarName.TemperatureC;
  initialShowHelp = retrieveState(StorageTypes.ShowingHelp) === 'true';
  initialSortBy = (retrieveState(StorageTypes.SortBy) as SortType) ?? 'name';
  initialSelectedHours = (retrieveState(StorageTypes.SelectedHours) as HoursSelection) ?? {
    selectHours: false,
    startHour: 9,
    endHour: 17,
    includeWeekends: false,
  };
  initialMotionThreshold = (retrieveState(StorageTypes.MotionThreshold) as [number, number]) ?? [
    3, 9,
  ];
  initialThemeMode = (retrieveState(StorageTypes.ThemeType) as ThemeMode) ?? ThemeMode.dark;
} catch (err) {
  // console.warn(`Error attempting to initialise the UI state ${err}`);
}

const initialState: UISettingsState = {
  mapSize: 'normal',
  highlightedItem: {
    id: '',
  },
  activeMarker: initialActiveMarker,
  clickedItem: { id: '' },
  dashboardPanels: initialDashboardPanels,
  activePlotVars: initialActivePlotVars,
  showHelp: initialShowHelp,
  searchTerm: { term: '', source: undefined },
  sortBy: initialSortBy,
  selectedHours: initialSelectedHours,
  motionThreshold: initialMotionThreshold,
  showFloorplanLabels: true,
  showSensorLabels: false,
  allowBleLocSwitch: true,
  bleWebViewUrl: '',
  showNavDrawer: false,
  themeMode: initialThemeMode,
  ghostParams: initialGhostParam,
  showStackedTraces: true, // available mainly for occumancy(Wifi/BLE) and energy traces
  selectedBand: null,
  showAdvancedAnalysis: false,
};

// eslint-disable-next-line default-param-last
export default function setUISettings(state = initialState, action: ActionTypes): UISettingsState {
  switch (action.type) {
    case ActionType.SET_MOTION_THRESHOLD: {
      const motionThreshold = action.payload;
      persistState(motionThreshold, StorageTypes.MotionThreshold);
      return {
        ...state,
        motionThreshold,
      };
    }
    case ActionType.SET_SELECTED_HOURS: {
      const selectedHours = action.payload;

      persistState(selectedHours, StorageTypes.SelectedHours);

      return {
        ...state,
        selectedHours,
      };
    }
    case ActionType.SET_SORT_BY: {
      const sortBy = action.payload;

      persistState(sortBy, StorageTypes.SortBy);

      return {
        ...state,
        sortBy,
      };
    }
    case ActionType.SET_SEARCH_TERM: {
      const {
        payload: { term, source },
      } = action;
      // We only start searching when 3 or more characters are typed
      const searchTerm =
        term.length > 2 ? { term: term.toLowerCase(), source } : { term: '', source: undefined };

      return {
        ...state,
        searchTerm,
      };
    }
    case ActionType.SHOW_HELP: {
      const showHelp = action.payload;

      // In theory we should not be doing that here but it
      // would require more actions/other mechanism vs one line of code here
      persistState(showHelp.toString(), StorageTypes.ShowingHelp);

      return {
        ...state,
        showHelp,
      };
    }
    case ActionType.TOGGLE_ACTIVE_PLOT_VAR: {
      const varName = action.payload;
      const newVars = state.activePlotVars;
      const index = newVars.indexOf(varName);
      if (index === -1) {
        newVars.push(varName);
      } else {
        newVars.splice(index, 1);
      }

      // In theory we should not be doing that here but as we toggle
      // the panels we'd have to get the full set and then store it which
      // would require more actions/other mechanism vs one line of code here
      persistState(newVars, StorageTypes.PlotVars);

      return {
        ...state,
        activePlotVars: [...newVars],
      };
    }
    case ActionType.SET_DASHBOARD_PANEL: {
      const panel = action.payload;
      const newPanels = [panel];

      // In theory we should not be doing that here but as we toggle
      // the panels we'd have to get the full set and then store it which
      // would require more actions/other mechanism vs one line of code here
      persistState(newPanels, StorageTypes.DashboardPanels);

      return {
        ...state,
        dashboardPanels: [...newPanels],
      };
    }
    case ActionType.TOGGLE_DASHBOARD_PANEL: {
      const panel = action.payload;
      const newPanels = state.dashboardPanels;
      const index = newPanels.indexOf(panel);
      if (index === -1) {
        newPanels.push(panel);
      } else {
        newPanels.splice(index, 1);
      }

      // In theory we should not be doing that here but as we toggle
      // the panels we'd have to get the full set and then store it which
      // would require more actions/other mechanism vs one line of code here
      persistState(newPanels, StorageTypes.DashboardPanels);

      return {
        ...state,
        dashboardPanels: [...newPanels],
      };
    }
    case ActionType.SET_CLICKED_ITEM: {
      const clickedItem = action.payload;
      return {
        ...state,
        clickedItem,
      };
    }
    case ActionType.SET_ACTIVE_MARKER: {
      const activeMarker = action.payload;

      return {
        ...state,
        activeMarker,
      };
    }
    case ActionType.SET_HIGHLIGHTED_ITEM: {
      const highlightedItemId = action.payload;
      return {
        ...state,
        highlightedItem: highlightedItemId,
      };
    }
    case ActionType.TOGGLE_SHOW_FLOORPLAN_LABELS: {
      const showFloorplanLabelsCurrent = state.showFloorplanLabels;
      return {
        ...state,
        showFloorplanLabels: !showFloorplanLabelsCurrent,
      };
    }
    case ActionType.TOGGLE_SHOW_SENSOR_LABELS: {
      const showSensorLabelsCurrent = state.showSensorLabels;
      return {
        ...state,
        showSensorLabels: !showSensorLabelsCurrent,
      };
    }
    case ActionType.SET_MAP_SIZE: {
      const mapSize = action.payload;
      return {
        ...state,
        mapSize,
      };
    }
    case ActionType.ALLOW_BLE_LOC_SWITCH: {
      const allowBleLocSwitch = action.payload;
      return {
        ...state,
        allowBleLocSwitch,
      };
    }
    case ActionType.SET_BLE_WEBVIEW_URL: {
      const url = action.payload;
      return {
        ...state,
        bleWebViewUrl: url,
      };
    }
    case ActionType.SET_SHOW_NAV_DRAWER: {
      const showNavDrawer = action.payload;
      return {
        ...state,
        showNavDrawer,
      };
    }
    case ActionType.SET_THEME_MODE: {
      const themeMode = action.payload;
      persistState(themeMode, StorageTypes.ThemeType);
      return {
        ...state,
        themeMode,
      };
    }
    case ActionType.SET_GHOST_PARAMS: {
      const ghostParams = action.payload;
      return {
        ...state,
        ghostParams,
      };
    }
    case ActionType.SET_STACKED_TRACES: {
      const stackedTraces = action.payload;
      return {
        ...state,
        showStackedTraces: stackedTraces,
      };
    }
    case ActionType.SET_SELECTED_BAND: {
      const selectedBand = action.payload;
      return {
        ...state,
        selectedBand,
      };
    }
    case ActionType.TOGGLE_SHOW_ADVANCED_ANALYSIS: {
      const showAdvancedAnalysis = action.payload;
      return {
        ...state,
        showAdvancedAnalysis,
      };
    }
    default: {
      return state;
    }
  }
}
