import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import { themeProps } from './theme';

const useStyles = makeStyles((theme: Theme) => ({
  occupancyPill: {
    border: 'none',
    marginLeft: '5px',
    marginRight: '5px',
  },
  lockedIcon: {
    color: theme.palette.warning.main,
    background: 'white !important',
  },
  timeSelectionContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    [theme.breakpoints.down('md')]: {
      width: '90%',
      margin: 'auto',
    },
  },
  plotContainer: {
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      width: '95vw',
      padding: '5px',
      display: 'block !important',
    },
  },
  toggleBtn: {
    fontSize: themeProps.textSize.small,
    padding: '0 10px',
    textTransform: 'capitalize',
  },
}));

export default useStyles;
