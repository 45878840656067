import { LocationTreeItems, PropLocationItem } from '../../services/api';
import { removeLocation, updateLocation } from '../../utils/locations';
import { ActionTypes, ActionType } from '../actionTypes';
import { LocationState, LocationTreeNode } from '../types';

const initialState: LocationState = {
  locations: new Map(),
  currentLocation: '#',
  currentLocationChildren: [],
  floorplans: new Map(),
  locationsById: new Map(),
  locationsByParent: new Map(),
};

export function processLocations(
  locationsRaw: LocationTreeItems | null
): Map<string, LocationTreeNode> {
  const processedLocations: Map<string, LocationTreeNode> = new Map();

  locationsRaw?.locations?.forEach((node: PropLocationItem) => {
    // This assumes that a top level location will have arrived and
    // have been processed by now
    const path = node.id.slice(1).split('#');

    if (path.length === 1) {
      const [id] = path;
      const nodeInMap = processedLocations.get(id);

      if (!nodeInMap) {
        processedLocations.set(id, {
          id: node.id,
          raw: node,
          children: new Map(),
        });
      }
    } else {
      let parentNode = processedLocations.get(path[0]);
      for (let i = 1; i < path.length - 1; i++) {
        parentNode = parentNode?.children?.get(path[i]);
      }
      const finalNode = path[path.length - 1];
      if (!parentNode?.children?.get(finalNode)) {
        parentNode?.children?.set(finalNode, {
          id: node.id,
          raw: node,
          children: new Map(),
        });
      }
    }
  });
  return processedLocations;
}

export function updateFlatMap(
  locationsById: Map<string, PropLocationItem>,
  locationsByParent: Map<string, string[]>,
  item: PropLocationItem
) {
  locationsById.set(item.id, { ...locationsById.get(item.id), ...item });

  if (item.location !== undefined) {
    const parentItem = locationsByParent.get(item.location) || [];
    if (!parentItem.includes(item.id)) {
      parentItem.push(item.id);
      locationsByParent.set(item.location, parentItem);
    }
  }
}

// eslint-disable-next-line default-param-last
export default function setLocationState(state = initialState, action: ActionTypes): LocationState {
  switch (action.type) {
    case ActionType.SET_LOCATION_DATA: {
      const locationsRaw = action.payload;
      const { locationsById, locationsByParent } = state;
      locationsRaw?.locations?.forEach((loc) =>
        updateFlatMap(locationsById, locationsByParent, loc)
      );
      return {
        ...state,
        locations: processLocations(locationsRaw),
        locationsById,
        locationsByParent,
      };
    }
    case ActionType.UPDATE_LOCATION_DATA: {
      const newDetails = action.payload;
      const allLocations = state.locations;
      const { locationsById, locationsByParent } = state;
      updateFlatMap(locationsById, locationsByParent, newDetails);

      return {
        ...state,
        locations: updateLocation(newDetails, allLocations),
        locationsById,
        locationsByParent,
      };
    }
    case ActionType.REMOVE_LOCATION_DATA: {
      const locationId = action.payload;
      const allLocations = state.locations;

      const { locationsById, locationsByParent } = state;
      const parentLocId = locationsById.get(locationId)?.location;
      if (parentLocId !== undefined) {
        const locArray = locationsByParent.get(parentLocId);
        // Remove the locationId from the parent array
        locArray?.splice(locArray.indexOf(locationId), 1);
      }
      locationsById.delete(locationId);

      return {
        ...state,
        locations: removeLocation(locationId, allLocations),
        locationsById,
        locationsByParent,
      };
    }
    case ActionType.SET_CURRENT_LOCATION: {
      const currentLocation = action.payload;
      return {
        ...state,
        currentLocation,
      };
    }
    case ActionType.SET_CURRENT_LOCATION_CHILDREN: {
      const locations = action.payload;
      const { locationsById, locationsByParent } = state;
      locations.forEach((loc) => updateFlatMap(locationsById, locationsByParent, loc));
      return {
        ...state,
        currentLocationChildren: locations,
        locationsById,
        locationsByParent,
      };
    }
    case ActionType.SET_LOCATION_FLOORPLAN: {
      const { locationId, floorplan } = action.payload;
      const floorplans = new Map(state.floorplans);
      floorplans.set(locationId, floorplan);
      return {
        ...state,
        floorplans,
      };
    }
    case ActionType.CLEAR_REDUX_STORE: {
      return {
        ...state,
        locations: new Map(),
        currentLocation: '#',
        currentLocationChildren: [],
        floorplans: new Map(),
        locationsById: new Map(),
        locationsByParent: new Map(),
      };
    }
    default: {
      return state;
    }
  }
}
