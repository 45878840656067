/* eslint-disable react/button-has-type */
/* eslint-disable react/react-in-jsx-scope */
import { useMemo, useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MapboxOverlay, MapboxOverlayProps } from '@deck.gl/mapbox/typed';
import Map, { useControl, AttributionControl, MapRef } from 'react-map-gl/maplibre';
import SourceToolbar from './SourceToolbar';
import MapToolbar from './MapToolbar';
import {
  getCurrentLocationChildSensors,
  getMapSize,
  getCurrentLocationFloorplan,
  getCurrentLocation,
  getHighlightedItem,
  getActiveMarker,
  getMotionThreshold,
  getShowFloorlanLabels,
  getUserPosition,
  getThemeMode,
  getCurrentLocationChildLocations,
} from '../../state/selectors';
import { VarName } from '../../utils/varNames';
import { setHighlightedItem, toggleShowFloorplanLabels } from '../../state/actions';
import {
  MarkerDataProps,
  MapCentrePosition,
  calculateViewSize,
  getMapLibreStyle,
  getMotionMap,
} from './mapHelpers';
import getFloorplanLayer from './MapLayers/floorPlanLayer';
import getLabelLayer from './MapLayers/labelLayer';
import getSensorCoverageLayer from './MapLayers/sensorCoverageLayer';
import getTargetPosLayer from './MapLayers/targetPosLayer';
import getSummaryCoverageLayer from './MapLayers/summaryCoverageLayer';
import { ActiveItem } from '../../state/types';
import { SensorValueItem } from '../CalendarView/helpers';
import CustomMarker from './MapLayers/CustomMarker';
import useStyles from '../../styles';
import { getMotionOccupancyValue } from '../../utils/motionEvents';
import getOccSignaturesLayer from './MapLayers/occSignaturesLayer';
import { StorageTypes, persistState, retrieveState } from '../../utils/persistentState';
import { DeckGLViewPort } from './types';
import CustomLocationMarker from './MapLayers/CustomLocationMarker';

function DeckGLOverlay(
  props: MapboxOverlayProps & {
    // eslint-disable-next-line react/no-unused-prop-types, react/require-default-props
    interleaved?: boolean;
  }
) {
  const overlay = useControl<MapboxOverlay>(() => new MapboxOverlay(props));
  overlay.setProps(props);
  return null;
}

const defaultViewState: DeckGLViewPort = {
  latitude: 53,
  longitude: -1,
  zoom: 4,
  pitch: 0,
  bearing: 0,
};

interface MapParams {
  allowChangeMapSize?: boolean;
  overrideData?: SensorValueItem[]; // e.g. on calendar page to use summary data instead of live data
  showSourceToolbar?: boolean;
  mapCentrePosition?: MapCentrePosition;
}

function MapGL({
  allowChangeMapSize,
  overrideData,
  showSourceToolbar,
  mapCentrePosition,
}: MapParams): JSX.Element {
  const dispatch = useDispatch();
  const classes = useStyles();
  const themeMode = useSelector(getThemeMode);
  const mapRef = useRef<MapRef>(null);
  const [viewState, setViewState] = useState<DeckGLViewPort>(
    (retrieveState(StorageTypes.MapLocation) as DeckGLViewPort) ?? defaultViewState
  );
  const [performAutoZoom, setPerformAutoZoom] = useState(false);

  const floorPlan = useSelector(getCurrentLocationFloorplan);
  const showLabels = useSelector(getShowFloorlanLabels);
  const childLocations = useSelector(getCurrentLocationChildLocations);
  const mapSize = useSelector(getMapSize);
  const childSensors = useSelector(getCurrentLocationChildSensors);
  const currentLocation = useSelector(getCurrentLocation);
  const highlightedItem = useSelector(getHighlightedItem);
  const activeMarker = useSelector(getActiveMarker);
  const motionThreshold = useSelector(getMotionThreshold);
  const userPosition = useSelector(getUserPosition);

  // Which data should we show on the map (activeMarker is selected data but can be overriden
  // by highlightedItem when hovering on data of another type)
  const activeVarName = highlightedItem?.varName ?? activeMarker;

  // Extract and shape the sensor information needed to create the map
  //   filter for only those needed and change data where overriden or needed (e.g. motion)
  const activeMarkers = useMemo(() => {
    const markerData: MarkerDataProps[] = [];
    childSensors?.forEach((sensor) => {
      const { id, data, name, shortName, position } = sensor;
      // Find the object within the `data` array
      const dataObject = data?.find((d) => d.varName === activeVarName);
      // Remove sensors that don't have data for the activeVarName
      let isValidSensor: boolean | undefined = dataObject !== undefined;

      const onlineStatusValue = data?.find((d) => d.varName === VarName.OnlineStatus)?.value;
      // Remove retired sensors if not showing onlineStatus data
      if (isValidSensor && activeVarName !== VarName.OnlineStatus) {
        if (onlineStatusValue === -2) isValidSensor = false;
      }

      let activeVarNameValue = dataObject?.value;
      // Change data value for overriden data
      if (overrideData) {
        const newValue = overrideData.find((d) => d.id === id)?.avg;
        // Update the data value if necessary
        if (newValue) activeVarNameValue = newValue;
      } else if (data && activeVarName === VarName.MotionEvent) {
        // Transform motion data to a form we can use
        const motionData = data.find((d) => d.varName === VarName.MotionEvent);
        if (motionData) {
          const { time, value } = motionData;
          const occValue = getMotionOccupancyValue(motionThreshold, time, value);
          motionData.value = occValue;
          activeVarNameValue = occValue;
        }
      }

      if (isValidSensor && activeVarNameValue !== undefined && position && dataObject?.time) {
        markerData.push({
          id,
          name: name ?? 'UNKNOWN',
          shortName: shortName ?? 'UNKNOWN',
          position,
          value: activeVarNameValue,
          time: dataObject.time,
          varName: activeVarName,
          isOnline: onlineStatusValue !== undefined && onlineStatusValue > 0,
          detail: dataObject?.detail ?? null,
        });
      }
    });
    return markerData;
  }, [childSensors, activeVarName, overrideData, motionThreshold]);

  const motionMap = useMemo(() => {
    // Create a map of motion event values vs polygons so we can
    // find them easier later.

    // When coming from CalendarView the data will be for utilisation
    const isUtlData = activeVarName === VarName.MotionEvent && overrideData !== undefined;
    const motionMapRecord = getMotionMap(activeMarkers, isUtlData);
    return motionMapRecord;
  }, [activeMarkers, activeVarName, overrideData]);

  const handleViewState = (viewParam: DeckGLViewPort) => {
    setViewState(viewParam);
    persistState(viewParam, StorageTypes.MapLocation);
  };

  const fitToView = () => {
    const viewSizeState = calculateViewSize(
      childLocations,
      childSensors,
      currentLocation,
      floorPlan,
      viewState
    );
    if (viewSizeState) {
      // display floorplan to slightly right side when floating contents are visible in left side
      const spacingParam = {
        padding: { left: mapCentrePosition === MapCentrePosition.right ? 600 : 0 },
      };
      handleViewState({ ...viewSizeState, ...spacingParam });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fitToView(), [floorPlan]); // Auto-zoom when floorplan downloaded

  useEffect(() => setPerformAutoZoom(true), [currentLocation]); // Track location changes
  useEffect(() => {
    // Auto-zoom when location data downloaded (after location change)
    if (performAutoZoom && childLocations) {
      setPerformAutoZoom(false);
      fitToView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childLocations]);

  const onSensorHover = useCallback(
    (item: ActiveItem) => dispatch(setHighlightedItem(item)),
    [dispatch]
  );

  const deckGLLayers = [];
  const floorPlanLayer = useMemo(() => {
    const showMotion = activeVarName === VarName.MotionEvent;
    const showOverrideData = overrideData !== undefined;

    const dataLayer = getFloorplanLayer(
      floorPlan,
      viewState.zoom,
      showMotion,
      highlightedItem,
      motionMap,
      motionThreshold,
      showOverrideData,
      onSensorHover
    );
    return dataLayer;
  }, [
    activeVarName,
    floorPlan,
    highlightedItem,
    motionMap,
    motionThreshold,
    onSensorHover,
    overrideData,
    viewState.zoom,
  ]);

  // Used to add labels to the floorplan
  const labelLayer = useMemo(() => {
    const dataLayer = getLabelLayer(floorPlan, showLabels, viewState.zoom);
    return dataLayer;
  }, [floorPlan, showLabels, viewState]);

  // Used to plot the users location on the map
  const targetPosLayer = useMemo(() => {
    let dataLayer = null;
    if (userPosition) {
      dataLayer = getTargetPosLayer(userPosition);
    }
    return dataLayer;
  }, [userPosition]);

  // Used to plot a circle around the user location on the map
  const perimeterPolygon = useMemo(() => {
    let dataLayer = null;
    if (userPosition) {
      dataLayer = getSummaryCoverageLayer(userPosition);
    }
    return dataLayer;
  }, [userPosition]);

  // Used to plot coloured area around sensors indicating coverage
  const sensorCoverageLayer = useMemo(() => {
    // TODO when data is overriden need to use getMotionUtlBandParams for colour
    // TODO if sensor is not online what to do with the motion polygon

    const dataLayer = getSensorCoverageLayer(activeVarName, activeMarkers, floorPlan);
    return dataLayer;
  }, [activeVarName, activeMarkers, floorPlan]);

  // Used for data with occupancy signatures to plot on Map measured positions of people
  const personMarkerLayer = useMemo(() => {
    let dataLayer = null;
    if (activeVarName === VarName.OccSignatures) {
      dataLayer = getOccSignaturesLayer(activeMarkers);
    }
    return dataLayer;
  }, [activeMarkers, activeVarName]);

  if (labelLayer !== null) {
    deckGLLayers.push(labelLayer);
  }

  if (sensorCoverageLayer !== null) {
    deckGLLayers.push(sensorCoverageLayer);
  }

  if (personMarkerLayer !== null) {
    deckGLLayers.push(personMarkerLayer);
  }

  if (floorPlanLayer !== null) {
    deckGLLayers.push(floorPlanLayer);
  }

  if (targetPosLayer !== null) {
    deckGLLayers.push(targetPosLayer);
  }

  if (perimeterPolygon !== null) {
    deckGLLayers.push(perimeterPolygon);
  }

  const toggleShowLabels = () => {
    dispatch(toggleShowFloorplanLabels());
  };

  const resetOrientation = () => {
    const newViewState = { ...viewState, pitch: 0, bearing: 0 };
    handleViewState({ ...newViewState });
  };

  const showCustomMarker = activeVarName !== VarName.MotionEvent;

  useEffect(() => {
    mapRef?.current?.resize();
  }, [mapSize]);

  const mapLibreStyle = getMapLibreStyle(themeMode);

  return (
    <div className={classes.mapContainer}>
      <div style={{ height: '100%', width: '100%', position: 'absolute' }}>
        <Map
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...viewState}
          ref={mapRef}
          onMove={(evt) => handleViewState(evt.viewState)}
          mapStyle={mapLibreStyle}
          attributionControl={false}
        >
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <DeckGLOverlay layers={deckGLLayers} interleaved />
          {showCustomMarker && <CustomMarker activeMarkers={activeMarkers} />}
          {!floorPlanLayer && <CustomLocationMarker />}
          <AttributionControl customAttribution="&copy; OpenStreetMap" compact={false} />
          <MapToolbar
            mapSize={mapSize}
            allowChangeMapSize={allowChangeMapSize}
            fitToView={fitToView}
            toggleShowLabels={toggleShowLabels}
            resetOrientation={resetOrientation}
            showLabels={showLabels}
            pitch={viewState.pitch}
            bearing={viewState.bearing}
          />
          {showSourceToolbar && <SourceToolbar />}
        </Map>
      </div>
    </div>
  );
}

MapGL.defaultProps = {
  allowChangeMapSize: true,
  overrideData: undefined,
  showSourceToolbar: true,
  mapCentrePosition: MapCentrePosition.default,
};

export default MapGL;
