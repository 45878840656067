import { TickFormatStop } from 'plotly.js';
import { Theme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { VarName, varNameDetails } from '../../utils/varNames';
import { DailyMetricItem, HistoricData } from '../../services/api';
import { MetricOptions } from '../../components/DateRangePicker';
import { getDataBandParams, varNameBandParams } from '../../utils/dataBandParams';
import { themeProps } from '../../styles/theme';
import { HoursSelection } from '../../state/types';

const ONE_DAY = 1000 * 60 * 60 * 24;

interface MinMax {
  max: number;
  min: number;
}

export const stackIcon = {
  width: 500,
  height: 600,
  path: 'M160 80c0-26.5 21.5-48 48-48h32c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H208c-26.5 0-48-21.5-48-48V80zM0 272c0-26.5 21.5-48 48-48H80c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V272zM368 96h32c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H368c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48z',
};

export interface TimeRange {
  // Timestamps in ms
  start: number;
  end: number;
}

export interface SensorHistoryPlotItem {
  sensorId: string;
  varName: VarName;
  sensorName: string;
  history: HistoricData;
  color?: string;
  ghost?: boolean;
}

export interface SensorMetricPlotItem {
  sensorId: string;
  varName: VarName;
  sensorName: string;
  metrics: DailyMetricItem[];
  color?: string;
  ghost?: boolean;
}

export function isHistoryItem(
  sensorData: SensorHistoryPlotItem | SensorMetricPlotItem
): sensorData is SensorHistoryPlotItem {
  return 'history' in sensorData;
}
export function isHistoryData(
  sensorData: SensorHistoryPlotItem[] | SensorMetricPlotItem[]
): sensorData is SensorHistoryPlotItem[] {
  return sensorData.some((item) => 'history' in item);
}
export function isMetricItem(
  sensorData: SensorHistoryPlotItem | SensorMetricPlotItem
): sensorData is SensorMetricPlotItem {
  return 'metrics' in sensorData;
}
export function isMetricData(
  sensorData: SensorHistoryPlotItem[] | SensorMetricPlotItem[]
): sensorData is SensorMetricPlotItem[] {
  return sensorData.some((item) => 'metrics' in item);
}

export function transformSimplePositiveDerivative(data: HistoricData): HistoricData {
  const transformedTimes: number[] = [];
  const transformedValues: number[] = [];
  for (let i = 1; i < data.time.length; i++) {
    // Start from 1 since we compare with the previous value
    const time = data.time[i];
    const value = data.value[i];
    const lastTime = data.time[i - 1];
    const lastValue = data.value[i - 1];
    const increase = value - lastValue;
    const timeDiff = time - lastTime;
    const rate = Number(((increase * 3600) / timeDiff).toFixed(2));
    if (rate >= 0) {
      transformedTimes.push(time);
      transformedValues.push(rate); // Only positive rates are considered
    }
  }
  return {
    time: transformedTimes,
    value: transformedValues,
  };
}

export const tickformatstops: Partial<TickFormatStop>[] = [
  // Adjust formatting for depending on time between ticks
  {
    // 0 -> 1 second
    dtickrange: [null, 1000],
    value: '%H:%M:%S.%L ms',
  },
  {
    // -> 1 minute
    dtickrange: [1000, 60000],
    value: '%H:%M:%S',
  },
  {
    // -> 1 hour
    dtickrange: [60000, 3600000],
    value: '%H:%M:%S \n %a %e %b',
  },
  {
    // -> 12 hours
    dtickrange: [3600000, 43200000],
    value: '%H:%M \n %a %e %b',
  },
  {
    // -> 1 day
    dtickrange: [43200000, 86400000],
    value: '%H:%M \n %a %e %b',
  },
  {
    // -> 1 week
    dtickrange: [86400000, 604800000],
    value: "%a %e \n %b '%y",
  },
  {
    // -> 1 month
    dtickrange: [604800000, 'M1'],
    value: '%e %b \n %Y',
  },
  {
    // -> 1 year
    dtickrange: ['M1', 'M12'],
    value: '%b %Y',
  },
  {
    // -> 1 year+
    dtickrange: ['M12', null],
    value: '%Y',
  },
];

export const getUnselectedTimeRange = (
  selectedHours: HoursSelection,
  startDate: Date,
  endDate: Date,
  metricSelection = MetricOptions.Raw
): TimeRange[] => {
  const { selectHours, includeWeekends, startHour, endHour } = selectedHours;
  if (!selectHours || (includeWeekends && startHour === 0 && endHour === 24)) {
    return [];
  }

  const hTs: TimeRange[] = [];
  const dStart = new Date(startDate);
  const dEnd = new Date(endDate);

  const now = new Date();

  let time = dStart.getTime();
  while (time <= dEnd.getTime()) {
    const start = new Date(time);
    const dayOfWeek = start.getDay();
    const morningStart = new Date(time);
    morningStart.setHours(0, 0, 0, 0); // Start of the day
    const eveningEnd = new Date(time);
    eveningEnd.setHours(23, 59, 59, 999); // End of the day
    if (metricSelection === MetricOptions.Daily) {
      if (!includeWeekends && (dayOfWeek === 6 || dayOfWeek === 0))
        // Daily data occupies 12 hour spread each side of midnight so adjust range
        hTs.push({
          start: morningStart.getTime() - ONE_DAY / 2,
          end: eveningEnd.getTime() - ONE_DAY / 2,
        });
    } else if (includeWeekends || (dayOfWeek !== 6 && dayOfWeek !== 0)) {
      const morningEnd = new Date(time);
      // Hourly data occupies 1 hour spread around the hour so increase range
      if (metricSelection === MetricOptions.Hourly)
        morningEnd.setHours(startHour - 1, 30, 0, 0); // Start of the selected hours
      else morningEnd.setHours(startHour, 0, 0, 0); // Start of the selected hours
      // For the time after the selected endHour
      const eveningStart = new Date(time);
      eveningStart.setHours(endHour, metricSelection === MetricOptions.Hourly ? 30 : 0, 0, 0); // End of the selected hours
      // Adjusting for the current time
      if (morningStart.getTime() < now.getTime()) {
        hTs.push({
          start: morningStart.getTime(),
          end: Math.min(morningEnd.getTime(), now.getTime()),
        });
      }
      if (eveningStart.getTime() < now.getTime()) {
        hTs.push({
          start: eveningStart.getTime(),
          end: Math.min(eveningEnd.getTime(), now.getTime()),
        });
      }
    } else {
      hTs.push({
        start: morningStart.getTime(),
        end: Math.min(eveningEnd.getTime(), now.getTime()),
      });
    }
    time += ONE_DAY;
  }
  return hTs;
};

export enum PlotlyBarMode {
  stack = 'stack',
  group = 'group',
  overlay = 'overlay',
  relative = 'relative',
}

export const getPlotCommonLayout = (
  varNames: VarName[],
  theme: Theme,
  unselectedTimes: TimeRange[] | undefined,
  showStackedTraces: boolean,
  metricSelection?: MetricOptions,
  varNameMinMax?: Map<VarName, MinMax> | undefined
): Partial<Plotly.Layout> => {
  // define barmode only when barchart is needed, undefined by default
  let barMode;
  if (metricSelection !== MetricOptions.Raw) {
    barMode = showStackedTraces ? PlotlyBarMode.stack : PlotlyBarMode.group;
  }

  const initialLayout = {
    font: { family: themeProps.fontFamily.body },
    grid: {
      rows: varNames.length,
      columns: 1,
      subplots: varNames.map((vn, idx) => `xy${idx}`),
      roworder: 'bottom to top',
      ygap: 0.2,
    },
    showlegend: false,
    hovermode: 'closest',
    autosize: true,
    // Set margins to maximise plot area (need to use automargin on axes)
    margin: {
      l: 10,
      r: 10,
      b: 10,
      t: 10,
      pad: 10,
    },
    // Use automargin so labels aren't cut off
    xaxis: {
      automargin: true,
      showgrid: true,
      spikemode: 'across',
      spikesnap: 'hovered data',
      tickformatstops: metricSelection !== MetricOptions.Daily && tickformatstops, // avoid unnecessary 00:00 for daily chart
      nticks: 20,  // Allow more ticks than default
      linecolor: theme.palette.text.primary,
      tickfont: {
        color: theme.palette.text.primary,
      },
      gridcolor: alpha(theme.palette.text.primary, 0.1),
    },
    barmode: barMode,
    shapes: [] as Plotly.Shape[],
    plot_bgcolor: theme.palette.primary.light,
    paper_bgcolor: theme.palette.primary.light,
  };

  const layout = varNames.reduce((acc, varId, varIdx) => {
    let { label, metric } = { ...varNameDetails[varId] };
    // Energy meter history data is transformed by store to simple average power
    if (varId === VarName.EnergyInkWh && metricSelection === MetricOptions.Raw) {
      label = 'Average Power';
      metric = 'kW';
    }
    const returnLayout = {
      title: {
        text: `${label} ${metric ? `(${metric})` : ''}`,
        font: {
          size: 16,
          color: theme.palette.text.primary,
        },
      },
      automargin: true,
      showgrid: true,
      tickfont: {
        color: theme.palette.text.primary,
      },
      gridcolor: alpha(theme.palette.text.primary, 0.1),
    } as Partial<Plotly.Axis>;

    const axisName = `yaxis${varIdx > 0 ? varIdx + 1 : ''}`;

    if (metricSelection === MetricOptions.Raw) {
      const varMinMax = varNameMinMax && varNameMinMax.get(varId);
      // Optional manual range specification
      // if (varMinMax && varMinMax.min && varMinMax.max) {
      //   returnLayout.range = [varMinMax.min, varMinMax.max];
      // }

      // Generate shapes to shade data band regions
      const newShapes = [] as Plotly.Shape[];
      if (varMinMax !== undefined) {
        // Find all the bands that cover our data
        //   exclude bands with upper bound below data minimum
        //   include band containing data maximum but not above
        const highBand = getDataBandParams(varId, varMinMax.max);
        const allBands = varNameBandParams[varId] ?? [];
        const bounds = allBands.filter(
          (b) => b.upperBound <= highBand.upperBound && b.upperBound > varMinMax.min
        );
        if (bounds.length > 0) {
          bounds.forEach((band, idx) => {
            const prevBand = idx > 0 ? bounds[idx - 1] : { upperBound: varMinMax.min };
            newShapes.push({
              type: 'rect',
              layer: 'below',
              xref: 'paper',
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              yref: `y${varIdx > 0 ? varIdx + 1 : ''}`, // 'y' | 'y1' | 'y2' |...
              x0: 0,
              x1: 1,
              y0: Math.max(varMinMax.min, prevBand.upperBound),
              y1: Math.min(varMinMax.max, band.upperBound),
              fillcolor: band.color,
              opacity: 0.25,
              line: { width: 0 },
            });
          });
        }
      }

      acc.shapes = acc.shapes.concat(newShapes);
    }
    return {
      ...acc,
      [axisName]: returnLayout,
    };
  }, initialLayout);

  // Create unselectedTimes shapes once
  if (unselectedTimes) {
    const unselectedShapes = [] as Plotly.Shape[];
    // grey out the time ranges (to unselected the times between them)
    unselectedTimes.forEach(({ start, end }) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      unselectedShapes.push({
        type: 'rect',
        layer: 'above',
        xref: 'x',
        yref: 'paper',
        x0: start,
        y0: 0,
        x1: end,
        y1: 1,
        fillcolor: theme.palette.primary.light,
        opacity: 0.65,
        line: { width: 0 },
      });
    });
    layout.shapes = layout.shapes.concat(unselectedShapes);
  }

  return layout as Plotly.Layout;
};
