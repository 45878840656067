import {
  DataTreeItems,
  GeoJSON as GeoJSONApiType,
  PropLocationItem,
  SensorLatest,
  UserPublic,
  Position,
  DataTreeItem,
} from '../services/api';
import { BandParamsType } from '../utils/dataBandParams';
import { VarName } from '../utils/varNames';

export enum ThemeMode {
  dark = 'dark',
  light = 'light',
}

export interface GhostParams {
  addGhosts: boolean;
  weekCount: number;
}

export type TempUser = {
  credential: string | null;
  accessToken: string | null;
  expiry: number | null;
};

export interface LocSensorStatusTree {
  onlineData: Map<VarName, DataTreeItem[]>;
  offlineData: Map<VarName, DataTreeItem[]>;
}

export interface UserAuthState {
  tempUser: TempUser | null;
  userDetails: UserPublic | null;
  userPosition: Position | null;
}

export interface ActiveItem {
  id: string;
  varName?: VarName;
  position?: Position;
  source?: 'map' | 'dashboardPanel' | undefined;
}

export type SortTypes = 'name' | 'location' | 'value' | 'time';
export type SortType = {
  property: SortTypes;
  ascending: boolean;
};

export type HoursSelection = {
  selectHours: boolean;
  startHour: number;
  endHour: number;
  includeWeekends: boolean;
};

export interface SelectedBand {
  varName: VarName;
  band: BandParamsType;
}

export interface UISettingsState {
  mapSize: 'collapsed' | 'normal' | 'full';
  highlightedItem: ActiveItem;
  activeMarker: VarName;
  clickedItem: ActiveItem;
  dashboardPanels: VarName[];
  activePlotVars: VarName[];
  showHelp: boolean;
  searchTerm: SearchPayload;
  sortBy: SortType;
  selectedHours: HoursSelection;
  motionThreshold: [number, number];
  showFloorplanLabels: boolean;
  showSensorLabels: boolean;
  allowBleLocSwitch: boolean;
  bleWebViewUrl: string;
  showNavDrawer: boolean;
  themeMode: ThemeMode;
  ghostParams: GhostParams;
  showStackedTraces: boolean;
  selectedBand: SelectedBand | null;
  showAdvancedAnalysis: boolean;
}

export type LocationTreeNode = {
  id: string;
  raw: PropLocationItem;
  children: Map<string, LocationTreeNode> | null;
};

export interface LocationState {
  locations: Map<string, LocationTreeNode>;
  currentLocation: string;
  currentLocationChildren: PropLocationItem[];
  floorplans: Map<string, GeoJSONApiType>;
  locationsById: Map<string, PropLocationItem>;
  locationsByParent: Map<string, string[]>;
}

export interface LatestVarNamePayload {
  varName: VarName;
  dataRaw: DataTreeItems;
}

export interface LocationFloorplanPayload {
  locationId: string;
  floorplan: GeoJSONApiType;
}

export interface SearchPayload {
  term: string;
  source: 'searchComponent' | 'dashboardPanel' | 'map' | undefined;
}

export interface SensorDataState {
  bleSensors: NearBySensor[] | undefined;
  sensorsById: Map<string, SensorLatest>;
  sensorsByVarName: Map<VarName, string[]>;
  sensorsByLocationId: Map<string, string[]>;
  selectedVars: VarName[];
  refreshTimeStamp: Map<VarName, number>;
  selectedSensorIds: string[];
  selectedStartDate: Date;
  selectedEndDate: Date;
}

export interface RootState {
  userAuth: UserAuthState;
  uiSettings: UISettingsState;
  locations: LocationState;
  sensors: SensorDataState;
}

export interface NearBySensor {
  id: string;
  rssi: number;
  lastSeen: number;
}
