import React, { ChangeEvent, useState, useEffect, useCallback, Suspense, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import CropFreeIcon from '@mui/icons-material/CropFree';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import PageviewIcon from '@mui/icons-material/Pageview';
import SyncIcon from '@mui/icons-material/Sync';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import useGlobalStyles from '../../styles';
import useStyles from '../../styles/location';
import {
  BacnetGatewaySettings,
  BacnetSensorSettings,
  BasicSensorType,
  GatewaySensorType,
  LocationDirectChildren,
  SensorLatest,
  SubSensorType,
  VisibleSubSensor,
} from '../../services/api';
import {
  filterNewSensorsInLocation,
  GatewayVisibleSubsensors,
  getAllGatewayVisibleSubsensors,
} from '../SensorConfig/helpers';
import ConfiguredSensorsList from './ConfiguredSensorsList';
import NewSensorsList from './NewSensorsList';
import { sensorTypeDetails } from '../../utils/sensorProperties';
import { AlertMsg } from './LocationProperties';
import SensorsInfoIcon from '../../styles/icons/SensorsInfoIcon';
import { fetchLocationDirectChildren, getSensorBacnetConfig } from '../../services/apiService';
import Loading from '../../components/Loading';
import BacnetSensorsList from './BacnetSensorsList';

const QrReader = React.lazy(() => import('../HelperComponents/QrReader'));

interface LocationSensorsListProps {
  locationId: string;
  locationSensors: Array<SensorLatest> | undefined;
  sensorsLoading: boolean;
}

function LocationSensorsList({
  locationId,
  locationSensors,
  sensorsLoading,
}: LocationSensorsListProps): JSX.Element {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  const [selectionType, setSelectionType] = useState('all');
  const [searchParam, setSearchParam] = useState<string>('');
  const [filteredSensors, setFilteredSensors] = useState<(SensorLatest | VisibleSubSensor)[]>();
  const [availableSensors, setAvailableSensors] = useState<(SensorLatest | VisibleSubSensor)[]>();
  const [newSensors, setNewSensors] = useState<VisibleSubSensor[]>();
  const [availableSensorsType, setAvailableSensorsType] =
    useState<(SubSensorType | GatewaySensorType | BasicSensorType)[]>();
  const [showAddNewSensors, setShowAddNewSensors] = useState<boolean>(false);
  const [filteringNewSensors, setFilteringNewSensors] = useState(true);
  const [allGatewayVisibleSubSensors, setAllGatewayVisibleSubSensors] = useState<
    GatewayVisibleSubsensors[] | undefined
  >(undefined);
  const [alertMsg, setAlertMsg] = useState<AlertMsg>();
  const [searchByQr, setSearchByQr] = useState<boolean>(false);
  const [showFilterOptions, setShowFilterOptions] = useState<boolean>(false);
  const [showBacnetSensors, setShowBacnetSensors] = useState(false);
  const [bacnetSensors, setBacnetSensors] = useState<BacnetSensorSettings[]>([]);
  const [bacnetGatewaySensors, setBacnetGatewaySensors] = useState<BacnetGatewaySettings[]>([]);

  // set and clear alerts based on selection type
  useEffect(() => {
    if (filteredSensors?.length === 0 && showBacnetSensors)
      setAlertMsg({ success: true, msg: 'No Bacnet enabled sensors found', alertType: 'error' });
    else if ((searchParam !== '' || selectionType !== 'all') && filteredSensors?.length === 0)
      setAlertMsg({ success: true, msg: 'No Sensor found matching filter', alertType: 'error' });
    else {
      setAlertMsg({ success: true, msg: '', alertType: 'error' });
    }
  }, [filteredSensors, searchParam, selectionType, showBacnetSensors]);

  // filter sensors based on selection type and search param
  const filterSensors = (
    sensorsList: (VisibleSubSensor | SensorLatest)[],
    searchValue: string,
    selectionValue: string
  ) => {
    let selectionFiltration = sensorsList;
    if (selectionValue === 'all') {
      selectionFiltration = sensorsList;
    } else {
      selectionFiltration = sensorsList.filter((sensors) => sensors.type === selectionValue);
    }
    if (!searchValue) {
      setFilteredSensors(selectionFiltration);
    } else {
      const regex = new RegExp(searchValue, 'i');
      if (showAddNewSensors) {
        const item = selectionFiltration as VisibleSubSensor[];
        const searchFilteredItem = item.filter((sensor) => sensor.suggested_id.match(regex));
        setFilteredSensors(searchFilteredItem);
      } else {
        const item = selectionFiltration as SensorLatest[];
        const searchFilteredItem = item.filter(
          (sensor) => sensor.id.match(regex) || (sensor.name && sensor.name.match(regex))
        );
        setFilteredSensors(searchFilteredItem);
      }
    }
  };

  const setNewSensorData = (visibleSubsensors: VisibleSubSensor[] | undefined) => {
    if (visibleSubsensors) {
      setFilteringNewSensors(false);
      setAvailableSensors(visibleSubsensors);
      setNewSensors(visibleSubsensors);
      filterSensors(visibleSubsensors, searchParam, selectionType);
    }
  };

  const fetchAllGatewayVisibleSubSensors = (locDirectChildren?: LocationDirectChildren) => {
    getAllGatewayVisibleSubsensors(locationId, locDirectChildren)
      .then((visibleSubsensors) => {
        setAllGatewayVisibleSubSensors(visibleSubsensors);
        filterNewSensorsInLocation(locationId, visibleSubsensors, locDirectChildren)
          .then((res) => {
            if (res) {
              setNewSensorData(res);
            }
          })
          .catch(() =>
            setAlertMsg({
              success: false,
              msg: 'Error filtering new sensors in location',
              alertType: 'error',
            })
          );
      })
      .catch(() =>
        setAlertMsg({
          success: false,
          msg: 'Error fetching gateway visible subsensors',
          alertType: 'error',
        })
      );
  };

  useEffect(() => {
    setShowBacnetSensors(false);
    if (showAddNewSensors && allGatewayVisibleSubSensors === undefined) {
      fetchLocationDirectChildren(locationId)
        .then((res) => {
          fetchAllGatewayVisibleSubSensors(res);
        })
        .catch((err) => setAlertMsg({ success: false, msg: err.cause, alertType: 'error' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allGatewayVisibleSubSensors, showAddNewSensors]);

  const filterAvailableSensorTypes = (sensors: VisibleSubSensor[] | SensorLatest[]) => {
    const sensorTypes: (SubSensorType | GatewaySensorType | BasicSensorType)[] = [];
    // eslint-disable-next-line array-callback-return
    sensors.map((sensor) => {
      if (sensor.type) {
        const sensorTypeExists = sensorTypes.includes(sensor.type);
        if (!sensorTypeExists) sensorTypes.push(sensor.type);
      }
    });
    setAvailableSensorsType(sensorTypes);
  };

  const getInitialData = useCallback(() => {
    if (!showBacnetSensors) {
      if (showAddNewSensors) {
        setShowBacnetSensors(false);
        setFilteringNewSensors(true);
        if (newSensors !== undefined) {
          setNewSensorData(newSensors);
          filterAvailableSensorTypes(newSensors);
        }
      } else if (locationSensors) {
        filterAvailableSensorTypes(locationSensors);
        setAvailableSensors(locationSensors as SensorLatest[]);
        setFilteredSensors(locationSensors as SensorLatest[]);
        filterSensors(locationSensors, searchParam, selectionType);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    showAddNewSensors,
    showBacnetSensors,
    searchParam,
    selectionType,
    locationId,
    locationSensors,
  ]);

  const onTextChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    e.stopPropagation();
    const { value } = e.target;
    setSearchParam(value);
    if (availableSensors) filterSensors(availableSensors, value, selectionType);
  };

  const onSelectChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    e.stopPropagation();
    const { value } = e.target;
    setSelectionType(value);
    if (availableSensors) filterSensors(availableSensors, searchParam, value);
  };

  useEffect(() => getInitialData(), [getInitialData, showAddNewSensors]);

  const bacnetSensorsLatest = useMemo(() => {
    const sensorsList: SensorLatest[] = [];
    if (bacnetSensors) {
      for (let i = 0; i < bacnetSensors.length; i++) {
        const { id } = bacnetSensors[i];
        const sensorInfo = locationSensors?.find((item) => item.id === id);
        if (sensorInfo) {
          sensorsList.push(sensorInfo);
        }
      }
    }
    return sensorsList;
  }, [bacnetSensors, locationSensors]);

  useEffect(() => {
    if (showBacnetSensors) {
      setAvailableSensors(bacnetSensorsLatest as SensorLatest[]);
      filterSensors(bacnetSensorsLatest, searchParam, selectionType);
      filterAvailableSensorTypes(bacnetSensorsLatest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bacnetSensorsLatest]);

  useEffect(() => {
    setBacnetSensors([]);
    const sensorsList: BacnetSensorSettings[] = [];
    if (bacnetGatewaySensors) {
      for (let i = 0; i < bacnetGatewaySensors.length; i++) {
        const { sensors } = bacnetGatewaySensors[i];
        if (sensors) {
          sensors.map((sensor) => sensorsList.push(sensor));
        }
        setBacnetSensors(sensorsList);
      }
    }
  }, [bacnetGatewaySensors]);

  const getBacnetGatewaySensorsList = async () => {
    if (locationSensors) {
      const gatewaySensors: SensorLatest[] = locationSensors.filter(
        (sensor) => sensor.type === GatewaySensorType.LfWiFiSensor
      );
      const sensorsList: BacnetGatewaySettings[] = [];
      await Promise.all(
        gatewaySensors.map(async (gatewaySensor) => {
          const bacnetConfig = await getSensorBacnetConfig(gatewaySensor.id).catch(() => undefined);
          const gateway = bacnetConfig?.gateway;
          if (gateway) {
            sensorsList.push(gateway);
          }
        })
      ).catch(); // ignore error
      setBacnetGatewaySensors(sensorsList);
    }
  };

  useEffect(() => {
    setBacnetGatewaySensors([]);
    if (showBacnetSensors) {
      getBacnetGatewaySensorsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showBacnetSensors]);

  useEffect(() => {
    setNewSensors([]);
    setSearchParam('');
    setSelectionType('all');
    setNewSensors(undefined);
    setShowAddNewSensors(false);
    setAllGatewayVisibleSubSensors(undefined);
    setBacnetGatewaySensors([]);
    setBacnetSensors([]);
    setShowBacnetSensors(false);
    setAlertMsg({
      success: true,
      msg: '',
      alertType: 'success',
    });
  }, [locationId]);

  const qrScanHandler = (data: string | null) => {
    if (data) {
      setSearchParam(data?.replace(/\s/g, ''));
      setSearchByQr(false);
    }
  };

  return (
    <div>
      <Suspense fallback={<Loading />}>
        <Dialog
          open={searchByQr}
          PaperProps={{
            style: {
              margin: 0,
            },
          }}
        >
          <QrReader
            onScan={(data: string | null) => qrScanHandler(data)}
            handleClose={() => setSearchByQr(false)}
          />
        </Dialog>
      </Suspense>
      <List>
        <ListItem>
          <Grid container>
            <Grid
              item
              sm={7}
              xs={7}
              style={{ display: 'flex', justifyContent: 'space-between', alignSelf: 'center' }}
            >
              <Box sx={{ display: 'inline-flex' }}>
                <SensorsInfoIcon className={classes.icon} />
                <Typography variant="h6" style={{ marginLeft: '10px' }}>
                  {showAddNewSensors ? 'Sensors To Be Configured' : 'Configured Sensors'}
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={5} xs={5} style={{ textAlign: 'right' }}>
              <Box sx={{ display: 'inline-flex' }}>
                {!showAddNewSensors && (
                  <Tooltip title="Show Bacnet enabled Sensors">
                    <IconButton
                      onClick={() => setShowBacnetSensors(!showBacnetSensors)}
                      size="large"
                    >
                      <DeviceHubIcon
                        className={showBacnetSensors ? classes.activeIcon : classes.icon}
                      />
                    </IconButton>
                  </Tooltip>
                )}
                {showAddNewSensors && (
                  <Tooltip title="Refresh sensors list">
                    <IconButton onClick={() => fetchAllGatewayVisibleSubSensors()} size="large">
                      <SyncIcon className={classes.icon} />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Show New Sensors">
                  <IconButton
                    onClick={() => setShowAddNewSensors(!showAddNewSensors)}
                    data-testid="show-sensors"
                    size="large"
                  >
                    {showAddNewSensors ? (
                      <CancelIcon className={globalClasses.closeBtnIcon} />
                    ) : (
                      <AddCircleIcon className={globalClasses.icon} />
                    )}
                  </IconButton>
                </Tooltip>
                <Tooltip title="Show filter options">
                  <IconButton
                    onClick={() => setShowFilterOptions(!showFilterOptions)}
                    data-testid="show-filter-options"
                    size="large"
                  >
                    <PageviewIcon className={classes.icon} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </ListItem>
        {showFilterOptions && (
          <ListItem style={{ paddingTop: '0' }}>
            <Grid container>
              <Grid item sm={8} xs={8} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'inline-flex' }}>
                  <IconButton
                    onClick={() => setSearchByQr(true)}
                    style={{ alignSelf: 'center', padding: '0' }}
                    size="large"
                  >
                    <CropFreeIcon className={classes.icon} />
                  </IconButton>
                  <TextField
                    placeholder={`Search by ${showAddNewSensors ? '' : 'name or '}id`}
                    onChange={onTextChange}
                    value={searchParam}
                    variant="standard"
                  />
                  {searchParam !== '' && (
                    <IconButton
                      onClick={() => setSearchParam('')}
                      style={{ alignSelf: 'center', padding: '0' }}
                      size="large"
                    >
                      <CancelIcon className={globalClasses.closeBtnIcon} />
                    </IconButton>
                  )}
                </Box>
              </Grid>
              <Grid item sm={4} xs={4} style={{ textAlign: 'right' }}>
                <Select
                  labelId="Sensor type"
                  value={selectionType}
                  onChange={(e) =>
                    onSelectChange(e as ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)
                  }
                  className={classes.selectBox}
                  variant="standard"
                >
                  <MenuItem value="all">All</MenuItem>
                  {availableSensorsType
                    ?.sort((a, b) => {
                      if (sensorTypeDetails[a].brandName < sensorTypeDetails[b].brandName) {
                        return -1;
                      }
                      if (sensorTypeDetails[a].brandName > sensorTypeDetails[b].brandName) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((sensorType) => (
                      <MenuItem key={`sensorType-${sensorType}`} value={sensorType}>
                        {`${sensorTypeDetails[sensorType].brandName} - ${sensorTypeDetails[sensorType].label}`}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
            </Grid>
          </ListItem>
        )}
      </List>
      <div style={{ padding: '5px 1rem' }}>
        {sensorsLoading ? (
          <Loading />
        ) : (
          <>
            {alertMsg?.msg && (
              <Alert
                severity={alertMsg.alertType ?? 'info'}
                onClose={() => setAlertMsg({ success: true, msg: '', alertType: 'success' })}
                className={globalClasses.alertMsg}
              >
                {alertMsg?.msg}
              </Alert>
            )}
            {showBacnetSensors && !showAddNewSensors && bacnetSensors.length > 0 && (
              <BacnetSensorsList
                filteredSensors={filteredSensors as SensorLatest[]}
                bacnetSensors={bacnetSensors}
                bacnetGatewaySensors={bacnetGatewaySensors}
              />
            )}
            {!showBacnetSensors && showAddNewSensors && allGatewayVisibleSubSensors && (
              <NewSensorsList
                fetchingSensors={filteringNewSensors}
                allSensors={filteredSensors as VisibleSubSensor[]}
                locationId={locationId === '' ? '#' : locationId}
                allGatewaySubSensor={allGatewayVisibleSubSensors}
                refreshNewSensors={fetchAllGatewayVisibleSubSensors}
              />
            )}
            {!showBacnetSensors && !showAddNewSensors && (
              <ConfiguredSensorsList allSensors={filteredSensors as SensorLatest[]} />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default LocationSensorsList;
