/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { DataTreeItem } from '../../services/api';
import useGlobalStyles from '../../styles';
import {
  getActiveMarker,
  getClickedItem,
  getHighlightedItem,
  getMotionThreshold,
  getSelectedBand,
  getLocSensorStatusData,
  getSortBy,
} from '../../state/selectors';
import { BandValues } from '../SensorArrayWidgets/base';
import { BandParamsType, getDataBandParams, varNameBandParams } from '../../utils/dataBandParams';
import { themeProps } from '../../styles/theme';
import SensorItem from '../Dashboard/SensorItem';
import BaseArraySortMenu from '../HelperComponents/BaseArraySortMenu';
import useDataFiltering from '../SensorArrayWidgets/useDataFiltering';
import useStyles from '../../styles/dashboard';
import { getDataValueNumber } from '../HelperComponents/DataValueString';
import { setClickedItem, setSelectedBand } from '../../state/actions';
import ReportsAddCircleIcon from '../../styles/icons/ReportsAddCircleIcon';

const useScrollListener = (
  element: React.MutableRefObject<null>,
  floatingElement: React.MutableRefObject<null>,
  handleScroll: () => void,
  isDesktopView: boolean,
  throttle = 200
) => {
  const scrollTimer = useRef();

  const listenToScroll = useCallback(() => {
    clearTimeout(scrollTimer.current);
    // @ts-ignore
    scrollTimer.current = setTimeout(() => handleScroll(), throttle);
  }, [handleScroll, throttle]);

  const removeScrollListener = useCallback(() => {
    if (element.current) {
      clearTimeout(scrollTimer.current);
    }
  }, [scrollTimer, element]);

  useEffect(() => {
    const currentElement = element.current;
    const floatEl = floatingElement.current;

    if (currentElement) {
      if (isDesktopView) {
        // @ts-ignore
        currentElement.onscroll = () => {
          listenToScroll();
        };
        // @ts-ignore
        floatEl.onscroll = () => {
          listenToScroll();
        };
      } else {
        window.onscroll = () => {
          listenToScroll();
        };
      }
    }

    return () => {
      removeScrollListener();
    };
  }, [listenToScroll, removeScrollListener, element, isDesktopView, floatingElement]);
};

interface SensorsListProps {
  floatingElementRef: React.MutableRefObject<null>;
}

function SensorsList({ floatingElementRef }: SensorsListProps): JSX.Element {
  const theme = useTheme();
  const dispatch = useDispatch();

  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const activeMarker = useSelector(getActiveMarker);
  const motionThreshold = useSelector(getMotionThreshold);
  const clickedItem = useSelector(getClickedItem);
  const highlightedItem = useSelector(getHighlightedItem);
  const sortBy = useSelector(getSortBy);
  const selectedBand = useSelector(getSelectedBand);
  const locSensorStatusData = useSelector(getLocSensorStatusData);

  const [currentFilter, setCurrentFilter] = useState<BandParamsType[]>([]);
  const [data, setData] = useState<DataTreeItem[]>([]);
  const [page, setPage] = useState(1);
  const targetElement = useRef(null);
  const isDesktopView = useMediaQuery(theme.breakpoints.up('lg'));

  // using useMemo to avoid loop for data filtering
  const onlineData = useMemo(
    () => locSensorStatusData.onlineData.get(activeMarker) ?? [],
    [activeMarker, locSensorStatusData.onlineData]
  );
  const offlineData = useMemo(
    () => locSensorStatusData.offlineData.get(activeMarker) ?? [],
    [activeMarker, locSensorStatusData.offlineData]
  );

  const allVarValues = useMemo(() => [...onlineData, ...offlineData], [onlineData, offlineData]);

  useEffect(() => {
    if (selectedBand && selectedBand.varName === activeMarker) {
      setCurrentFilter([selectedBand.band]);
    } else {
      setCurrentFilter([]);
      dispatch(setSelectedBand(null));
    }
  }, [activeMarker, dispatch, selectedBand]);

  // sort before hand for default selection and sensors to be added on scroll on hierarchy
  const sortedVarValues = useDataFiltering(allVarValues);

  // set default clicked item if active item doesn't have active marker sensor
  useEffect(() => {
    const activeItem = highlightedItem.id ? highlightedItem : clickedItem;
    const sortedOnlineValues = sortedVarValues.filter((item) =>
      onlineData.some((el) => el.id === item.id)
    );
    if (sortedOnlineValues.length > 0) {
      if (!activeItem || activeItem.varName !== activeMarker) {
        dispatch(
          setClickedItem({ id: sortedOnlineValues[0].id, varName: sortedOnlineValues[0].varName })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMarker, sortedVarValues]);

  // filter sensors list based on selected band pill
  const activeMarkerData: DataTreeItem[] = useMemo(() => {
    const filteredTree = sortedVarValues.filter(
      (dataItem) =>
        currentFilter.length === 0 ||
        currentFilter.indexOf(
          getDataBandParams(activeMarker, getDataValueNumber(dataItem, motionThreshold) ?? Infinity)
        ) !== -1
    );
    return filteredTree;
  }, [activeMarker, currentFilter, motionThreshold, sortedVarValues]);

  useEffect(() => setPage(1), [sortBy]);
  useEffect(() => setData(activeMarkerData.slice(0, page * 10)), [page, activeMarkerData]);

  const bandDetails = varNameBandParams[activeMarker] as BandParamsType[];

  const perBandSums: BandValues[] = useMemo(() => {
    const result: BandValues[] =
      bandDetails?.map(({ color }) => ({
        total: 0,
        pct: 0,
        colour: color,
      })) ?? undefined;

    if (result) {
      sortedVarValues.forEach((dataItem: DataTreeItem) => {
        const dataItemValue = getDataValueNumber(dataItem, motionThreshold);
        for (let i = 0; i < result.length; i++) {
          if (
            (dataItemValue !== undefined ? dataItemValue : Infinity) <= bandDetails[i].upperBound
          ) {
            result[i].total += 1;
            break;
          }
        }
      });
    }
    return result;
  }, [bandDetails, sortedVarValues, motionThreshold]);

  const toggleFilter = (band: BandParamsType) => {
    if (currentFilter) {
      const index = currentFilter?.indexOf(band);

      if (index === -1) {
        currentFilter?.push(band);
      } else {
        currentFilter.splice(index, 1);
      }
      setCurrentFilter([...currentFilter]);
    }
  };

  const scrollFunc = () => {
    const browserHeight = window.innerHeight;
    const mobileScrollPos = window.pageYOffset;
    // @ts-ignore
    const elementHeight = targetElement?.current?.clientHeight;
    // @ts-ignore
    const scrollPosition = targetElement?.current?.scrollTop;
    // @ts-ignore
    const scrollHeight = targetElement?.current?.scrollHeight;
    if (isDesktopView) {
      if (scrollHeight === elementHeight + scrollPosition) {
        setPage(page + 1);
      }
    } else if (mobileScrollPos > browserHeight * page - 5) {
      window.scrollTo({ top: mobileScrollPos - browserHeight, behavior: 'smooth' });
      setPage(page + 1);
    }
  };

  useScrollListener(targetElement, floatingElementRef, scrollFunc, isDesktopView);

  return (
    <Box
      ref={targetElement}
      sx={{
        overflowY: 'scroll',
        margin: '10px 0 30px',
        // avoid double scroll for browser with small height
        height: window.innerHeight < 880 ? 'auto' : '400px',
      }}
      className={classes.floatingBox}
    >
      <div>
        <div style={{ display: 'flex', padding: '10px', justifyContent: 'space-between' }}>
          <div>
            {perBandSums?.map((band, index) => {
              const bd = bandDetails[index];
              const filterActive = currentFilter.length === 0 || currentFilter.indexOf(bd) !== -1;
              const { description, text, label, color, lightBackground } = bd;
              return (
                <Tooltip
                  title={description ?? text}
                  placement="top"
                  key={`sensorarray-${activeMarker}-extended-${label}`}
                >
                  <button
                    type="button"
                    onClick={() => toggleFilter(bd)}
                    key={`sensorarray-${activeMarker}-summary-${label}`}
                    className={`${globalClasses.pillLabel} ${globalClasses.interactivePill}`}
                    style={{
                      fontSize: themeProps.textSize.small,
                      background: filterActive ? theme.palette.text.primary : 'transparent',
                      borderColor:
                        lightBackground || !filterActive ? color : theme.palette.text.primary,
                      color: filterActive
                        ? theme.palette.text.secondary
                        : theme.palette.text.primary,
                    }}
                  >
                    <FiberManualRecordIcon style={{ width: '12px', height: '12px', color }} />
                    {` ${label} (${band.total})`}
                  </button>
                </Tooltip>
              );
            })}
          </div>
        </div>
        <List>
          <ListItem style={{ padding: '0 8px' }}>
            <Grid container>
              <Grid item sm={10} xs={9}>
                <BaseArraySortMenu />
              </Grid>
              <Grid item sm={1} xs={2} style={{ textAlign: 'center' }}>
                <Tooltip title="Add to compare" placement="top">
                  <div>
                    <ReportsAddCircleIcon />
                  </div>
                </Tooltip>
              </Grid>
            </Grid>
          </ListItem>
          {data.map((sensorData) => (
            <ListItem key={sensorData.id} style={{ padding: '8px' }}>
              <SensorItem
                sensorData={sensorData}
                isOnline={onlineData.some((item) => item.id === sensorData.id)}
              />
            </ListItem>
          ))}
        </List>
      </div>
    </Box>
  );
}

export default SensorsList;
