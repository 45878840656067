import { Auth } from 'aws-amplify';
import { VarName } from '../utils/varNames';
import { DefaultApi, UserPublic } from './api';
import {
  DataTreeItems,
  GeoJSON as GeoJSONApiType,
  HistoricData,
  LocationDirectChildren,
  LocationTreeItems,
  ProvisionSensorClaimOut,
  ProvisionSensorIn,
  SensorLatest,
  PropSensorItemIn,
  PropSensorItem,
  PropSensorItemCreate,
  SensorTreeItems,
  VisibleItems,
  PropLocationItemIn,
  PropLocationItem,
  PropLocationItemCreate,
  GatewayConfig,
  UserListOut,
  NewUserDetails,
  TokenOutput,
  TokenCredentialsIn,
  DailyMetricItem,
  DailyMetricProjectTypes,
  BacnetSettings,
  BacnetSettingsUpdate,
  BacnetSettingsCreate,
  ProjectDirectChildren,
  ProjectSensors,
  ProjectData,
  ProjectSensorLatest,
} from './api/api';
import { Configuration } from './api/configuration';
import DataStore from './dataStore';
// import { RANGES, DEFAULT_RANGE, SENSORS } from './constants';
// import { parseParams, convertDateRange, formatISODate } from './functions';

const API_URL = process.env.REACT_APP_API_URL;
const API_TOKEN_CLIENT_ID = process.env.REACT_APP_API_TOKEN_CLIENT_ID;

async function getAccessToken(): Promise<string> {
  const tempUserToken = DataStore.getInstance().getTempUserAccessToken();
  if (tempUserToken) {
    return tempUserToken;
  }
  const res = await Auth.currentSession();
  const accessToken = res.getAccessToken();
  const jwt = accessToken.getJwtToken();

  return jwt;
}

export interface DefaultResponse {
  result?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getErrMsg = (res: any) => {
  const detail = res?.response?.data?.detail ?? undefined;
  let errMsg = 'Unknown error';
  if (detail) {
    if (typeof detail === 'string') errMsg = detail;
    else if (detail && typeof detail === 'object' && detail[0]) {
      const { msg } = detail[0];
      if (msg) errMsg = msg;
    }
  }
  return errMsg;
};

const apiConfig = new Configuration({
  basePath: API_URL,
  accessToken: getAccessToken,
});
const apiSerivice = new DefaultApi(apiConfig);

export const fetchTempUserToken = async (credential: string): Promise<TokenOutput> => {
  const tokenCreds = {
    credential_id: credential,
    client_id: API_TOKEN_CLIENT_ID,
  } as TokenCredentialsIn;
  const { data } = await apiSerivice.getAccessTokenTokenPost(tokenCreds).catch((res) => {
    throw new Error('Failed to fetch', { cause: getErrMsg(res) });
  });
  return data;
};

export const fetchUserDetails = async (): Promise<UserPublic> => {
  const { data } = await apiSerivice.readUsersMeUsersMeGet().catch((res) => {
    throw new Error('Failed to fetch', { cause: getErrMsg(res) });
  });
  return data;
};

export const fetchLocations = async (): Promise<LocationTreeItems> => {
  const { data } = await apiSerivice
    .readRootLocationTreeLocationsAllGet(['location', 'name', 'shortName'])
    .catch((res) => {
      throw new Error('Failed to fetch', { cause: getErrMsg(res) });
    });
  return data;
};

export const fetchLocationProperties = async (locationId: string): Promise<PropLocationItem> => {
  const { data } = await apiSerivice
    .readLocationPropertiesLocationsLocationIdGet(locationId)
    .catch((res) => {
      throw new Error('Failed to fetch', { cause: getErrMsg(res) });
    });
  return data;
};

export const fetchAllSensorsInLocation = async (
  locationId: string,
  project?: ProjectSensors[]
): Promise<SensorTreeItems> => {
  const { data } = await apiSerivice
    .readAllSensorsInLocationTreeLocationsLocationIdAllSensorsGet(locationId, project)
    .catch((res) => {
      throw new Error('Failed to fetch', { cause: getErrMsg(res) });
    });
  return data;
};

export const fetchAllLatestVarname = async (
  varName: VarName,
  project?: ProjectData[]
): Promise<DataTreeItems> => {
  const { data } = await apiSerivice
    .readAllLatestDataInTreeSensorsAllVarNameLatestGet(varName, project)
    .catch((res) => {
      throw new Error('Failed to fetch', { cause: getErrMsg(res) });
    });
  return data;
};

export const fetchSensorLatest = async (
  sensorId: string,
  project?: ProjectSensorLatest[]
): Promise<SensorLatest> => {
  const { data } = await apiSerivice
    .readLatestSensorInformationSensorsSensorIdGet(sensorId, project)
    .catch((res) => {
      throw new Error('Failed to fetch', {
        cause: { msg: getErrMsg(res), status: res.response.status },
      });
    });
  return data;
};

export const updateSensorProperties = async (
  sensorId: string,
  propSensorItemIn: PropSensorItemIn
): Promise<PropSensorItem> => {
  const { data } = await apiSerivice
    .updateSensorPropertiesSensorsSensorIdPut(sensorId, propSensorItemIn)
    .catch((res) => {
      throw new Error('Failed to update', { cause: getErrMsg(res) });
    });
  return data;
};

export const updateLocationProperties = async (
  locationId: string,
  propLocationItemIn: PropLocationItemIn
): Promise<PropLocationItem> => {
  const { data } = await apiSerivice
    .updateLocationPropertiesLocationsLocationIdPut(locationId, propLocationItemIn)
    .catch((res) => {
      throw new Error('Failed to update', { cause: getErrMsg(res) });
    });
  return data;
};

export const fetchLocationDirectChildren = async (
  locationId: string,
  project?: ProjectDirectChildren[]
): Promise<LocationDirectChildren> => {
  const { data } = await apiSerivice
    .readLocationDirectChildrenLocationsLocationIdGet(locationId, project)
    .catch((res) => {
      throw new Error('Failed to fetch', { cause: getErrMsg(res) });
    });
  return data;
};

//  Will get 404 from the API when there is no floorplan for location.
export const fetchLocationFloorplan = async (locationId: string): Promise<GeoJSONApiType> => {
  const { data } = await apiSerivice
    .getLocationFloorplanLocationsLocationIdFloorplanGet(locationId)
    .catch((res) => {
      throw new Error('Failed to fetch', { cause: getErrMsg(res) });
    });
  return data;
};

export const deleteLocationFloorplan = async (locationId: string): Promise<string> => {
  const { data } = await apiSerivice
    .deleteLocationFloorplanLocationsLocationIdFloorplanDelete(locationId)
    .catch((res) => {
      throw new Error('Failed to delete', { cause: getErrMsg(res) });
    });
  return data;
};

export const fetchSensorHistory = async (
  varName: VarName,
  sensorId: string,
  tstart: number,
  tend: number
): Promise<HistoricData> => {
  // Convert the start/end dates from milliseconds to seconds
  const tS = Math.round(tstart / 1000);
  const tE = Math.round(tend / 1000);

  const { data } = await apiSerivice
    .readSensorHistoricDataSensorsSensorIdVarNameHistoryGet(varName, sensorId, tS, tE)
    .catch((res) => {
      throw new Error('Failed to fetch', { cause: getErrMsg(res) });
    });
  return data;
};

export const addNewSensor = async (
  sensorId: string,
  propSensorItemCreate: PropSensorItemCreate
): Promise<PropSensorItem | string> => {
  const { data } = await apiSerivice
    .addNewSensorSensorsSensorIdPost(sensorId, propSensorItemCreate)
    .catch((res) => {
      throw new Error('Failed to create', { cause: getErrMsg(res) });
    });
  return data;
};

export const provisionSensor = async (
  sensorId: string,
  provisionSensorIn: ProvisionSensorIn
): Promise<ProvisionSensorClaimOut> => {
  const { data } = await apiSerivice
    .provisionNewGatewaySensorSensorsSensorIdProvisionPost(sensorId, provisionSensorIn)
    .catch((res) => {
      throw new Error('Failed to provision sensor', { cause: getErrMsg(res) });
    });
  return data;
};

export const fetchVisibleItems = async (sensorId: string): Promise<VisibleItems> => {
  const { data } = await apiSerivice
    .getVisibleSubsensorsApsSensorsSensorIdVisibleGet(sensorId)
    .catch((res) => {
      throw new Error('Failed to fetch', { cause: getErrMsg(res) });
    });
  return data;
};

export const setLocationFloorPlan = async (
  locationId: string,
  geoJSON: GeoJSONApiType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const { data } = await apiSerivice
    .setLocationFloorplanLocationsLocationIdFloorplanPost(locationId, geoJSON)
    .catch((res) => {
      throw new Error('Failed to set location floor plan', { cause: getErrMsg(res) });
    });
  return data;
};

export const createNewLocation = async (
  locationId: string,
  propLocationItemCreate: PropLocationItemCreate
): Promise<PropLocationItem> => {
  const { data } = await apiSerivice
    .createNewLocationLocationsLocationIdPost(locationId, propLocationItemCreate)
    .catch((res) => {
      throw new Error('Failed to fetch', { cause: getErrMsg(res) });
    });
  return data;
};

export const updateGatewayConfig = async (
  sensorId: string,
  gatewayConfig: GatewayConfig
): Promise<GatewayConfig> => {
  const { data } = await apiSerivice
    .updateGatewayConfigSensorsSensorIdConfigPut(sensorId, gatewayConfig)
    .catch((res) => {
      throw new Error('Error updating config', { cause: getErrMsg(res) });
    });
  return data;
};

export const deleteLocation = async (locationId: string, areYouSure: boolean): Promise<string> => {
  const { data } = await apiSerivice
    .deleteLocationLocationsLocationIdDelete(locationId, areYouSure)
    .catch((res) => {
      throw new Error('Error deleting location', { cause: getErrMsg(res) });
    });
  return data;
};

export const getUsersInLocation = async (locationId: string): Promise<UserListOut> => {
  const { data } = await apiSerivice
    .listUsersInLocationUsersLocationIdAllGet(locationId)
    .catch((res) => {
      throw new Error('Error fetching users', { cause: getErrMsg(res) });
    });
  return data;
};

export const addNewUser = async (
  locationId: string,
  newUserDetails: NewUserDetails
): Promise<DefaultResponse> => {
  const { data } = await apiSerivice
    .addNewUserToLocationUsersLocationIdAddPost(locationId, newUserDetails)
    .catch((res) => {
      throw new Error('Error creating user', { cause: getErrMsg(res) });
    });
  return data;
};

export const deleteUser = async (
  userId: string,
  locationId: string,
  forceDelete: boolean
): Promise<DefaultResponse> => {
  const { data } = await apiSerivice
    .removeUserAccessUsersLocationIdUserIdRemoveDelete(userId, locationId, forceDelete)
    .catch((res) => {
      throw new Error('Error deleting user', { cause: getErrMsg(res) });
    });
  return data;
};

export const deleteSensor = async (sensorId: string, areYouSure: boolean): Promise<string> => {
  const { data } = await apiSerivice
    .deleteSensorSensorsSensorIdDelete(sensorId, areYouSure)
    .catch((res) => {
      throw new Error('Error deleting user', { cause: getErrMsg(res) });
    });
  return data;
};

export const getSensorDailyData = async (
  varName: VarName,
  sensorId: string,
  dStart: string,
  dEnd: string,
  selectedHours?: Set<number>,
  project?: DailyMetricProjectTypes[]
): Promise<DailyMetricItem[]> => {
  const { data } = await apiSerivice
    .readSensorDailyDataRangeSensorsSensorIdVarNameDailyGet(
      varName,
      sensorId,
      dStart,
      dEnd,
      selectedHours,
      project
    )
    .catch((res) => {
      throw new Error('Failed to fetch', { cause: getErrMsg(res) });
    });
  return data;
};

export const getLocationDailyData = async (
  varName: VarName,
  locationId: string,
  dStart: string,
  dEnd: string,
  selectedHours?: Set<number>,
  project?: DailyMetricProjectTypes[]
): Promise<DailyMetricItem[]> => {
  const { data } = await apiSerivice
    .readLocationDirectChildDailyDataRangeLocationsLocationIdVarNameDailyGet(
      varName,
      locationId,
      dStart,
      dEnd,
      selectedHours,
      project
    )
    .catch((res) => {
      throw new Error('Failed to fetch', { cause: getErrMsg(res) });
    });
  return data;
};

export const getSensorBacnetConfig = async (sensorId: string): Promise<BacnetSettings> => {
  const { data } = await apiSerivice
    .getSensorBacnetConfigSensorsSensorIdBacnetGet(sensorId)
    .catch((res) => {
      throw new Error('Failed to fetch', {
        cause: { msg: getErrMsg(res), status: res.response.status },
      });
    });
  return data;
};

export const updateSensorBacnetConfig = async (
  sensorId: string,
  bacnetSettingsUpdate: BacnetSettingsUpdate
) => {
  const { data } = await apiSerivice
    .updateSensorBacnetConfigSensorsSensorIdBacnetPut(sensorId, bacnetSettingsUpdate)
    .catch((res) => {
      throw new Error('Failed to update', { cause: getErrMsg(res) });
    });
  return data;
};

export const createSensorBacnetConfig = async (
  sensorId: string,
  bacnetSettingCreate: BacnetSettingsCreate
) => {
  const { data } = await apiSerivice
    .addBacnetConfigSensorsSensorIdBacnetPost(sensorId, bacnetSettingCreate)
    .catch((res) => {
      throw new Error('Failed to create', { cause: getErrMsg(res) });
    });
  return data;
};

export const removeSensorBacnetConfig = async (
  sensorId: string,
  areYouSure: boolean,
  removeGateway: boolean
) => {
  const { data } = await apiSerivice
    .removeBacnetConfigSensorsSensorIdBacnetDelete(sensorId, areYouSure, removeGateway)
    .catch((res) => {
      throw new Error('Failed to remove', { cause: getErrMsg(res) });
    });
  return data;
};
